import React from "react";
import { FormProvider } from "react-hook-form";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegPlusSquare } from "react-icons/fa";
import { usePatientLogic } from "./usePatientLogic";
import {
  Form,
  TextInput,
  TextAreaInp,
  NumInput,
  AsyncSelectComp,
  PopUp,
  DatePicker,
  CheckboxComp,
  CheckboxGroup,
  Radio,
} from "..";

export const Patient = ({ edit = false, ...props }) => {
  const {
    state,
    error,
    methods,
    fields,
    isShow,
    saveData,
    togglePopup,
    getPatient,
    handleToggle,
    handleAppend,
    handleRepresentativeToggle,
    handleRemove,
  } = usePatientLogic(edit);

  return (
    <div className="max-w-2500">
      <div className="myContent patient-form">
        {state.message && <h2 className="text-success">{state.message}</h2>}
        {error.error && <PopUp toggle={togglePopup} message={error.message} />}
        {edit && state.loading ? (
          <h1>Načítání formuláře...</h1>
        ) : (
          <FormProvider {...methods}>
            <Form>
              <div className="form-row w-66">
                <div className="form-column">
                  <NumInput
                    label="Rodné číslo *"
                    id="birth_number"
                    name="birth_number"
                    error={methods.formState.errors.birth_number?.message}
                    onBlur={getPatient}
                  />
                  <TextInput
                    label="Příjmení pacienta *"
                    id="lastName"
                    name="last_name"
                    error={methods.formState.errors.last_name?.message}
                  />
                  <TextInput
                    label="Jméno pacienta *"
                    id="firstName"
                    name="first_name"
                    error={methods.formState.errors.first_name?.message}
                  />
                  <NumInput
                    label="Číslo občanky"
                    id="id_number"
                    name="id_number"
                  />

                  <NumInput
                    label="Číslo pojištěnce *"
                    id="insurance_number"
                    name="insurance_number"
                    error={methods.formState.errors.insurance_number?.message}
                  />
                  <AsyncSelectComp
                    label="Pojišťovna *"
                    id="insurance"
                    name="insurance_company"
                    active={false}
                    endPoint="insurances"
                    error={
                      methods.formState.errors.insurance_company?.label?.message
                    }
                  />
                  <AsyncSelectComp
                    label="Diagnóza *"
                    id="main_diagnosis"
                    name="main_diagnosis"
                    active={false}
                    endPoint="diagnoses"
                    error={
                      methods.formState.errors.main_diagnosis?.label?.message
                    }
                  />
                </div>
                <div className="form-column">
                  <TextInput
                    label="Státní občanství"
                    id="nationality"
                    name="nationality"
                  />
                  <TextInput
                    label="Jazyk/y v nichž pacient komunikuje"
                    id="communication_languages"
                    name="communication_languages"
                  />
                  <NumInput
                    label="Hmotnost pacienta *"
                    id="weight"
                    name="weight"
                    error={methods.formState.errors.weight?.message}
                  />

                  <NumInput
                    label="Výška pacienta *"
                    id="height"
                    name="height"
                    error={methods.formState.errors.height?.message}
                  />

                  <TextInput
                    label="Lékař *"
                    id="doctor"
                    name="doctor"
                    error={methods.formState.errors.doctor?.message}
                  />
                  <DatePicker
                    label="Datum narození *"
                    id="birthDate"
                    name="birth_date"
                    error={methods.formState.errors.birth_date?.message}
                  />
                </div>
                <div className="form-column w-100">
                  <AsyncSelectComp
                    label="Zdravotnické zařízení *"
                    id="external_department_extend"
                    name="external_department_extend"
                    active={false}
                    endPoint="external_departments"
                    error={
                      methods.formState.errors.external_department_extend?.label
                        ?.message
                    }
                  />
                </div>
              </div>
              <h2>Adresa pacienta</h2>
              <div className="form-row w-66">
                <div className="form-column">
                  <TextInput label="Město" id="city" name="city" />
                  <TextInput label="Ulice" id="street" name="street" />
                </div>
                <div className="form-column">
                  <TextInput
                    label="Číslo popisné"
                    id="descriptive_number"
                    name="descriptive_number"
                  />

                  <NumInput label="PSČ" id="postcode" name="postcode" />
                </div>
              </div>
              <div className="form-row w-66">
                <CheckboxComp
                  label="Korespondenční adresa se liší"
                  name="has_correspondence_address"
                  containerClass={"patient-form-checkbox"}
                  onClick={() => handleToggle("correspondenceAddress")}
                />
              </div>
              {/* logic for hiddne unles the top checkbox is true */}
              {isShow.correspondenceAddress && (
                <div className="form-row w-66">
                  <div className="form-column">
                    <TextInput
                      label="Město"
                      id="correspondence_city"
                      name="correspondence_city"
                    />
                    <NumInput
                      label="Číslo popisné"
                      id="correspondence_descriptive_number"
                      name="correspondence_descriptive_number"
                    />
                  </div>
                  <div className="form-column">
                    <TextInput
                      label="Ulice"
                      id="correspondence_street"
                      name="correspondence_street"
                    />
                    <NumInput
                      label="PSČ"
                      id="correspondence_postcode"
                      name="correspondence_postcode"
                    />
                  </div>
                </div>
              )}
              {isShow.mailAdrress && (
                <div className="form-row w-66 mb-1">
                  <div className="form-column">
                    {/* special input for phone number */}
                    <NumInput
                      label="Telefonní číslo"
                      id="phone_number"
                      name="phone_number"
                    />
                  </div>
                  <div className="form-column">
                    <TextInput
                      label="Emailová adresa"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="form-column w-100">
                    <div className="form-check mb-2">
                      <Radio
                        id="gender"
                        name="gender"
                        value="M"
                        label="Muž"
                        radioClass="form-check-input"
                        labelClass="ml-2"
                      />
                    </div>

                    <div className="form-check">
                      <Radio
                        id="gender"
                        name="gender"
                        value="F"
                        label="Žena"
                        radioClass="form-check-input"
                        labelClass="ml-2"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="form-row w-66">
                <CheckboxComp
                  label="Pacient s omezenou svéprávností?"
                  name="has_limited_capacity"
                  num={12}
                  containerClass="patient-form-checkbox my-3"
                />
                <CheckboxComp
                  label="Identifikační a kontaktní údaje zákonného zástupce pacienta"
                  name="has_representatives"
                  num={12}
                  containerClass="patient-form-checkbox"
                  onClick={() => handleRepresentativeToggle()}
                />
              </div>
              {fields.map((item, index) => (
                <div className="form-container mb-3" key={`${index}-index`}>
                  <div className="form-title w-66">
                    <h2>
                      Zástupce{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemove(index)}
                      >
                        <RiDeleteBin6Line />
                      </span>
                    </h2>
                  </div>
                  <div className="form-row w-66">
                    <TextInput
                      label="Vztah k pacientovi"
                      name={`representative.${index}.relationship`}
                    />
                  </div>
                  <div className="form-row w-66">
                    <div className="form-column">
                      <TextInput
                        label="Jméno"
                        name={`representative.${index}.first_name`}
                      />
                      <TextInput
                        label="Příjmení"
                        name={`representative.${index}.last_name`}
                      />
                      <TextInput
                        label="Město"
                        name={`representative.${index}.city`}
                      />
                      <TextInput
                        label="Číslo popisné"
                        name={`representative.${index}.descriptive_number`}
                      />
                      <NumInput
                        label="Telefonní číslo"
                        name={`representative.${index}.phone_number`}
                      />
                    </div>
                    <div className="form-column">
                      <TextInput
                        label="Ulice"
                        name={`representative.${index}.street`}
                      />
                      <NumInput
                        label="PSČ"
                        name={`representative.${index}.postcode`}
                      />
                      <TextInput
                        label="E-mailová adresa"
                        name={`representative.${index}.email`}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {/* logic for legal paerenthip */}
              {/* list of forms, saving probably individualy */}
              {fields.length !== 0 && (
                <div className="form-row mt-3 ml-5 add-representative">
                  <div children="form-column">
                    <span
                      style={{ cursor: "pointer" }}
                      className="add-representative-icon strong"
                      onClick={() => handleAppend()}
                    >
                      <FaRegPlusSquare className="mr-3" />
                      Přidat zástupce
                    </span>
                  </div>
                </div>
              )}
              <div className="form-row w-66">
                <CheckboxComp
                  label="Pacient se zdravotním postižením?"
                  name="has_disability"
                  num={12}
                  containerClass="patient-form-checkbox"
                  onClick={() => handleToggle("has_disability")}
                />
                {isShow.has_disability && (
                  <>
                    <TextAreaInp
                      label="Informace o nezbytných zdravotnických prostředcích či pomůckách"
                      name="medical_devices_information"
                      labelClass="w-100"
                      containerClass="ml-5 mt-2"
                      rows={3}
                    />
                    <CheckboxComp
                      label="Pacient se sluchovým, kombinovaným sluchovým nebo zrakovým postižením?"
                      name="has_hearing_visual_impairment"
                      num={12}
                      containerClass="patient-form-checkbox mt-3 ml-5"
                      onClick={() =>
                        handleToggle("has_hearing_visual_impairment")
                      }
                    />
                    {isShow.has_hearing_visual_impairment && (
                      <div className="communication-input-container mt-3">
                        <TextInput
                          label="Preferovaná forma komunikace"
                          name="preferred_communication_form"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="form-row">
                <CheckboxComp
                  label="Osoba vykonávajících činnosti epidemiologicky závažné?"
                  name="has_epidemiological_significance_activities"
                  num={12}
                  containerClass="patient-form-checkbox"
                  onClick={() =>
                    handleToggle("has_epidemiological_significance_activities")
                  }
                />
                {isShow.has_epidemiological_significance_activities && (
                  <TextAreaInp
                    label="Vypište druh vykonávané činnosti"
                    name="activities_type"
                    labelClass="w-100"
                    containerClass="ml-5 mt-2"
                    rows={3}
                  />
                )}
              </div>
              <div className="form-row-button">
                <button
                  type="button"
                  className="button"
                  onClick={methods.handleSubmit(saveData)}
                >
                  Uložit
                </button>
              </div>
            </Form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
