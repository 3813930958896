import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";
import { compareAsc, compareDesc, isAfter, isEqual, parseISO } from "date-fns";

export const CheckinLogic = (
  checkin = null,
  patient,
  careID,
  careIsLocked,
  toggle = false
) => {
  const [state, setState] = useState({
    submit: false,
    activateForm: true,
    dataSaved: false,
    edit: false,
    locked: false,
  });
  const [error, setError] = useState("");
  const [oldesCare, setOldesCare] = useState({});
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const { id, care_id } = useParams();
  const edit = history?.location?.state?.edit;

  //Decide what data I need on component mount
  useEffect(() => {
    //Patient have checkin data already -> populate form
    if (checkin !== null) {
      setFormValues(checkin);
    } else if (careIsLocked) {
      deactivateForm();
    }
  }, []);

  useEffect(() => {
    const findOldCares = patient?.cares.filter((item) => item.checkin !== null);
    const care = findOldCares.reduce(
      (latest, current) => {
        if (current.finished_at === null) return latest;
        if (latest.finished_at === null) return current;

        const latestDate = parseISO(latest.finished_at);
        const currentDate = parseISO(current.finished_at);
        return compareAsc(currentDate, latestDate) === 1 ? current : latest;
      },
      { finished_at: null }
    );

    if (care.finished_at !== null && checkin === null) {
      setOpen(true);
      setOldesCare(care);
    }
  }, []);

  const onPopUpClose = () => setOpen(false);

  const onPopUpSubmit = (item) => {
    setFormValues(item);
    setOpen(false);
  };

  //deactivate form when care is locked
  const deactivateForm = () => {
    setState({ ...state, activateForm: false });
  };

  //FORM SECTION
  const methods = useForm({
    defaultValues: {
      polypharmacy: false,
      drugs: [{ label: "Vyhledejte...", value: "" }],
      polypharmacy_note: "",
      high_interaction_potential: false,
      high_interaction_potential_drugs: [{ label: "Vyhledejte...", value: "" }],
      high_interaction_potential_note: "",
      toggle_diagnoses: false,
      diagnoses: [
        {
          label: "Vyhledejte...",
          value: "",
          drugs: [{ label: "Vyhledejte...", value: "" }],
        },
      ],
      diagnoses_note: "",
      renal_insufficiency: false,
      renal_insufficiency_note: "",
      significant_biochemical_changes: false,
      significant_biochemical_changes_note: "",
      systemic_corticosteroids: false,
      systemic_corticosteroids_note: "",
      narrow_therapeutic_window: false,
      narrow_therapeutic_window_drugs: [{ label: "Vyhledejte...", value: "" }],
      narrow_therapeutic_window_note: "",
      hepatic_insufficiency: false,
      hepatic_insufficiency_note: "",
      intensive_care: false,
      intensive_care_note: "",
      pharmacist_intervention_required: false,
      consultation_requested: false,
      risk_level: "none",
    },
  });

  const setFormValues = async (data) => {
    setState({ ...state, loading: true });
    methods.setValue("polypharmacy", data.polypharmacy);
    methods.setValue(
      "drugs",
      await helpFunc.pDataForSelect(data.drugs, "drugs")
    );
    methods.setValue("polypharmacy_note", data.polypharmacy_note);
    methods.setValue(
      "high_interaction_potential",
      data.high_interaction_potential
    );
    methods.setValue(
      "high_interaction_potential_drugs",
      await helpFunc.pDataForSelect(
        data.high_interaction_potential_drugs,
        "drugs"
      )
    );
    methods.setValue(
      "high_interaction_potential_note",
      data.high_interaction_potential_note
    );
    methods.setValue("toggle_diagnoses", data.toggle_diagnoses);
    methods.setValue(
      "diagnoses",
      await helpFunc.pDataForSelect(data.diagnoses, "diagnosis")
    );
    methods.setValue("diagnoses_note", data.diagnoses_note);
    methods.setValue("renal_insufficiency", data.renal_insufficiency);
    methods.setValue("renal_insufficiency_note", data.renal_insufficiency_note);
    methods.setValue(
      "significant_biochemical_changes",
      data.significant_biochemical_changes
    );
    methods.setValue(
      "significant_biochemical_changes_note",
      data.significant_biochemical_changes_note
    );
    methods.setValue("systemic_corticosteroids", data.systemic_corticosteroids);
    methods.setValue(
      "systemic_corticosteroids_note",
      data.systemic_corticosteroids_note
    );
    methods.setValue(
      "narrow_therapeutic_window",
      data.narrow_therapeutic_window
    );
    methods.setValue(
      "narrow_therapeutic_window_drugs",
      await helpFunc.pDataForSelect(
        data.narrow_therapeutic_window_drugs,
        "drugs"
      )
    );
    methods.setValue(
      "narrow_therapeutic_window_note",
      data.narrow_therapeutic_window_note
    );
    methods.setValue("hepatic_insufficiency", data.hepatic_insufficiency);
    methods.setValue(
      "hepatic_insufficiency_note",
      data.hepatic_insufficiency_note
    );
    methods.setValue("intensive_care", data.intensive_care);
    methods.setValue("intensive_care_note", data.intensive_care_note);
    methods.setValue(
      "pharmacist_intervention_required",
      data.pharmacist_intervention_required
    );
    methods.setValue("consultation_requested", data.consultation_requested);
    methods.setValue("risk_level", data.risk_level);

    if (!careIsLocked) {
      setState({ ...state, loading: false, edit: true });
    } else {
      setState({ ...state, loading: false, activateForm: false, locked: true });
    }
  };

  const consultationRequestedWatch = useWatch({
    control: methods.control,
    name: "consultation_requested",
  });

  //Control what the risk checkbox should be set
  const highRisk = useWatch({
    control: methods.control,
    name: "pharmacist_intervention_required",
  });
  const medRisk = useWatch({
    control: methods.control,
    name: [
      "polypharmacy",
      "high_interaction_potential",
      "toggle_diagnoses",
      "renal_insufficiency",
      "significant_biochemical_changes",
      "systemic_corticosteroids",
      "narrow_therapeutic_window",
      "hepatic_insufficiency",
      "intensive_care",
      "consultation_requested",
    ],
  });

  // useEffect(() => {
  //   if (!toggle && !edit) {
  //     console.log("som tu");
  //     if (highRisk) {
  //       methods.setValue("risk_level", "3");
  //     } else if (!medRisk.every((v) => v === false)) {
  //       methods.setValue("risk_level", "2");
  //     } else {
  //       methods.setValue("risk_level", "1");
  //     }
  //   }
  // }, [highRisk, medRisk, consultationRequestedWatch]);

  useEffect(() => {
    if (highRisk) {
      methods.setValue("risk_level", "3");
    } else if (!medRisk.every((v) => v === false)) {
      methods.setValue("risk_level", "2");
    } else {
      methods.setValue("risk_level", "1");
    }
  }, [highRisk, medRisk, consultationRequestedWatch]);

  const editCheckin = () => {
    history.push(`/patient-entry-control/${id}/care/${care_id}`, {
      edit: true,
    });
  };

  //Patching or posting data from form
  const saveData = async (data) => {
    //parse data for API
    //extract diagnoses from form data
    let diagnose = data.diagnoses;
    //delete data from checkin posting data
    delete data.diagnoses;
    data = {
      ...data,
      care: careID,
      drugs: helpFunc.objToArrApi(data.drugs),
      high_interaction_potential_drugs: helpFunc.objToArrApi(
        data.high_interaction_potential_drugs
      ),
      diagnoses_drugs: helpFunc.objToArrApi(data.diagnoses_drugs),
      narrow_therapeutic_window_drugs: helpFunc.objToArrApi(
        data.narrow_therapeutic_window_drugs
      ),
      risk_level: data.consultation_requested ? "none" : data.risk_level,
    };

    //Edit existing data
    if (edit != undefined && edit) {
      try {
        setState({ ...state, submit: true, dataSaved: false });
        //updating data in db
        await patientService.patchCheckIn(data, checkin.id);
        //updating diagnoses
        await patientService.patchDiagnoses(
          diagnose,
          "checkin-diagnosis",
          checkin.diagnoses,
          checkin.id
        );
      } catch (error) {
        console.error(error);
        setError({ error: true, message: "Data nebyla uložena." });
        setState({ ...state, submit: false, dataSaved: false, popUp: true });
      }
    }
    //Saving new data
    else {
      try {
        setState({ ...state, submit: true, dataSaved: false });
        //posting new data to db
        const res = await patientService.postCheckIn(data);
        //posting new diagnoses
        await patientService.postDiagnoses(
          diagnose,
          "checkin-diagnosis",
          res.data.id
        );
      } catch (error) {
        console.error(error);
        setError({ error: true, message: "Data nebyla uložena." });
        setState({ ...state, submit: false, dataSaved: false, popUp: true });
      }
    }
    setState({ ...state, submit: false, dataSaved: true });
    if (data.risk_level != 1) {
      history.push(
        `/patient-profile/${id}/care/${care_id}/pharmacological-plan`
      );
    } else {
      history.goBack();
    }
  };

  const returnBtn = () => {
    if (!state.dataSaved) {
      setError({
        error: true,
        message: "Při opuštění stránky nebudou záznamy uloženy.",
      });
      setState({ ...state, popUp: true });
    } else {
      history.push("/");
    }
  };

  const clearErrors = () => {
    setError({ error: false });
  };

  return {
    state,
    error,
    methods,
    editCheckin,
    saveData,
    returnBtn,
    clearErrors,
    onPopUpClose,
    onPopUpSubmit,
    open,
    oldesCare,
  };
};
