import { useEffect, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";
import { useHistory, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validation } from "../../helpers/validation_schemes/patientValidation";
import { careServices } from "../../services/care.services";

const schema = yup.object().shape({
  first_name: yup.string(validation.string).required(validation.required),
  last_name: yup.string(validation.string).required(validation.required),
  birth_date: yup.string(validation.string).required(validation.required),
  birth_number: yup.string(validation.string).required(validation.required),
  height: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  weight: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  doctor: yup.string(validation.string).required(validation.required),
  external_department_extend: yup.object().shape({
    label: yup.string(validation.string).required(validation.required),
    value: yup.string(validation.string).required(validation.required),
  }),
  insurance_number: yup.string(validation.string).required(validation.required),
  insurance_company: yup.object().shape({
    label: yup.string(validation.string).required(validation.required),
    value: yup.string(validation.string).required(validation.required),
  }),
  main_diagnosis: yup.object().shape({
    label: yup.string(validation.string).required(validation.required),
    value: yup.string(validation.string).required(validation.required),
  }),
});

export const usePatientLogic = () => {
  const [state, setState] = useState({ loading: true });
  const [error, setError] = useState({ error: false });
  const [patient, setPatient] = useState();
  const { id } = useParams();
  const history = useHistory();

  const [isShow, setIsShow] = useState({
    mailAdrress: true,
    correspondenceAddress: false,
    has_disability: false,
    has_epidemiological_significance_activities: false,
    has_hearing_visual_impairment: false,
    has_representatives: false,
  });

  const handleToggle = (section) => {
    setIsShow({ ...isShow, [section]: !isShow[section] });
  };

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { control, setValue } = methods;

  const has_representatives = useWatch({
    control: methods.control,
    name: "has_representatives",
    defaultValue: false,
  });

  useEffect(() => {
    setValue("has_representatives", isShow.has_representatives);
  }, [has_representatives]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "representative",
  });

  const handleAppend = () => {
    append({
      relationship: "",
      first_name: "",
      last_name: "",
      city: "",
      descriptive_number: "",
      phone_number: "",
      street: "",
      postcode: "",
      email: "",
    });
  };

  const handleRemove = (index) => {
    remove(index);
    if (index == 0) {
      setIsShow({ ...isShow, has_representatives: false });
      setValue("has_representatives", false);
    }
  };

  const handleRepresentativeToggle = () => {
    if (has_representatives === false) {
      handleAppend();
    }

    if (has_representatives === true) {
      remove();
    }

    setIsShow({
      ...isShow,
      has_representatives: !isShow.has_representatives,
    });
  };
  //Form data

  const saveData = async (data) => {
    data = {
      ...data,
      insurance_company: data.insurance_company?.value,
      birth_date: helpFunc.parseDate(data.birth_date),
    };
    // Validace rodneho cisla - docastne vypnute
    // if (!validatePersonalNumber(data.birth_number)) {
    //     setError({error: true, message: 'Zadané rodné číslo je v nesprávném tvaru.'});
    //     return;
    // }

    if (data.birth_number.length > 10 || data.birth_date < 9) {
      setError({
        error: true,
        message:
          "Pole pro rodné číslo obsahuje nepovolený počet číslic. Povolený formát rodného čísla se skládá z devíti nebo z deseti číslic.",
      });
      return;
    }

    if (state.edit) {
      data = {
        ...data,
        name: data.last_name + " " + data.first_name,
        // current_care: {
        //   doctor: data.doctor,
        //   patient: data.id,
        //   external_department: data.external_department_extend?.value ?? "",
        //   main_diagnosis: data.main_diagnosis?.value ?? "",
        // },
      };
      try {
        await patientService.patchPatient(data.id, data);
        setState({
          ...state,
          message: `Pacient ${data.first_name} ${data.last_name} byl úspěšně upraven. U pacienta nedošlo k založení nové péče, jelikož u něj ještě nedošlo k ukončení předešlé péče`,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (error) {
        setError({ error: true, message: "Pacienta se nepodařilo upravit" });
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      let finishCareResponse;
      if (
        patient.current_care.care_type === "external" &&
        patient.current_care.is_active
      ) {
        try {
          finishCareResponse = await careServices.finishCare(
            patient.current_care.id
          );
        } catch (error) {
          setError({ error: true, message: "Péči sa nepodařilo ukončit" });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }

      if (
        !patient.current_care.is_active ||
        finishCareResponse.status === 200
      ) {
        const careData = {
          care_type: "external",
          doctor: data.doctor,
          patient: patient.id,
          external_department: data.external_department_extend?.value ?? "",
          main_diagnosis: data.main_diagnosis?.value ?? "",
          started_at: new Date(),
        };

        try {
          await careServices.createCare(careData);
          setState({
            ...state,
            message: `Pacient ${data.first_name} ${data.last_name} byl úspěšně upraven. Byla založena nová externí péče.`,
          });
        } catch (error) {
          setError({ error: true, message: "Péči se nepodařilo založit" });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }

      try {
        if (data.representative.length !== 0) {
          data = {
            ...data,
            representative: data.representative.map((item) => ({
              ...item,
              patient: data.id,
            })),
          };

          await Promise.all(
            data.representative.map(
              async (item) =>
                await patientService.postPatientRepresentativess(item)
            )
          );
        }
      } catch (error) {
        setError({
          error: true,
          message: "Pacienta se nepodařilo upravit",
        });
      }
    } else {
      //parseData
      let response;
      try {
        response = await patientService.postNewPatient(data);
        const patientId = response.data.id;
        data = {
          ...data,
          name: data.last_name + " " + data.first_name,
          current_care: {
            care_type: "external",
            doctor: data.doctor,
            patient: patientId,
            external_department: data.external_department_extend?.value ?? "",
            main_diagnosis: data.main_diagnosis?.value ?? "",
            started_at: new Date(),
          },
        };
        await patientService.patchPatient(patientId, data);
        if (data.representative.length !== 0) {
          data = {
            ...data,
            representative: data.representative.map((item) => ({
              ...item,
              patient: patientId,
            })),
          };
          response = await Promise.all(
            data.representative.map(
              async (item) =>
                await patientService.postPatientRepresentativess(item)
            )
          );
          if (response.some((item) => item.status === 201)) {
            setState({
              ...state,
              message: `Pacient ${data.first_name} ${data.last_name} byl úspěšně přidán.`,
            });
            methods.reset();
          } else {
            setError({
              error: true,
              message: "Pacienta se nepodařilo přidat",
            });
          }
        }
        setState({
          ...state,
          message: `Pacient ${data.first_name} ${data.last_name} byl úspěšně přidán.`,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => history.go(0), 3000);
      } catch (error) {
        console.error(error.response);
      }
    }
  };

  const getPatient = async () => {
    try {
      let patient = await patientService.getPatientsFilter({
        birth_number: methods.getValues("birth_number"),
      });
      if (patient.results.length === 1) {
        setState({ ...state, edit: true });
        setFormValues(patient.results[0]);
        setPatient(patient.results[0]);
      }
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba načítání pacienta" });
    }
  };

  const setFormValues = async (data) => {
    methods.setValue("id", data.id);
    const birthDate = data.birth_date ? new Date(data.birth_date) : "";
    methods.setValue("last_name", data.last_name);
    methods.setValue("first_name", data.first_name);
    methods.setValue("birth_date", birthDate);
    methods.setValue("id_number", data.id_number);
    methods.setValue("insurance_number", data.insurance_number);
    methods.setValue("insurance_company", {
      value: data.insurance_company.id,
      label: data.insurance_company.name,
    });
    // methods.setValue("main_diagnosis", {
    //   value: data.current_care.main_diagnosis.id,
    //   label: data.current_care.main_diagnosis.name,
    // });
    methods.setValue("nationality", data.nationality);
    methods.setValue("communication_languages", data.communication_languages);
    methods.setValue("weight", data.weight);
    methods.setValue("height", data.height);
    // methods.setValue("doctor", data.current_care_doctor_name);
    // methods.setValue("external_department_extend", {
    //   value: data.current_care.department.id,
    //   label: data.current_care.department.description,
    // });
    methods.setValue("city", data.city);
    methods.setValue("street", data.street);
    methods.setValue("descriptive_number", data.descriptive_number);
    methods.setValue("postcode", data.postcode);
    methods.setValue(
      "has_correspondence_address",
      data.has_correspondence_address
    );
    methods.setValue("correspondence_city", data.correspondence_city);
    methods.setValue(
      "correspondence_descriptive_number",
      data.correspondence_descriptive_number
    );
    methods.setValue("correspondence_street", data.correspondence_street);
    methods.setValue("correspondence_postcode", data.correspondence_postcode);
    methods.setValue("phone_number", data.phone_number);
    methods.setValue("email", data.email);
    methods.setValue("gender", data.gender);
    methods.setValue("has_limited_capacity", data.has_limited_capacity);
    methods.setValue("has_disability", data.has_disability);
    methods.setValue(
      "has_hearing_visual_impairment",
      data.has_hearing_visual_impairment
    );
    methods.setValue(
      "preferred_communication_form",
      data.preferred_communication_form
    );
    methods.setValue(
      "has_epidemiological_significance_activities",
      data.has_epidemiological_significance_activities
    );
    methods.setValue("activities_type", data.activities_type);
    //methods.setValue("has_representatives", data.has_representatives);
  };

  const togglePopup = () => {
    setError({ error: false });
  };

  const handleError = (error) => {
    if (error.data.birth_number) {
      setError({ error: true, message: error.data.birth_number });
      return;
    }

    setError({ error: true, message: "Pacienta se nepodařilo přidat" });
  };

  return {
    state,
    error,
    methods,
    fields,
    isShow,
    saveData,
    togglePopup,
    getPatient,
    handleToggle,
    handleAppend,
    handleRemove,
    handleRepresentativeToggle,
  };
};
