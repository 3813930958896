import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { AsyncSelectCompLogic } from "./AsyncSelectCompLogic";

export const AsyncSelectComp = ({
  name,
  endPoint,
  labelClass,
  active = true,
  label = "Seznam léčiv",
  error = undefined,
  containerClass = "input-group",
  ...props
}) => {
  const methods = useFormContext;
  const control = methods.control;
  const { promiseOptions } = AsyncSelectCompLogic(name, endPoint);

  const styles = {
    control: (props) => ({
      ...props,
      borderRadius: 0,
      minHeight: 42,
    }),
  };

  return (
    <div className={containerClass}>
      <label className={labelClass}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AsyncSelect
            isClearable
            {...field}
            styles={styles}
            isDisabled={active}
            placeholder="Vyhledejte..."
            cacheOptions
            loadOptions={promiseOptions}
            {...props}
          />
        )}
      />
      {error && (
        <p
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "164px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};
