import React from "react";
import { helpFunc } from "../../../helpers/helpFunction";
import { PatientSearchTableLogic } from "./PatientSearchTableLogic";

export const PatientSearchTable = ({ row }) => {
  const { patient, state, care } = PatientSearchTableLogic(row);
  const careType = patient?.current_care?.care_type;
  return (
    <div className="patient-info-table">
      {state.loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {state.error && <h1>Chyba načítání pacienta</h1>}
          <div className="header patient-info-cell">
            <strong>Příjmení a jméno pacienta</strong>
          </div>
          <div className="header patient-info-cell">
            <strong>{patient.name}</strong>
          </div>
          <div className="patient-info-cell strong">Datum narození</div>
          <div className="patient-info-cell">{patient.birth_date}</div>
          <div className="patient-info-cell strong">Diagnóza</div>
          <div className="patient-info-cell">
            {patient.current_care.main_diagnosis.name || "Žádná diagnóza"}
          </div>
          <div className="patient-info-cell strong">Hmotnost</div>
          <div className="patient-info-cell">
            {patient.weight || "Žádné informace"} kg
          </div>
          <div className="patient-info-cell strong">Výška</div>
          <div className="patient-info-cell">
            {patient.height || "Žádné informace"} cm
          </div>
          {careType !== "external" && (
            <>
              <div className="patient-info-cell strong">Poznámka</div>
              <div className="patient-info-cell">
                {patient.note || "Žádné poznámky"}
              </div>
            </>
          )}
          <div className="patient-info-cell strong">BMI</div>
          <div className="patient-info-cell">
            {patient.bmi || "Žádné informace"}
          </div>
          <div className="patient-info-cell strong">Rodné číslo</div>
          <div className="patient-info-cell">{patient.birth_number}</div>
          <div className="patient-info-cell strong">ID pacienta</div>
          <div className="patient-info-cell">
            {patient.external_id ?? patient.id}
          </div>
          {careType === "hospitalization" ? (
            <>
              <div className="patient-info-cell strong">
                Počet dnů hospitalizace k dnešnímu dni
              </div>
              <div className="patient-info-cell">
                {patient.days || "Žádné informace"}
              </div>
            </>
          ) : (
            <>
              <div className="patient-info-cell strong">
                Datum návštěvy ambulance
              </div>
              <div className="patient-info-cell">
                {patient.first_visit || "Žádné informace"}
              </div>
            </>
          )}
          {careType === "hospitalization" && (
            <>
              <div className="patient-info-cell strong">
                Datum posledního napsaného dekurzu
              </div>
              <div className="patient-info-cell">
                {patient?.last_dekurz || "Žádné informace"}
              </div>
            </>
          )}
          {careType === "hospitalization" ? (
            <>
              <div className="patient-info-cell strong">Ošetřující lékař</div>
              <div className="patient-info-cell">
                {patient?.current_care?.last_dekurz?.doctor?.name ||
                  "Žádné informace"}
              </div>
            </>
          ) : (
            <>
              <div className="patient-info-cell strong">Jméno lékaře</div>
              <div className="patient-info-cell">
                {patient?.current_care?.doctor || "Žádné informace"}
              </div>
            </>
          )}
          <div className="patient-info-cell strong">Klinika</div>
          <div className="patient-info-cell">
            {patient?.current_care?.clinic?.description || "Žádné informace"}
          </div>
          <div className="patient-info-cell strong">Oddělení</div>
          <div className="patient-info-cell">
            {patient.current_care_last_dekurz_department_description ||
              "Žádné informace"}
          </div>
          <button
            className="button patient-info-table-button"
            type="button"
            {...row.getToggleRowExpandedProps()}
          >
            Zpět
          </button>
          <div>{!care.loading && care.list}</div>
        </>
      )}
    </div>
  );
};
