import React from 'react' 
import { SwitchComp } from '../../components'


export const SwitchGroup = ({names = [], labels = [], classes = [], ids = [], defaultValue = [], num, sectionTitle, disabled, ...props }) => {
    const switchList = names.map((name, index) =>
            <SwitchComp key={index} name = {name} defaultValue={defaultValue[index]} label={labels[index]} id={ids[index]} disabled={disabled} num={num} /> )
    return (
        <div className='input-group'>
            <label className='strong'>{sectionTitle}</label>
            <div className='grid-row wrap'>
                { switchList }
            </div>
        </div>
    )
}