import React, { useState, useEffect } from "react";
import { ClinicBoxItem } from "../../components";
import { clinicService } from "../../services/clinic.services";

export const PatientsBedsLogic = () => {
  const [state, setState] = useState({ loading: true });
  const [error, setError] = useState({ error: false, message: "" });
  const [noCheckInCount, setNoCheckInCount] = useState("");
  const [myHospitalsList, setMyHospitalsList] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const celaNemocnice = user?.celaNemocnice;

  const fetchData = async () => {
    try {
      setState({ loading: true });
      await getMyHospitals();
      setState({ loading: false });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Problém s načítáním dat." });
    }
  };

  const getMyHospitals = async () => {
    try {
      let hospitals = await clinicService.getAllClinicsFilter("my_hospitals");
      const allClinics = await clinicService.getAllClinics("hospitals");
      let count = 0;
      allClinics.forEach(
        (item) =>
          (count = count + item.hospitalization_patient_without_checkin_count)
      );
      setNoCheckInCount(count);

      hospitals = await pMyHospitals(hospitals);
      setMyHospitalsList(hospitals);
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání vlastních klinik" });
    }
  };

  const pMyHospitals = async (hospitals) => {
    return hospitals.map((hospital) => (
      <div className="item" key={hospital.id}>
        <ClinicBoxItem
          id={hospital.id}
          abbreviation={hospital.abbreviation}
          img={hospital.image}
          description={hospital.description}
          noCheckInCount={
            hospital.hospitalization_patient_without_checkin_count
          }
          careType="hospitalization"
        />
      </div>
    ));
  };

  useEffect(() => {
    fetchData();
    return () => {
      setState({ loading: true });
    };
  }, []);

  return { state, error, noCheckInCount, myHospitalsList, celaNemocnice };
};
