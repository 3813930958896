import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Logout } from "../Authetification/Logout";
import { helpFunc } from "../../helpers/helpFunction";
import { AuthContext } from "../../contexts/AuthContext";

export const Header = () => {
  const { authToken } = useContext(AuthContext);

  return (
    <div className="layout-header">
      <div className="wrap">
        <div id="logo">
          <NavLink to="/">iPharm</NavLink>
          <span>({helpFunc.currentDate()})</span>
        </div>
        <div className="navigation">
          <div>
            <NavLink to="/help">Nápověda</NavLink>
          </div>
          {authToken && <Logout />}
        </div>
      </div>
    </div>
  );
};
