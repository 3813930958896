import React from "react";
import { CareProceduresLogic } from "./CareProceduresLogic";

export const CareProcedures = ({
  careId,
  displayCareProcedures = true,
  careType,
  ...props
}) => {
  const { procedures, error } = CareProceduresLogic(
    careId,
    displayCareProcedures
  );

  return (
    <div className="care-procedures">
      {displayCareProcedures ? (
        procedures.loading ? (
          <h2>Načítání...</h2>
        ) : (
          <table>
            <tbody>
              {careType !== "hospitalization" && (
                <>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.procedure_05752_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05752</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.unreported_05752_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05752</span>{" "}
                      <span style={{ fontSize: 14 }}>další</span>
                    </td>
                  </tr>
                </>
              )}
              {careType === "hospitalization" && (
                <>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.procedure_05751_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05751</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.procedure_05753_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05753</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.procedure_05755_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05755</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="strong">
                        {procedures.data.unreported_05755_count}x
                      </span>
                    </td>
                    <td>
                      <span className="strong">05755 </span>
                      <span style={{ fontSize: 14 }}>další</span>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        )
      ) : null}
      {error.error && <h2>Chyba při načítání výkazů.</h2>}
    </div>
  );
};
