import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export const Logout = () => {
  const [hover, setHover] = useState(false);
  const hoverIn = () => setHover(true);
  const hoverOut = () => setHover(false);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  return (
    <div className="account">
      <NavLink to="/" onMouseEnter={hoverIn}>
        <i className="fas fa-user-circle"></i>
      </NavLink>
      {hover && (
        <div className="account-menu" onMouseLeave={hoverOut}>
          <div>
            <ul>
              <li>
                <h4>{user.username}</h4>
              </li>
              <li>
                <NavLink to="/user-settings">Osobní nastavení</NavLink>
              </li>
                <li>
                  <NavLink to="/signin">Odhlášení</NavLink>
                </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
