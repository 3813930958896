import { helpFunc } from "../../helpers/helpFunction";

export const PopUpPatienEntryControl = (props) => {
  const { oldesCare, onClose, onSubmit } = props;
  return (
    <div className="modal flex-container-column">
      <div className="modal_content">
        <div className="text">
          <p className="strong">
            Byly nalezeny předchozí vstupní kontroly pacienta:
          </p>
        </div>
        <div className="list">
          <p
            className="modal-content-list"
            onClick={() => onSubmit(oldesCare.checkin)}
          >
            Vstupní kontrola ze dne:{" "}
            {helpFunc.formatDate(oldesCare.checkin.updated_at)}
          </p>
        </div>
        <div className="btn-row">
          <button type="button" className="button" onClick={onClose}>
            Zavřít
          </button>
        </div>
      </div>
    </div>
  );
};
