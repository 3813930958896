import React from "react";
import { CareProceduresLogic } from "./CareProceduresLogic";

export const CareProceduresPharmaPlan = ({
  careId,
  displayCareProcedures = true,
  reload = false,
  careType,
  ...props
}) => {
  const { procedures, error } = CareProceduresLogic(
    careId,
    displayCareProcedures,
    reload
  );

  return (
    <div className="care-procedures pharmacological-plan">
      {displayCareProcedures ? (
        procedures.loading ? (
          <h2>Načítání...</h2>
        ) : (
          <>
            <span className="header strong">Vykázání výkonů</span>
            <table>
              <tbody>
                {careType !== "hospitalization" && (
                  <>
                    <tr>
                      <td>
                        <span>
                          Ambulantní konziliární zhodnocení medikace (05752)
                        </span>
                      </td>
                      <td>
                        <span>{procedures.data.procedure_05752_count}x</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Další ambulantní konziliární zhodnocení medikace
                          (05752)
                        </span>
                      </td>
                      <td>
                        <span>{procedures.data.unreported_05752_count}x</span>
                      </td>
                    </tr>
                  </>
                )}
                {careType === "hospitalization" && (
                  <>
                    <tr>
                      <td>
                        <span>Komplexní zhodnocení medikace (05751)</span>
                      </td>
                      <td>
                        <span>{procedures.data.procedure_05751_count}x</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Výkon vztahující se k stanovení plánu (05753)
                        </span>
                      </td>
                      <td>
                        <span>{procedures.data.procedure_05753_count}x</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Ověření účinnosti stanoveného plánu (05755)</span>
                      </td>
                      <td>
                        <span>{procedures.data.procedure_05755_count}x</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          Další ověření účinnosti stanoveného plánu (05755)
                        </span>
                      </td>
                      <td>
                        <span>{procedures.data.unreported_05755_count}x</span>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </>
        )
      ) : null}
      {error.error && <h2>Chyba při načítání výkazů.</h2>}
    </div>
  );
};
