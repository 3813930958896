import React from "react";
import { FormProvider } from "react-hook-form";
import { UserSettingsForm, Form } from "../../components";
import { UserSettingsLogic } from "./UserSettingsLogic";

export const UserSettings = (props) => {
  const {
    state,
    error,
    data,
    methods,
    checkbox,
    saveData,
    handleChangeCheckbox,
  } = UserSettingsLogic();

  return (
    <div>
      <h1>Osobní nastavení</h1>
      <div className="max-w-1024">
        {state.loading ? (
          <h2>Načítání osobního nastavení...</h2>
        ) : (
          <FormProvider {...methods}>
            <Form>
              <UserSettingsForm
                title="Moje kliniky"
                label="Moje klinika "
                name="hospital"
                options={data.hospitals}
                handleChangeCheckbox={handleChangeCheckbox}
                checkbox={checkbox}
              />
              <UserSettingsForm
                title="Moje ambulance"
                label="Moje ambulance "
                name="ambulance"
                options={data.ambulances}
              />
              <div className="form-row">
                {state.submit && <span>Data se ukládají...</span>}
                {state.dataSaved && <span>Data jsou uložena</span>}
              </div>
              <div className="form-row-button">
                <button
                  type="button"
                  className="button"
                  onClick={methods.handleSubmit(saveData)}
                >
                  Uložit
                </button>
              </div>
            </Form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
