import { reduce } from "lodash";
import React, { useEffect } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import Creatable, { useCreatable } from "react-select/creatable";

export const ControlSelect = ({
  name,
  label,
  id,
  labelClass,
  options,
  isMulti,
  isDisabled,
  sectionName,
  placeholder = "Vyberte...",
  containerClass = "input-group",
  isClearable = true,
  ...props
}) => {
  const methods = useFormContext();
  const selectStyles = {
    control: (props) => ({
      ...props,
      borderRadius: 0,
      minHeight: 42,
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: "#fff",
        zIndex: 9999,
        color: "#000",
        "&:hover": {
          backgroundColor: "#f1f1f1",
        },
      };
    },
  };

  const toggle = useWatch({ control: methods.control, name: sectionName });

  useEffect(() => {
    if (!toggle) {
      clearFormValues();
    }
  }, [toggle]);

  const clearFormValues = () => {
    methods.setValue(name, []);
    methods.reset(methods.getValues(), { keepDefaultValues: true });
  };

  return (
    <div className={containerClass}>
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      )}
      <Controller
        id={id}
        name={name}
        control={methods.control}
        {...props}
        render={({ field }) => (
          <Creatable
            isClearable={isClearable}
            options={options}
            isDisabled={isDisabled}
            isMulti={isMulti ? true : false}
            styles={selectStyles}
            placeholder={placeholder}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            {...field}
          />
        )}
      />
    </div>
  );
};
