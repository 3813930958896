import React from "react";
import { FaHospitalSymbol } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";

export const Legend = (props) => {
  const { showRequest = false, showPlannedHospitalization = false } = props;
  return (
    <div className="legend">
      <span className="strong">Legenda</span>
      <ul>
        <li>Nový pacient bez vstupní kontroly</li>
        <li>Pacient s rizikovostí 1</li>
        <li>Pacient s rizikovostí 2</li>
        <li>Pacient s rizikovostí 3</li>
        <li>Pacient vyžadující vyšší pozornost</li>
        {showRequest && (
          <>
            <li>
              <span>
                <IoMdAlert />
              </span>{" "}
              Nová žádanka
            </li>
            <li>
              <span>
                <IoMdAlert />
              </span>{" "}
              Žádanka v řešení / vyřešena
            </li>
          </>
        )}
        {showPlannedHospitalization && (
          <>
            <li>
              <span>
                <FaHospitalSymbol />
              </span>
              Plánovaná hospitalizace
            </li>
          </>
        )}
      </ul>
    </div>
  );
};
