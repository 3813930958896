import React from "react";
import {
  Table,
  CheckinPharmaPlan,
  PopUp,
  ColorStripe,
  CareProceduresPharmaPlan,
} from "../../components";
import { PatientRequestsList } from "./PatientRequestsList";
import { PatientRequestsLogic } from "./PatientRequestsLogic";

export const PatientRequests = ({
  patient,
  plan = null,
  careID,
  careIsLocked = false,
  checkin,
  care = {},
  requisitions,
  getPatientData,
  ...props
}) => {
  const {
    error,
    table,
    popUp,
    procedures,
    patientRiskLevel,
    columns,
    togglePopUp,
    handleBackButton,
  } = PatientRequestsLogic(plan, checkin, patient, care, careIsLocked);

  const careType = care.care_type;

  return (
    <>
      <div className="pharma-plan-goback-btn">
        <button type="button" className="button" onClick={handleBackButton}>
          Zpět
        </button>
      </div>
      <div className="patient-profile pharmacological-plan">
        {/* Simple table */}
        {!popUp.hide && popUp.popUp}
        {error.error && <PopUp message={error.message} toggle={togglePopUp} />}
        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              {table.loading ? (
                <h1>Loading table...</h1>
              ) : table.data ? (
                <>
                  <Table data={table.data} columns={columns} />
                  <ColorStripe className={`color-stripe-${patientRiskLevel}`} />
                </>
              ) : (
                <h2>Nebyly nalezeny informace o pacientovi</h2>
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column flex-2">
              <PatientRequestsList
                requisitions={requisitions}
                getPatientData={getPatientData}
                careId={care.id}
                careType={care.care_type}
                category={care.category}
              />
            </div>
            {/* RIGHT COLUMN */}
            <div className="grid-column flex-1">
              {/* checkin component showing patient checkin data */}
              <div className="grid-row">
                {!table.loading && (
                  <CheckinPharmaPlan
                    checkin={checkin}
                    careIsLocked={careIsLocked}
                  />
                )}
              </div>
              <div className="grid-row">
                {/* Medical procedures table */}
                <CareProceduresPharmaPlan
                  careId={careID}
                  reload={procedures.reload}
                  careType={careType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
