import React from "react";
import { useFormContext } from "react-hook-form";

export const NumInput = ({
  id,
  label,
  labelClass,
  error = undefined,
  disabled = false,
  name,
  onBlur = undefined,
  ...props
}) => {
  const methods = useFormContext();

  return (
    <div className="input-group">
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <input
        type="number"
        id={id}
        className="inp-text"
        {...methods.register(name)}
        disabled={disabled}
        onBlur={onBlur}
        {...props}
      />
      {error && (
        <p
          style={{
            position: "absolute",
            bottom: "-2px",
            left: "164px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};
