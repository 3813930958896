import React, {useEffect, useState, useMemo, Fragment} from 'react'
import { FtdEvaluationForm } from '../../components'
import { helpFunc } from '../../helpers/helpFunction'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { careServices } from '../../services/care.services'

export const FtdEvaluationLogic = (checkin = {}, ftdEvaluation = [], careID, careIsLocked=false) => {
    const [ state, setState ] = useState({submit: false, dataSaved: false, popUp: false, update: false, checkinInfo: false})
    const [ error, setError ] = useState("")
    const [ table, setTable ] = useState ({loading: true})
    const [ evaluation, setEvaluation ] = useState ({loading: true})
    let history = useHistory ()
    const {id} = useParams()

    const autoCreateNewEvulation = localStorage.getItem('auto-add-ftd');

    //Load component data on mount 
    useEffect(() => {
        if (history.location?.state?.reasonUpdate) {
            getCare()
        } 
        else {
            listEvaluationForms(ftdEvaluation)
        }
        //setting checkin data, patient doesn't have to have checkin in all cases
        getCheckinData (checkin)
        return () => {
            setState({submit: false, dataSaved: false, popUp: false, update: false})
            setTable({loaded: false, loading: true})
        }
    },[])

    //Getting table data -> patient checkin data
    const getCheckinData = async (checkin) => {
        if (checkin) {
            try {
                setTable ({...table, loading: true})
                const data = await helpFunc.pCheckinData(checkin)
                setTable ({...table, loading: false, data: data})
            }
            catch (error) {
                console.error(error)
                setError({error: true, message: 'Chyba při načítání pacientovi vstupní kontroly.'})
            }
        }
        else {
            setTable({...table, loading: false, data:null})
        }
    }

    //Get new patient data when reason is updated 
    const getCare = async () => {
        try {
            // patient = await patientService.getPatient(id)
            const care = await careServices.getCare(careID)
            await listEvaluationForms (care.pharmacological_evaluations)
        } catch (error) { 
            console.error(error) 
            setError({error: true, message: 'Chyba při načítání pacienta'})
        }
    }
    //FORM SECTION
    // Decide how many forms I need to start with -> when edit -> decide how many I need 
    const listEvaluationForms = async (ftdEvaluation) => {
        if (ftdEvaluation.length != 0 ) {
            setState({...state, update: true})
            const list = ftdEvaluation.map ((item,key) => {
                return (<Fragment key={key}>
                            <FtdEvaluationForm evaluation={item} index={key+1} careID={careID} careIsLocked={careIsLocked} />
                        </Fragment>)
                }
            )
            setEvaluation((prevState) => ({...prevState, list: list, loading: false}))
            if (autoCreateNewEvulation) {
                const emptyList = <Fragment key="empty"><FtdEvaluationForm evalution={false} careID={careID} careIsLocked={careIsLocked}/></Fragment>
                setEvaluation((prevState) => ({...prevState, list: [...prevState.list, emptyList]}))
                localStorage.removeItem('auto-add-ftd');
            }
            
            }
        else {
            const list = <Fragment key={0}><FtdEvaluationForm evalution={false} careID={careID} careIsLocked={careIsLocked}/></Fragment>
            setEvaluation({list:[list], loading: false})
        }
    }

    //DOES NOT WORK WELL
    //Add new evaluation form
    const addNewEvalution = async () => {
        try {   
            let newList = <FtdEvaluationForm key={evaluation.list?.length} evaluation={false} careID={careID} />
            setEvaluation ({...evaluation, list: [...evaluation.list, newList]}) 
        } catch (error) {
            console.error(error)
            setError ({error: true, message: 'Chyba při přidávání nového hodnocení'})
        }
    }

    //TABLE SECTION  
    const columns = useMemo ( () => [
        { Header: "Výsledky vstupní kontroly", columns: [
            {
                Header: "Polypragmazie", accessor: "polypharmacy",
            },
            {
                Header: "Úzké ter. okno", accessor: "narrow_therapeutic_window",
            },     
            {
                Header: "Interakční potenciál", accessor: "high_interaction_potential",
            },      
            {
                Header: "Systémová imunosupresiva", accessor: "systemic_corticosteroids",
            },      
            {
                Header: "Riziková diagnóza", accessor: "toggle_diagnoses",
            },      
            {
                Header: "Renální insuficience", accessor: "renal_insufficiency",
            },
            {
                Header: "Hepatální insuficience", accessor: "hepatic_insufficiency",
            },
            {
                Header: "Změny HEM/BIO", accessor: "significant_biochemical_changes",
            },
            {
                Header: "Intenzivní péče", accessor: "intensive_care",
            },
            {
                Header: "Nutný zásah KF", accessor: "pharmacist_intervention_required",
            },
            {
                Header: "Vyžádané konzilium", accessor: "consultation_requested",
            },            
        ]}
    ])

    //On arrow click show patient Checkin data
    const showCheckin = () => {
        setState({...state, checkinInfo: !state.checkinInfo})
    }

    //Handeling error pop up
    const togglePopUp = () => {
        setError({error: false})
    }

    return {state, columns, table, evaluation, error, togglePopUp, addNewEvalution, showCheckin }
}

FtdEvaluationLogic.propTypes = {
    patient: PropTypes.object
}