import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";

//REFACTOR 
// loading data to component
//form default values
// no need for api call for drugs 
//better default value for multiple select

export const FtdEvaluationFormLogic = (evaluation = false, careID, careIsLocked=false) => {
    const [state, setState] = useState({loading: true, submit: false, dataSaved: false, activateForm: true, hideEvaluation: evaluation, update: false, locked: false, delete: false})
    const [error, setError] = useState("")
    const [tags, setTags] = useState("")
    const [comments, setComments] = useState({ loading: true })

    //Load data on component mount 
    useEffect(() => {
        //if there is not evaluaiton data generate new empty form
        if (!evaluation && !careIsLocked) {
            getTags()
        }
        //if care is locked deactivate the form
        else if (evaluation) {
            setFormValues(evaluation)
            getComments(evaluation.id)
        }
        //if evaluation exists set form values for form and load comments
        else {
            deactivateForm ()
        }
    }, [])

    //deactivate form when care is locked 
    const deactivateForm = () => {
        setState({...state, loading: false, activateForm: false})
    }

    // Loading tags data on mount or form edit
    const getTags = async () => {
        try {
            setState({ ...tags, loading: true })
            let data = await patientService.getTags()
            data = await helpFunc.pDataForSelect(data.results, 'tags')
            setTags({ ...tags, data: data })
            setState({...state, loading: false})
        }
        catch (error) {
            console.error(error)
            setError({ error: true, message: 'Chyba při načítání štítků do formuláře.' })
        }
    }

    //FORM SECTION 
    //setting form values from evaluaiton props
    const setFormValues = async (evaluation) => {
        setState({ ...state, activateForm: false, evaluationID: evaluation.id })
        formMethods.setValue('drug', await helpFunc.pDataForSelect([evaluation.drug], 'drugs'))
        formMethods.setValue('deployment', evaluation.deployment)
        formMethods.setValue('discontinuation', evaluation.discontinuation)
        formMethods.setValue('continuation', evaluation.continuation)
        formMethods.setValue('continuation_drug_reintroduction', evaluation.continuation_drug_reintroduction)
        formMethods.setValue('dose_change', evaluation.dose_change)
        formMethods.setValue('recommended_investigation_by_specialist', evaluation.recommended_investigation_by_specialist)
        formMethods.setValue('recommended_investigation_by_laboratory', evaluation.recommended_investigation_by_laboratory)
        formMethods.setValue('recommended_investigation_by_physical', evaluation.recommended_investigation_by_physical)
        formMethods.setValue('tdm_interpretation', evaluation.tdm_interpretation)
        formMethods.setValue('tdm_measure_level_recommendation', evaluation.tdm_measure_level_recommendation)
        formMethods.setValue('specific_adverse_effect_diagnosis', evaluation.specific_adverse_effect_diagnosis)
        formMethods.setValue('specific_adverse_effect_reporting', evaluation.specific_adverse_effect_reporting)
        formMethods.setValue('specific_consultation', evaluation.specific_consultation)
        formMethods.setValue('dosage_determination', evaluation.dosage_determination)
        formMethods.setValue('administration_method_optimization', evaluation.administration_method_optimization)
        formMethods.setValue('tags', await helpFunc.pDataForSelect(evaluation.tags))
        if (!careIsLocked) {
            setState({ ...state, update: true, loading: false}) 
        } else {
            setState({ ...state, update: true, loading: false, activateForm: false}) 
        }
    }
    // Setting default values for empty form
    const formMethods = useForm({
        defaultValues: {
            drug: { label: 'Vyhledejte...', value: "" },
            deployment: false,
            discontinuation: false,
            continuation: false,
            continuation_drug_reintroduction: false,
            dose_change: false,
            recommended_investigation_by_specialist: false,
            recommended_investigation_by_laboratory: false,
            recommended_investigation_by_physical: false,
            tdm_interpretation: false,
            tdm_measure_level_recommendation: false,
            specific_adverse_effect_diagnosis: false,
            specific_adverse_effect_reporting: false,
            specific_consultation: false,
            dosage_determination: false,
            administration_method_optimization: false,
            tags: null
        }
    })

    //COMMENT SECTION
    //Comment basic form logic 
    const commentsMethods = useForm({
        defaultValues: {
            text: ""
        }
    })

    //load comment from db
    const getComments = async (id) => {
        try {
            setComments ({...comments, loading: true})
            //loading comments form db
            let data = await patientService.getEvaluationComments(id)
            //replace null from data
            data = helpFunc.replaceNull(data)
            //parse comment data
            data = await helpFunc.pComments(data, 'evaluation', careIsLocked)
            setComments({ loading: false, list: data })
        }
        catch (error) {
            console.error(error)
            setError({ error: true, message: 'Chyba při načítání komentářů' })
        }
    }

    //HANDELING STATE
    //User want to edit evaluation plan 
    const editPlan = async (e) => {
        //no page reload
        e.preventDefault()
        //get tags for select
        await getTags()
        setState({ ...state, activateForm: true, loading: false, dataSaved: false })
    }

    const openReasons = async (e) => {
        e.preventDefault()
        setState({ ...state, activateForm: false, locked: true, evaluationID: evaluation.id, showReason: true })
    }

    const saveAndCreateNew = async (data) => {
        localStorage.setItem('auto-add-ftd', true)
        saveData(data)
    }

    //Save data on submit 
    const saveData = async (data) => {    
        setState({ ...state, submit: true, dataSaved: false })

        if (data.drug.length === 0 || data.drug === null || data.drug.value === '') {
            setError({ error: true, message: 'Nutno vyplnit kolonku Léčivo.'});
            return;
        } 

       

        try {
            const pTags = await helpFunc.createAndParseTags(helpFunc.objToArrApi(data.tags));
            data = { ...data, tags: pTags }
        } catch(error) {
            console.log(error);
            setError ({error:true, message: 'Štítek již existuje', popUp: true});
            return Promise.reject(error);
        }

        try {
            setState({ ...state, submit: true, dataSaved: false })
            data = { ...data, care: careID, drug: data.drug.value }

            let showReason = false;
            const reasons = [data.deployment, data.discontinuation, data.discontinuation, data.continuation_drug_reintroduction, data.dose_change]
            if (reasons.some((item) => item === true)) {
                showReason = true;
            }
            
            //if user is updating data
            if (state.update) {
                await patientService.patchEvaluation(data, evaluation.id)
                setState({ ...state, submit: false, dataSaved: true, activateForm: false, evaluationID: evaluation.id, showReason })
                if(!showReason) {
                    window.location.reload(false);
                }
            }
            //if new form user is posting new data 
            else {
                let response = await patientService.postEvaluation(data)
                setState({ ...state, submit: false, dataSaved: true, activateForm: false, evaluationID: response.data.id, showReason })
                if(!showReason) {
                    window.location.reload(false);
                }
            }
        }
        catch (error) {
            console.error(error)
            setError({ error: true, message: 'Chyba při ukládání dat.'})
        }
    }

    //Delete drug form
    const deleteDrug = async () => {
        setState({ ...state, deleting: true })
        if (!evaluation) {
            setState ({...state, deleting: false, delete: true})
        }
        else {
            try {
            //delete drug form db
            await patientService.deleteEvaluation(evaluation.id)
            setState({ ...state, deleting: false })
            window.location.reload(false);
            }
            catch (error) {
                console.error(error)
                setError({ error: true, message: 'Chyba při mazání dat.' })
        }
        }
    }

    //Save comment
    const saveComment = async (data) => {
        //posting to pharmaplan id 
        // setState({ ...state, submit: true})
        //posting new comment 
        commentsMethods.reset ({ text:"" })
        data = { ...data, pharmacological_evaluation: evaluation.id}
        try {
            //posting new evaluation to db
            await patientService.postEvaluationComment(data)
            setState({ ...state, submit: false})
        }
        catch (error) {
            console.error(error)
            setError({erorr: true,  message: 'Chyba při ukládíní komnetáře.' })
        }
        await getComments (evaluation.id)
    }

    //Handeling error pop up
    const togglePopUp = () => {
        setError({ error: false })
        setState({ ...state, deleting: false, submit: false, dataSaved: false })
    }

    //Toggle evaluation 
    const toggleEvaluation = () => {
        setState ({...state, hideEvaluation: !state.hideEvaluation, activateForm: evaluation ? false : true})
    }


    return { state, error, formMethods, tags, commentsMethods, comments, saveData, editPlan, deleteDrug, saveComment, togglePopUp, toggleEvaluation, openReasons, saveAndCreateNew }
}
