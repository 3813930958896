import React from "react";
import { PatientHistoryLogic } from "./PatientHistoryLogic";
import { PatientInfo, PopUp } from "../../components";

export const PatientHistory = ({ patient, careID }) => {
  const { error, careHistory, togglePopUp } = PatientHistoryLogic(patient);

  return (
    <>
      <div className="patient-profile patient-history">
        {error.error && <PopUp toggle={togglePopUp} message={error.message} />}
        {/* Sidebar patient info */}
        <PatientInfo
          patientData={patient}
          careProcedures={false}
          careID={careID}
        />
        {/* Simple table */}
        <div className="myContent">
          {/* LIST OF CARE HIDDEN BELOW TOGLE */}
          {!careHistory.loading && careHistory.list}
        </div>
      </div>
    </>
  );
};
