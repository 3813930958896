import React, { useState, createContext, useEffect } from "react";
import "./assets/css/main.css";
import { Header, Sidebar } from "./components";
import {
  Home,
  PatientsBeds,
  Help,
  AllClinics,
  AssemblyRequests,
  Signin,
  PatientLookup,
  UserSettings,
  PatientsHospital,
  PatientEdit,
  PatientNew,
  PatientEntryControl,
  PatientProfile,
  ReportsAndStatistics,
  PatientsExternal,
  TagsSettings,
  AmbulantPatients,
  AllPatients,
} from "./pages";
import { BrowserRouter, Route, Switch } from "react-router-dom"; //If trouble use Hashrouter isted BrowserRouter
import { PrivateRoute } from "./components/Authetification/PrivateRoute";
import { AuthContext } from "./contexts/AuthContext";
import { Requisitions } from "./pages/Requisitions/Requisitions";

export const App = () => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));
  const authTokenValue = { authToken, setAuthToken };

  return (
    <AuthContext.Provider value={authTokenValue}>
      <BrowserRouter>
        <div id="layout">
          {authToken && <Sidebar />}
          {/* {authToken && <Sidebar /> } */}
          <div id="content">
            <Header />
            <div className="layout-content">
              <div id="main">
                <Switch>
                  <PrivateRoute path="/" exact component={Home} />
                  <PrivateRoute
                    path="/patients-beds"
                    exact
                    component={(props) => <PatientsBeds {...props} />}
                  />
                  <PrivateRoute
                    path="/patients-hospital/:id/:state/:careType"
                    component={PatientsHospital}
                  />
                  <PrivateRoute
                    path="/all-clinics/:careType"
                    component={AllClinics}
                  />
                  <PrivateRoute
                    path="/patients-visits"
                    component={AmbulantPatients}
                  />
                  <PrivateRoute
                    path="/all-clinics/:careType"
                    component={AllClinics}
                  />
                  <PrivateRoute
                    path="/assembly-requests"
                    component={AssemblyRequests}
                  />
                  <PrivateRoute
                    path="/reports"
                    component={ReportsAndStatistics}
                  />
                  <PrivateRoute
                    path="/patients-external/:state"
                    component={PatientsExternal}
                  />
                  <PrivateRoute path="/patient-new" component={PatientNew} />
                  <PrivateRoute
                    path="/patient-edit/:id"
                    component={PatientEdit}
                  />
                  <PrivateRoute path="/settings" component={TagsSettings} />
                  <Route path="/help" component={Help} />
                  <Route path="/signin" component={Signin} />
                  <PrivateRoute
                    path="/user-settings"
                    component={(props) => (
                      <UserSettings {...props} history={props.history} />
                    )}
                  />

                  {/* Routes not accesible from main navigaiton */}
                  <PrivateRoute
                    path="/patient-profile/:id/care/:care_id"
                    component={PatientProfile}
                  />
                  <PrivateRoute
                    path="/patient-lookup"
                    component={PatientLookup}
                  />
                  <PrivateRoute
                    path="/patient-entry-control/:id/care/:care_id"
                    component={PatientEntryControl}
                  />
                  <PrivateRoute
                    path="/requisitions/:careType"
                    component={Requisitions}
                  />
                  <PrivateRoute
                    path="/all-patients/:careType"
                    component={AllPatients}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};
