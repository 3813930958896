import React from "react";

export const AssemblyRequests = () => {
    return (
    <div>
        <h1>Žádosti o konzilia</h1>
        <br /><br /> 

    </div>
    )
}
