import { FormProvider } from "react-hook-form";
import { TextInput } from "../Form/TextInput";
import { Form, SwitchComp, TextAreaFormInp } from "../../components";

export const PredefinedTextForm = ({ savePredefinedText, methods }) => {
  return (
    <FormProvider {...methods}>
      <Form className="myForm">
        <div className="form-row">
          <TextInput
            name="name"
            id="name"
            label="Název"
            labelDisplay={false}
            placeholder="Název"
          />
        </div>
        <div className="form-row text-inp">
          <TextAreaFormInp
            name="text"
            rows={5}
            columns={15}
            maxlenght={5000}
            containerClass="predefined-textarea w-100"
          />
        </div>
        <div className="form-row">
          <SwitchComp name="is_own" id="is_own" label="Vlastní" />
        </div>
        <div className="text-center">
          <button
            type="button"
            className="button"
            onClick={methods.handleSubmit(savePredefinedText)}
          >
            Uložit
          </button>
        </div>
      </Form>
    </FormProvider>
  );
};
