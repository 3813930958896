import React from "react";
import { NavLink } from "react-router-dom";
import {
  Checkin,
  PageNavigation,
  PatientInfo,
  RecordHistory,
} from "../../components";
import { PatientEntryControlLogic } from "./PatientEntryControlLogic";

export const PatientEntryControl = () => {
  const { patient, error, navigation, setNavigation } =
    PatientEntryControlLogic();

  return (
    <>
      {patient.loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <PageNavigation className="sub-page-navigation present m-0">
            <div>
              <a
                className={navigation === "entry-control" ? "active" : null}
                onClick={() => setNavigation("entry-control")}
              >
                Formulář vstupní kontroly
              </a>
            </div>
            {patient?.data.cares?.length > 1 && (
              <div>
                <a
                  className={navigation === "record-history" ? "active" : null}
                  onClick={() => setNavigation("record-history")}
                >
                  Historie záznamů
                </a>
              </div>
            )}
          </PageNavigation>
          <div className="pharma-plan-goback-btn" style={{ marginTop: 30 }}>
            <NavLink
              to={`/patient-profile/${patient.data.id}/care/${patient.careID}/pharmacological-plan`}
            >
              <button type="button" className="button">
                Zpět
              </button>
            </NavLink>
          </div>
          {navigation === "entry-control" && (
            <div className="patient-entry-control">
              {error && <h2>{error.message}</h2>}
              <PatientInfo
                patientData={patient.data}
                careID={patient.careID}
                careData={patient.care}
              />
              <div className="max-w-1024">
                <Checkin
                  patient={patient.data}
                  checkin={patient.care.checkin}
                  careID={patient.careID}
                  careIsLocked={false}
                />
              </div>
            </div>
          )}
          {navigation === "record-history" && (
            <RecordHistory
              patient={patient.data}
              care={patient.care}
              checkin={patient.care.checkin}
              careIsLocked={patient.care.is_locked}
              careID={patient.care.id}
              plan={patient.care.pharmacologicalplan}
              isPharmaPlan={false}
            />
          )}
        </div>
      )}
    </>
  );
};
