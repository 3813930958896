import React from 'react' 
import { Form, SwitchGroup, FtdEvaluationReasonFormLogic, TextInput, PopUp } from '../../components'
import { FormProvider } from 'react-hook-form'
import { Redirect, useParams } from 'react-router-dom'

export const FtdEvaluationReasonForm = ({careID, careIsLocked = false, ...props}) => {
    const {state, error, evaluation, formMethods, saveData, togglePopUp, backToEvaluation} = FtdEvaluationReasonFormLogic ()
    const {id} = useParams()

    return (
        <div className='patient-profile'>
            <div className='max-w-1524'>
                <div className='myContent'>
                    {error.error && <PopUp toggle={togglePopUp} message={error.message} />}
                        {evaluation.loading ? <h2>Načítání FTD hodnocení...</h2> : 
                        <FormProvider {...formMethods}>
                            <Form className='myForm'>
                                { evaluation.data.deployment && 
                                <>
                                    <div className='grid-row'>
                                        <div className='grid-column'>
                                            <SwitchGroup sectionTitle={"Důvody nasazení léčiva"} num={4}
                                                names={['deployment_initial_diagnosis','deployment_during_diagnosis', 'deployment_ft_approach']} 
                                                labels={["Diagnóza ve vstupní anamnéze", 'Diagnóza v průběhu hospitalizace', "Vhodnější FT postup"]}
                                                disabled={careIsLocked}/>
                                        </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='grid-column'>
                                            <TextInput label={"Jiný důvod:"} labelClass='strong' name={"deployment_other_reason"} disabled={careIsLocked} />
                                        </div>
                                    </div>
                                </>
                                }
                                { evaluation.data.continuation_drug_reintroduction && 
                                <>
                                    <div className='grid-row form-background m-bot-0'>
                                        <div className='grid-column'>
                                            <SwitchGroup sectionTitle={"Znovunasazení léčiva"} num={4}
                                                names={['continuation_medical_intervention']} labels={['Po lékařské intervenci']}  
                                                disabled={careIsLocked}/>
                                        </div>
                                    </div>
                                    <div className='grid-row form-background'>
                                        <div className='grid-column'>
                                            <TextInput label={"Jiný důvod:"} labelClass='strong' name={"continuation_other_reason"} disabled={careIsLocked} />
                                        </div>
                                    </div>
                                </>
                                }
                                { evaluation.data.discontinuation && 
                                <>
                                    <div className='grid-row'>
                                        <div className='grid-column'>
                                        <SwitchGroup sectionTitle={"Důvody vysazení léčiva"} num={3}
                                            names={['discontinuation_contradiction', 'discontinuation_adverse_effect', 'discontinuation_adverse_effect_risk', 'discontinuation_missing_indication', 'discontinuation_allergies',
                                            'discontinuation_underdosage_risk','discontinuation_overdosage_risk', 'discontinuation_drug_interaction', 'discontinuation_duplicity', 'discontinuation_renal_insufficiency',  
                                            'discontinuation_hepatic_insufficiency', 'discontinuation_medical_intervention']} 
                                            labels={["Kontraindikace", "Projev nežádoucího účinku", "Riziko nežádoucího účinku", "Chybějící indikace", "Alergie",
                                            "Rizika poddávkování", "Rizika předávkování", "Léková interakce", "Duplicity", "Renální insuficience", "Hepatální insuficience", "Lékařská intervence"]} 
                                            disabled={careIsLocked}/>
                                        </div>
                                    </div>
                                    <div className='grid-row'>
                                        <div className='grid-column'>
                                            <TextInput label={"Jiný důvod:"} labelClass='strong' name={"discontinuation_other_reason"} disabled={careIsLocked} />
                                        </div>
                                    </div>
                                </>
                                }
                                { evaluation.data.dose_change && 
                                <>
                                    <div className='grid-row form-background m-bot-0'>
                                        <div className='grid-column'>
                                        <SwitchGroup sectionTitle={"Změna dávky"} num={3}
                                            names={['dose_change_adverse_effect', 'dose_change_adverse_effect_risk', 'dose_change_hepatic_insufficiency', 'dose_change_renal_insufficiency',
                                            'dose_change_drug_interaction', 'dose_change_underdosage', 'dose_change_overdosage', 'dose_change_laboratory_findings', 'dose_change_dosage_reduction', 'dose_change_dosage_increase' ]}
                                            labels={['Projev nežád. účinku', "Riziko nežád. účinku", 'Hepatální insuficience', "Renální insuficience", "Léková interakce", 
                                            "Rizika poddávkování", "Rizika předávkování", "Na základě lab. výsledků", "Snížení dávky", "Zvýšení dávky"]} 
                                            disabled={careIsLocked}/>
                                        </div>
                                    </div>
                                    <div className='grid-row form-background'>
                                        <div className='grid-column'>
                                            <TextInput label={"Jiný důvod:"} labelClass='strong' name={"dose_change_other_reason"} disabled={careIsLocked} />
                                        </div>
                                    </div>
                                </>
                                }
                                <div className='grid-row'>
                                    <div className='grid-column'>
                                        {state.submit && <span className="strong">Data se ukládají...</span>}
                                        {state.dataSaved && <span className="strong">Data jsou uložena.</span>}
                                    </div>
                                </div>
                                <div className='form-row-button'>
                                    {careIsLocked ? <button type='submit' className='button' onClick={backToEvaluation}>Zpět na FTD hodnocení</button> 
                                    : <button type='submit' className='button' onClick={formMethods.handleSubmit(saveData)}>Uložit</button>}
                                </div>
                                {state.dataSaved && 
                                <Redirect to= {{
                                    pathname: `/patient-profile/${id}/care/${careID}/ftd-evaluation/`, 
                                    push: true,
                                    state: {reasonUpdate: true} }} /> }
                                {state.locked && 
                                <Redirect to= {{
                                    pathname: `/patient-profile/${id}/care/${careID}/ftd-evaluation/`, 
                                    push: true,
                                    state: {reasonUpdate: false} }} /> }
                            </Form>
                        </FormProvider>
                    }
                </div>
            </div>
        </div>
    )
}