import React from 'react' 
import { FormProvider } from 'react-hook-form'
import { SwitchComp, Form } from '../../../components'
import { PatientConditionChangeLogic } from './PatientConditionChangeLogic'

export const PatientConditionChange = ({planID = null, conditionChange = false, careIsLocked = false}) => {
    const { state, formMethods } = PatientConditionChangeLogic (planID, conditionChange, careIsLocked)

    return (
        <FormProvider {...formMethods}>
            <Form className='myForm'>
                <SwitchComp name='patient_condition_change' num={0} disabled={careIsLocked} inputGroup='flex-container-row' classLabel='strong' label="Změna zdravotního stavu" />
            </Form>
        </FormProvider>
    )
}