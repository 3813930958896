import React from 'react' 
import {useHistory} from 'react-router-dom'

export const PopUpCheckin = ({message, clearErrors, toggle, ...props}) => {
    const history = useHistory ()

    const handleClick = () => {
        toggle()
    }

    const goBack = () => {
        history.goBack()
    }

    return (
        <div className="modal flex-container-column">
            <div className="modal_content">
                <div className='text'>
                    <p className='strong'>{message}</p>
                </div>
                <div className='btn-row flex-container-row'>
                    <button type='button' className="button mr-2" onClick={goBack}>Zpět</button>
                    <button type='button' className="button" onClick={handleClick}>Pokračovat v úpravách</button>
                </div>
            </div>
        </div>
    )

}