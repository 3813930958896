import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

export const CheckboxComp = ({
  id,
  name,
  containerClass = "",
  label,
  value,
  num,
  onClick = () => {},
  ...props
}) => {
  const [checked, setChecked] = useState(value);
  const methods = useFormContext();

  const onChange = (e) => {
    setChecked(!checked);
    onClick();
  };

  return (
    <div className={`col-md-6 col-lg-${num} form-group ${containerClass}`}>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <input
          className="styled-checkbox"
          type="checkbox"
          id={id}
          checked={checked}
          data-value={value}
          onClick={onChange}
          {...methods.register(name)}
        />
        <label className="ml-3" htmlFor={id}>
          {label}
        </label>
      </div>
    </div>
  );
};

{
  /* <div className={`grid-column col-${num} ${inputGroup}`} >  */
}
