import { useHistory, useParams } from "react-router-dom";

export const CheckinPharmaPlanLogic = (checkin = null) => {
  const history = useHistory();
  const { id, care_id } = useParams();

  //get patient risk level for stripe color under the table and checkin heading background
  const getPatientRiskLevel = (checkin = {}) => {
    switch (checkin?.risk_level) {
      case "1":
        return "blue";
      case "2":
        return "orange";
      case "3":
        return "red";
      case "none":
        return "none";
    }
  };

  const patientRiskLevel = getPatientRiskLevel(checkin);

  //Redirect to checkin page when user click the button
  const editCheckin = () => {
    history.push(`/patient-entry-control/${id}/care/${care_id}`, {
      edit: true,
    });
  };

  return { patientRiskLevel, editCheckin };
};
