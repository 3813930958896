import React from 'react' 
import { Patient } from '../../components'

export const PatientNew = (props) => {
    return (
        <div>
            <h1>Nový pacient</h1>
            <Patient edit={false}/>
        </div>
    )
}