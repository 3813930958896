import React from "react";
import { FormProvider } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { PatientSearchLogic } from "./PatientSearchLogic";
import { PatientSearchRes } from "./PatientSearchRes";
import {
  Form,
  TextInput,
  AsyncSelectComp,
  Pagination,
  PopUp,
} from "../../components";

export const PatientSearch = () => {
  const {
    error,
    togglePopup,
    methods,
    patients,
    tags,
    onPaginate,
    getPatients,
    paramsState,
    columns,
  } = PatientSearchLogic();
  return (
    <div className="max-w-1024 patient-search">
      <FormProvider {...methods}>
        {error.error && <PopUp toggle={togglePopup} message={error.message} />}
        <Form className="myForm">
          <div className="form-row">
            <div className="form-column">
              <TextInput
                name="external_id"
                id="external_id"
                label="ID pacienta"
                labelClass="strong"
              />
              <TextInput
                name="birth_number__contains"
                id="birth_number__contains"
                label="RČ Pacienta"
                labelClass="strong"
              />
              {!tags.loading && (
                <AsyncSelectComp
                  name="tag"
                  label="Štítky pacienta"
                  labelClass="strong"
                  endPoint="tags"
                  isDisabled={false}
                />
              )}
            </div>
            <div className="form-column">
              <TextInput
                name="last_name__startswith"
                id="last_name__startswith"
                label="Příjmení pacienta"
                labelClass="strong"
              />
              <TextInput
                name="first_name__startswith"
                id="first_name__startswith"
                label="Jméno pacienta"
                labelClass="strong"
              />
            </div>
          </div>
          <div className="form-row-button">
            <button
              type="submit"
              disabled={patients.loading}
              onClick={methods.handleSubmit(getPatients)}
              className="button"
            >
              Vyhledat
            </button>
            <NavLink to="/patients-beds">
              <button className="button btn-light">Zpět</button>
            </NavLink>
          </div>
          <div className="form-row">
            {patients.loading ? (
              <h2>Načítání...</h2>
            ) : (
              <>
                <PatientSearchRes data={patients.data} columns={columns} />
                <Pagination
                  onSubmit={onPaginate}
                  pagination={patients.pagination ? patients.pagination : {}}
                  paramsState={paramsState}
                />
              </>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
