import React, {Fragment} from 'react' 
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import AsyncSelect  from 'react-select/async'
import {FaRegMinusSquare,FaRegPlusSquare } from 'react-icons/fa'
import { AsyncSelectArrCompLogic } from './AsyncSelectArrCompLogic'

export const AsyncSelectArrComp = ({name, endPoint, labelClass, active = true, displayBtns = true, label = "Seznam léčiv", ...props }) => {
    const methods = useFormContext
    const { control } = methods
    const {fields, remove, append} = useFieldArray({name: name, control})
    const { promiseOptions } = AsyncSelectArrCompLogic(name, endPoint)
    
    const styles = {
        control: (props) => ({
            ...props,
            borderRadius: 0,
            minHeight: 42,
    })}

    return (
        fields.map ((item,i) => (
            <div className='input-group' key={item.id}>
                <label className={labelClass}>{label}</label>
                <Controller name={`${name}.${i}`} control={methods.control} render = { ( {field} ) => (
                    <AsyncSelect isClearable {...field} styles={styles} isDisabled={active} placeholder='Vyhledejte...' cacheOptions loadOptions={promiseOptions} {...props} /> )}
                />
                {/* Add logic to display icons */}
                { displayBtns && 
                <div className='input-icons'>
                    <a onClick={() => append ({value: "", label: "Vyhledejte..."})}>
                        <FaRegPlusSquare id="plus" />
                    </a>
                    <a onClick={() => { fields.length <= 1 ? alert("Nemůžete odstranit všechna léčiva") : remove(i) } }>
                        <FaRegMinusSquare id="minus" />
                    </a>
                </div> 
                }
            </div>
        ))
    )
}



