import React, {useState} from 'react' 
import {IoIosClose} from 'react-icons/io'

export const TagBlock = ({title= "", delTag, id, active = true, containerClass = ""}) => {
    const [ hide, setHide ] = useState(false)
    const onClick = () => {
        delTag(id)
        setHide (!hide)
    }

    return (
        <div className={`tag ${hide ? 'hidden' : ""} ${containerClass}`}>
            <span className='strong'>{title}</span>
            {active && <IoIosClose onClick={onClick} />}
        </div>
    )
}