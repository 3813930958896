import React from 'react' 

export const ContentContainer = ({label = "", value ="", old= false, ...props}) => {
    return (
        <div className='flex-container-row content-history-container' >
            <div className='flex-container-column content-history-label' >
                <label>{label}</label>
            </div>
            <div className='flex-container-column content-history-value' >
                {old ? <span><i>{value}</i></span> : <span>{value}</span>}
            </div>
        </div>
    )
}