import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { patientService } from "../../services/patient.services";

export const PatientInformationFilesLogic = (patientData = {}, patientId) => {
  const [state, setState] = useState({
    submit: false,
    saved: false,
    edit: false,
  });
  const [error, setError] = useState({ error: false });
  const formMethods = useForm({
    defaultValues: {
      text: "",
    },
  });

  useEffect(() => {
    if (patientData.text.length > 0) {
      setFormValues(patientData);
    }
  }, []);

  //set form values user is editign data
  const setFormValues = (data) => {
    formMethods.setValue("text", data.text);
    formMethods.setValue("file", data.file);
    setState({ ...state, edit: true });
  };

  //saving patient informations
  const saveData = async (data) => {
    setState({ ...state, saving: true });
    if (data.text == null || data.text == undefined || data.text === "") {
      setError({
        error: true,
        message: "Záznam nelze uložit. Je potřeba vyplnit jeho popis.",
      });
    } else {
      //posting new comment
      postData(data);
    }
  };

  const postData = async (data) => {
    const formData = new FormData();

    const isBlob = typeof data.file === "string" || data.file instanceof String;

    if (data.file !== null && data.file[0] && !isBlob) {
      formData.append("file", data.file[0], data.file[0].name);
    }
    formData.append("text", data.text);
    formData.append("patient", patientId);
    if (!state.edit) {
      try {
        setState({ ...state, submit: true });
        await patientService.postPatientInformation(formData);
        setState({ ...state, saved: true, submit: false });
      } catch (error) {
        console.error(error);
        setError({ error: true, message: "Chyba při ukládání dat." });
      }
    } else {
      try {
        setState({ ...state, submit: true });
        await patientService.patchPatientInformation(formData, patientData.id);
        setState({ ...state, saved: true, submit: false });
      } catch (error) {
        console.error(error);
        setError({ error: true, message: "Chyba při ukládání dat." });
      }
    }
    window.location.reload(false);
  };

  //HANDELING SAVING ERROR POPUP
  const togglePopUp = () => {
    formMethods.clearErrors();
    setError({ error: false });
  };

  return { state, formMethods, error, saveData, togglePopUp };
};
