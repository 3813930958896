import { useEffect } from "react";
import { useState } from "react";

export const NumberField = (props) => {
  const { filter, onChange, onSubmit, field, type = "number" } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  const opt = [
    { value: equal, label: "Rovná se" },
    { value: min, label: "Větší nebo rovná se" },
    { value: max, label: "Menší nebo rovná se" },
  ];

  const [option, setOption] = useState({
    value: equal,
    label: "Rovná se",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[min]) {
      setValue(filter[min]);
      setOption(opt.find((item) => item.value === min));
    } else if (filter[max]) {
      setValue(filter[max]);
      setOption(opt.find((item) => item.value === max));
    } else if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[min]) {
      setOption(opt.find((item) => item.value === min));
    } else if (filter[max]) {
      setOption(opt.find((item) => item.value === max));
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    const oldEvent = {
      target: { value: value ? value : "", name: option.value },
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="age-select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <input
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </td>
  );
};
