import React, { useState, useEffect } from "react";
import { ClinicBoxItem } from "../../components";
import { clinicService } from "../../services/clinic.services";

export const AmbulantPatientsLogic = () => {
  const [state, setState] = useState({ loading: true });
  const [error, setError] = useState({ error: false, message: "" });
  const [noCheckInCount, setNoCheckInCount] = useState("");
  const [myAmbulantList, setMyAmbulantList] = useState("");

  const fetchData = async () => {
    try {
      setState({ loading: true });
      await getMyHospitals();
      setState({ loading: false });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Problém s načítáním dat." });
    }
  };

  const getMyHospitals = async () => {
    try {
      let ambulants = await clinicService.getAllClinicsFilter("my_ambulances");
      const allClinics = await clinicService.getAllClinics("abmulances");
      let count = 0;
      allClinics.forEach(
        (item) =>
          (count =
            count + item.ambulation_patient_without_checkin_count)
      );
      setNoCheckInCount(count);

      ambulants = await pMyHospitals(ambulants);
      setMyAmbulantList(ambulants);
    } catch (error) {
      console.error(error);
      setError({
        error: true,
        message: "Chyba při načítání vlastních ambulancí",
      });
    }
  };

  const pMyHospitals = async (ambulants) => {
    return ambulants.map((ambulant) => (
      <div className="item" key={ambulant.id}>
        <ClinicBoxItem
          id={ambulant.id}
          abbreviation={ambulant.abbreviation}
          img={ambulant.image}
          description={ambulant.description}
          noCheckInCount={
            ambulant.ambulation_patient_without_checkin_count
          }
          careType="ambulation"
        />
      </div>
    ));
  };

  useEffect(() => {
    fetchData();
    return () => {
      setState({ loading: true });
    };
  }, []);

  return { state, error, noCheckInCount, myAmbulantList };
};
