import React from "react";
import { AsyncSelectComp } from "../../Form/AsyncSelectComp/AsyncSelectComp";
import { Input } from "../../Form/Inputs/Input";
import { IoMdClose } from "react-icons/io";

export const SecondaryDrugTableInput = (props) => {
  const { fields, remove } = props;
  return (
    <table class="table table-bordered adverse-effect-table">
      <thead>
        <tr>
          <th scope="col">
            <label htmlFor="">Název léčiva</label>
          </th>
          <th scope="col">
            <label htmlFor="">Síla</label>
          </th>
          <th scope="col">
            <label htmlFor="">Dávkování</label>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((item, index) => (
          <tr key={`index${index}`}>
            <td style={{ width: 300 }}>
              <AsyncSelectComp
                name={`simultaneous.${index}.drug`}
                endPoint="drugs"
                active={false}
                labelClass="d-none"
                containerClass="w-100"
              />
            </td>
            <td>
              <Input name={`simultaneous.${index}.dosage`} />
            </td>
            <td>
              <Input name={`simultaneous.${index}.strength`} />
            </td>
            <td>
              <IoMdClose
                onClick={remove}
                style={{ color: "red", cursor: "pointer", margin: "12 8" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
