import {useState, useEffect} from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export const DiagnosesLogic = (sectionName, noteName="", diagnoseName = "", clearForm, deactivateFunc ) => {
    const {getValues, setValue, control, reset} = useFormContext()
    const [state, setState] = useState({active: deactivateFunc ? getValues(sectionName) : true})
    const toggle = useWatch({control: control, name: sectionName})
    
    useEffect (() => {
        if (!toggle) {
            clearFormValues()
        }
    }, [toggle])

    const clearFormValues = () => {
        setValue(diagnoseName, [
            {
                label: "Vyhledejte...", value: "",
                drugs: [{label: "Vyhledejte...", value: ""}]
            }
        ])
        reset(getValues(), {keepDefaultValues: true});
        setValue(noteName, "")
    }
    
    const toggleSection = () => {
        if (deactivateFunc) {
            setState({...state, active: !state.active})
        }
        clearForm(state.active)
    }
    
    

    return {state, toggleSection}
}