import React, { Fragment } from 'react' 
import AsyncSelect  from 'react-select/async'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import { FieldArrayLogic } from './FieldArrayLogic'
import { NestedAsyncSelectArrComp } from '../../../components'
import { FaRegMinusSquare,FaRegPlusSquare } from 'react-icons/fa'

export const FieldArray = ({name, endPoint, nestedName, nestedEndPoint, labelClass, active, displayBtns = true, label = "Seznam léčiv", ...props }) => {
    const {control, setValue} = useFormContext ()
    const {fields, remove, append} = useFieldArray({name: name, control})
    const { promiseOptions } = FieldArrayLogic(name, endPoint)
    const styles = {
        control: (props) => ({
            ...props,
            borderRadius: 0,
            minHeight: 42,
    })}

    const onChange = (e, name) => {
        setValue(name.name, e)
    }

    return (    
        <>
        { fields.map ((item,i) => (
            <Fragment key={item.id}>
            <div className='input-group'>
                <label className={labelClass}>{label}</label>
                <Controller name={`${name}[${i}]`} control={control} render = { ( {field} ) => (
                    <AsyncSelect isClearable {...field} onChange={(e,name) => onChange(e,name)} styles={styles} isDisabled={active} placeholder='Vyhledejte...' cacheOptions loadOptions={promiseOptions} {...props} /> )}
                />
                {/* Add logic to display icons */}
                { displayBtns &&    
                    <div className='input-icons'>
                        <a onClick={() => append ({value: "", label:"Vyhledejte...",
                                                    [nestedName]: [{label:'Vyhledejte...', value: ''}]})}>
                            <FaRegPlusSquare id="plus" />
                        </a>
                        <a onClick={() => { fields.length <= 1 ? alert("Nemůžete odstranit všechny diagnózy") : remove(i) } }>
                            <FaRegMinusSquare id="minus" />
                        </a>
                    </div> 
                }
            </div>
            <div className='m-left-1'>
                <NestedAsyncSelectArrComp nestedIndex={i} arrName={name} name={nestedName} endPoint={nestedEndPoint} active={active} label='Léčivo' displayBtns={displayBtns} labelClass={labelClass} />
            </div>
            </Fragment>
            ))
        }
        </>
    )
}



