import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { CareHistory } from "./CareHistory/CareHistory";

export const PatientHistoryLogic = (patient = null) => {
  const [error, setError] = useState({ error: false });
  const [careHistory, setCareHistory] = useState({
    loading: true,
    list: false,
  });

  //Get patient history data on component mount
  useEffect(() => {
    listCares(patient.cares || []);
  }, []);

  //find all patients cares
  const listCares = (cares) => {
    setCareHistory({ ...careHistory, loading: true });
    let care = cares.map((care) => {
      return (
        <Fragment key={care.id}>
          <CareHistory careData={care} patient={patient} />
        </Fragment>
      );
    });
    setCareHistory({ ...careHistory, loading: false, list: care });
  };

  //Clean errors and toggle popup
  const togglePopUp = () => {
    setError({ error: false });
  };

  return { error, togglePopUp, careHistory };
};

PatientHistoryLogic.propTypes = {
  patient: PropTypes.object,
};
