import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { helpFunc } from "../../../helpers/helpFunction";

export const PatientSearchTableLogic = (row) => {
  const [state, setState] = useState({ loading: true, moreInfo: false });
  const [error, setError] = useState("");
  const [patient, setPatient] = useState("");
  const [care, setCare] = useState({ loading: true });
  const patientData = row.original;
  const history = useHistory();

  useEffect(() => {
    getPatient(patientData);
  }, []);

  //Get patient info
  const getPatient = async (patientData) => {
    let data = await pPatientData(patientData);
    let cares = await listCareData(patientData.cares, patientData.id);

    setPatient(data);
    setCare({ ...care, loading: false, list: cares });
    setState({ ...state, loading: false });
  };

  //Parse patient data
  const pPatientData = async (data) => {
    const birth_date = helpFunc.formatDate(data.birth_date);
    const bmi = helpFunc.countBmi(data.weight, data.height);
    const days = helpFunc.countDaysInHospital(data.current_care.started_at);
    const last_dekurz = data?.current_care?.last_dekurz
      ? helpFunc.formatDate(data?.current_care?.last_dekurz?.made_at)
      : "Chybí data";
    const first_visit = helpFunc.formatDate(data?.current_care?.started_at);

    return {
      ...data,
      bmi: bmi,
      days: days,
      last_dekurz: last_dekurz,
      birth_date: birth_date,
      first_visit,
    };
  };

  //List all care data
  //Hospital table -> Only active cares
  //Patient search -> All cares
  //Released patients -> Only unactive cares
  const listCareData = async (cares, patientID) => {
    return cares.map((care) => {
      return (
        <div key={care.id}>
          <span>Péče od: {helpFunc.formatDate(care.started_at)}</span>
          <br />
          {care.finished_at === "" || care.finished_at === null ? (
            <span>Aktivní</span>
          ) : (
            <span>Péče do: {helpFunc.formatDate(care.finished_at)}</span>
          )}
          <br />
          {care.is_locked && <span>Uzamčena</span>} <br />
          <button className="button" onClick={() => openCare(care, patientID)}>
            Otevřít pacienta
          </button>
        </div>
      );
    });
  };

  const openCare = (care, patientID) => {
    //link to id
    if (care.is_active && (care.checkin === "" || care.checkin === null)) {
      history.push(`/patient-entry-control/${patientID}/care/${care.id}`);
    } else {
      history.push(
        `/patient-profile/${patientID}/care/${care.id}/pharmacological-plan`
      );
    }
  };

  const showMoreInfo = () => {
    setState({ ...state, moreInfo: !state.moreInfo });
  };

  return { patient, error, state, showMoreInfo, care };
};
