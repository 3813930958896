import { Pagination } from "../Pagination";
import { PopUp } from "../PopUps/PopUp";
import { RequisitionsListLogic } from "./RequisitionsListLogic";
import { RequisitionsListTable } from "./RequisitionsListTable";

export const RequisitionsList = (props) => {
  const { careType } = props;

  const {
    columns,
    data,
    loading,
    params,
    filter,
    error,
    togglePopup,
    getRequisitions,
    handleFilterChange,
    handleResetFilter,
    handleDateChange,
  } = RequisitionsListLogic({ careType });
  return (
    <div className="requisitions">
      <div className="max-w-auto">
        <div className="myContent">
          {loading ? (
            <h2>Načítání...</h2>
          ) : (
            <>
              {error.error && (
                <PopUp toggle={togglePopup} message={error.message} />
              )}
              <RequisitionsListTable
                data={data.data}
                columns={columns[careType]}
                filter={filter}
                handleFilterChange={handleFilterChange}
                handleDateChange={handleDateChange}
                handleResetFilter={handleResetFilter}
                onSubmit={getRequisitions}
                careType={careType}
              />

              <Pagination
                onSubmit={getRequisitions}
                pagination={data.pagination}
                paramsState={params}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
