import React, { createContext, useEffect } from "react";
import { useExpanded, useTable, useFilters, useSortBy } from "react-table";
import { PatientHistoryHead } from "./PatientHistoryHead";
import { PatientHistoryBody } from "./PatientHistoryBody";

export const TableContext = createContext({});

export const PatientHistoryTable = ({
  columns,
  data,
  patient,
  filters,
  ...props
}) => {
  const filterTypes = React.useMemo(
    () => ({
      dateBetweenFilter: filters.dateRangeFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: "date",
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useExpanded
  );
  const { getTableProps } = tableInstance;

  useEffect(() => {
    if (filters.userFilter != undefined) {
      tableInstance.setFilter("user", filters.userFilter.value);
    }
    if (filters.valueFilter != undefined) {
      tableInstance.setFilter("value", filters.valueFilter.value);
    }
    if (filters.date.from != null || filters.date.to != null) {
      tableInstance.setFilter("date", filters.date);
    }
  }, [filters]);

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="myTable" {...getTableProps()}>
        <PatientHistoryHead />
        <PatientHistoryBody />
      </table>
    </TableContext.Provider>
  );
};
