import { useState } from "react";
import { patientService } from "../../services/patient.services";

export const CommentLogic = (type = "", id=null) => {
    const [ error, setError ] = useState ({error: false, message: ""})


    const deleteComment = () => {
        switch (type) {
            case 'evaluation':
                deleteEvaluation ()
                break;
            case 'drugHistory': 
                deleteDrugHistory ()
                break;
        }
    }

    const deleteEvaluation = async () => {
        try {
            await patientService.deleteEvaluationComment(id)
            window.location.reload(false)
        } catch (e) {
            console.error(e)
            setError ({error: true, message: 'Záznam se nepodařilo odstranit'})
        }
    }

    const deleteDrugHistory = async () => {
        try {
            await patientService.deleteRiskDrugHistoryComment(id)
            window.location.reload(false)
        } catch (e) {
            console.error(e)
            setError ({error: true, message: 'Záznam se nepodařilo odstranit'})
        }
    }

    return { error, deleteComment }
}   