import React from "react";
import { PopUpPharmaPlanLogic } from "./PopUpPharmaPlanLogic";

export const PopUpPharmaPlan = ({
  message,
  hide,
  saveData,
  careIsLocked = false,
  clinicID = null,
  activeCare = true,
  careType,
  backUrl
}) => {
  const { hidePopUp, goBack, saveForm } = PopUpPharmaPlanLogic(
    hide,
    saveData,
    clinicID,
    activeCare,
    careType,
    backUrl
  );
  return (
    <div className="modal flex-container-column">
      <div className="modal_content">
        <div className="text">
          <p className="strong">{message}</p>
        </div>
        <div className="btn-row flex-container-row">
          <button type="button" className="button m-1" onClick={hidePopUp}>
            Zpět na stránku
          </button>
          <button type="button" className="button m-1" onClick={goBack}>
            {careIsLocked ? `Opustit stránku` : `Opustit stránku bez uložení`}
          </button>
          {!careIsLocked && (
            <button type="button" className="button m-1" onClick={saveForm}>
              Uložit a opustit stránku
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
