import React from "react";
import { FormProvider } from 'react-hook-form'
import { TextInput, Form} from '../../components'
import { AuthetificationLogic } from "./AuthentificationLogic";


export const Login = () =>  {
    const {error, state, methods, userAuth, enableBasicAuth} = AuthetificationLogic ()

    return (
        <>
        <FormProvider {...methods}>
            <Form id='login-box'>

                { state.basicAuth ?
                <>
                <div className="form-row">
                    <TextInput label={<i className="far fa-user"></i>} name='username' id='inp-login' placeholder="Login" />
                </div>
                <div className="form-row">
                    <TextInput label={<i className="fas fa-lock"></i>} name='password' id='inp-password' placeholder="Heslo" type="password"/>
                </div>
                <div className="form-row-button">
                    <button type="submit" className="button" onClick={methods.handleSubmit(userAuth)} disabled={state.signingIn}>Přihlásit</button>

                </div> 
                </> :
                <>
                    {state.kerberosAuth && <h1>Přihlašování...</h1>}
                </>
                }
                <div className="form-row-button">
                { state.signingIn && 
                    <h2>Přihlašování...</h2>
                }
                { error.error &&  
                <>
                    <div className={'alert alert-danger'}>{error.message}</div>
                </>
                }
                {state.basicAuthBtn && <button type="button" className="button" onClick={enableBasicAuth}>Přihlásit se pomocí jména a hesla</button>}
                </div>
            </Form>
        </FormProvider>
        </>
    )
}