import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { patientService } from "../../../services/patient.services";

export const PatientConditionChangeLogic = (
  planID = null,
  conditionChange = false,
  careIsLocked = false
) => {
  const [state, setState] = useState({ submit: false, saved: false });
  const [err, setErr] = useState(false);

  //setting form methods
  const formMethods = useForm({
    defaultValues: {
      patient_condition_change: conditionChange,
    },
  });
  //decostruct values function form useForm() hook
  const { getValues, setValue, formState, control } = formMethods;

  let change = useWatch({ control, name: "patient_condition_change" });

  useEffect(() => {
    //setting initial values either from checkin.patient_condition_change or from choditionChange default value
    setData(conditionChange);
  }, []);

  //setting up initial values
  const setData = (conditionChange) => {
    setValue("patient_condition_change", conditionChange);
  };

  //save data when user change input
  useEffect(() => {
    if (!careIsLocked && change !== conditionChange) {
      saveData();
    }
  }, [change]);

  //reset states, saves data
  const saveData = async () => {
    setErr(false);
    setState({ ...state, submit: true, saved: false });
    const data = {
      patient_condition_change: getValues("patient_condition_change"),
    };
    try {
      const response = await patientService.patchPharmaPlan(data, planID);
      setValue("patient_condition_change", response.patient_condition_change);
      setState({ ...state, submit: false, saved: true });
    } catch (e) {
      console.error(e);
      setErr(true);
    }
  };

  return { state, formMethods };
};
