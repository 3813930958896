import React from "react";
import { DatePicker, ControlSelect } from "..";
import { Form } from "../Form/Form";
import { FormProvider } from "react-hook-form";

// Form with filtering options for patient history
export const PatientHistoryFilters = ({
  users,
  values,
  methods,
  defaultValues,
  ...props
}) => {
  return (
    <div className="grid-row">
      <FormProvider {...methods}>
        <Form className="myForm">
          <div className="col-12 strong mb-4">FILTROVAT PODLE | DATUM:</div>
          <div className="col-md-12 col-lg-6">
            {/* Filtering according to date */}
            <DatePicker
              label="OD:"
              name="from"
              {...methods.register("from", { required: true })}
            />
          </div>
          <div className="col-md-12 col-lg-6">
            <DatePicker
              label="DO:"
              name="to"
              {...methods.register("to", { required: true })}
            />
          </div>

          <div className="col-12">
            {/* Filtering according to user */}
            <ControlSelect label="SLOUPEC KDO:" name="users" options={users} />
          </div>

          <div className="col-12">
            {/* Filtering according to patient information */}
            <ControlSelect
              label="SLOUPCE ZMĚNA:"
              name="values"
              options={values}
            />
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
