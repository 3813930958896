import React from "react";
import { PatientInfo, PatientInformationTable } from "../../components";
import { PatientInformationLogic } from "./PatientInformationLogic";

export const PatientInformation = ({
  patient,
  careID,
  careIsLocked = false,
  ...props
}) => {
  const { columns, table, addNewRow } = PatientInformationLogic(
    patient,
    careIsLocked
  );

  return (
    <>
      <div className="patient-profile patient-information">
        <PatientInfo patientData={patient} careID={careID} />
        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              {!careIsLocked && (
                <button className="button" onClick={addNewRow}>
                  Nový záznam
                </button>
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column">
              {table.loading ? (
                <h2>Loading...</h2>
              ) : (
                <PatientInformationTable
                  columns={columns}
                  data={table.data}
                  patientId={patient.id}
                  careIsLocked={careIsLocked}
                />
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column">
              {table.noData && <h1>Žádná data o pacientovi</h1>}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column">
              {!careIsLocked && (
                <button className="button" onClick={addNewRow}>
                  Nový záznam
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
