import React, { useState, useEffect } from "react";
import { patientService } from "../../services/patient.services";
import { Tag } from "../../components";
import { userService } from "../../services/user.services";
import { useForm } from "react-hook-form";

export const TagsSettingsLogic = () => {
  const [error, setError] = useState({ error: false });
  const [tags, setTags] = useState({ loading: true });
  const [pagination, setPagination] = useState();
  const [paramsState, setParamsState] = useState({
    page: 1,
    offset: 0,
    limit: 60,
  });
  const [tab, setTab] = useState("TAG");
  const [texts, setTexts] = useState([]);
  const [showTextForm, setShowTextForm] = useState(false);

  //Load tags on component mount
  useEffect(() => [getTags()], []);

  useEffect(() => {
    getPredefinedText();
  }, []);

  const methods = useForm({
    defaultValues: {
      name: "",
      text: "",
      is_own: false,
    },
  });

  const getPredefinedText = async () => {
    const response = await userService.getAllPredefinedText();
    if (response.status === 200) {
      setTexts(response.data.results);
    }
  };

  const removePredefinedText = async (id) => {
    const response = await userService.removePredefinedText(id);
    if (response.status === 204) {
      getPredefinedText();
    }
  };

  const savePredefinedText = async (data) => {
    if (data.id) {
      const updateData = {
        name: data.name,
        text: data.text,
        is_own: data.is_own,
      };
      const response = await userService.patchPredefinedText(
        updateData,
        data.id
      );
      if (response.status === 200) {
        getPredefinedText();
        setShowTextForm(false);
        methods.reset();
      }
    } else {
      const response = await userService.postPredefinedText(data);
      if (response.status === 201) {
        getPredefinedText();
        methods.reset();
        setShowTextForm(false);
      }
    }
  };

  const handleExpand = (text) => {
    if (text) {
      methods.setValue("name", text.name);
      methods.setValue("text", text.text);
      methods.setValue("is_own", text.is_own);
      methods.setValue("id", text.id);
      setShowTextForm(true);
    } else {
      setShowTextForm(true);
      methods.reset();
    }
  };

  //get tags from db
  const getTags = async (offset, page) => {
    try {
      let data = await patientService.getTags({ ...paramsState, page, offset });
      setPagination({ count: data.count });
      setParamsState({
        ...paramsState,
        page: data.page,
        offset: data.offset,
      });
      data = await listTags(data.results);
      setTags({ ...tags, loading: false, tags: data });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání štítků" });
    }
  };

  //list tags into components
  const listTags = async (data) => {
    // let flag = false
    // let counter = 0
    // return data.map((item, key) => {
    //     if (counter === 4) {
    //         flag = !flag
    //         counter = 0
    //     }
    //     counter ++
    // ${flag ? "backgroud-blue" : ""}
    return data.map((item) => {
      return (
        <Tag
          key={item.id}
          className={`inp-text tag `}
          id={item.id.toString()}
          value={item.name}
        />
      );
    });
  };

  //handle errors and popup
  const togglePopup = () => {
    setError({ error: false });
  };
  return {
    error,
    tags,
    togglePopup,
    pagination,
    getTags,
    paramsState,
    setTab,
    tab,
    texts,
    removePredefinedText,
    savePredefinedText,
    methods,
    handleExpand,
    showTextForm,
  };
};
