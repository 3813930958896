import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cs } from "date-fns/esm/locale";
import { FaCalendarAlt } from "react-icons/fa";

export const DatePicker = ({
  name,
  label,
  error = undefined,
  labelDisplay = true,
  id,
  labelClass,
  options,
  defaultValue = "",
  isDisabled,
  isClearable = true,
  ...props
}) => {
  const methods = useFormContext();
  registerLocale("cz", cs);

  return (
    <div className="input-group">
      {labelDisplay && (
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      )}
      <div className="date-picker-container">
        <Controller
          control={methods.control}
          name={name}
          defaultValue={defaultValue}
          {...props}
          render={({ field }) => (
            <ReactDatePicker
              {...field}
              dateFormat="dd/MM/yyyy"
              calendarStartDay={1}
              className="inp-datepicker"
              placeholderText="dd/mm/rrrr"
              onChange={(e) => field.onChange(e)}
              selected={field.value}
              disabled={isDisabled}
              locale="cz"
              isClearable={isClearable}
            />
          )}
        />
        <FaCalendarAlt />
      </div>
      {error && (
        <p
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "0px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};
