import React, { createContext } from "react";
import { useExpanded, useTable } from "react-table";
import { PatientInformationBody } from "./PatientInformationBody";
import { PatientInformationHead } from "./PatientInformationHead";

export const TableContext = createContext({});

export const PatientInformationTable = ({
  columns,
  data,
  patientId,
  careIsLocked,
  ...props
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "date",
            desc: true,
          },
        ],
      },
    },
    useExpanded
  );
  const { getTableProps } = tableInstance;

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="patient-information-table" {...getTableProps()}>
        <PatientInformationHead />
        <PatientInformationBody
          patientId={patientId}
          careIsLocked={careIsLocked}
        />
      </table>
    </TableContext.Provider>
  );
};
