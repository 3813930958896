import { useState } from "react";
import { useForm } from 'react-hook-form'
import { patientService } from "../../services/patient.services";

export const TagLogic = (id, value) => {
    const [ state, setState ] = useState ({active: false, submit: false, save: false, edit: false})
    const [ error, setError ] = useState ({error: false})

    //FORM SECTION 
    const methods = useForm ({
        defaultValues: {
            name: value
        }
    })
    const saveTag = (data) => {
        try {
            setState({...state, submit: true})
            patientService.patchTags(data, parseInt(id))
            setState({...state, active: false, submit: false, save: true})
        } catch (error) {
            console.error(error)
            setError({error: true, message: 'Nastala chyba při ukládání štítku'})
        }
    }

    const toggleTag = () => {
        setState({...state, active: true, save: false})
    }

    const togglePopup = () => {
        setError({error: false})
    }

    return {state, error, methods, toggleTag, togglePopup, saveTag}
}