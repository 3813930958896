import React from "react";
import { CheckinLogic } from "./CheckinLogic";
import {
  Form,
  PatientDrugsInp,
  PopUpCheckin,
  Diagnoses,
} from "../../components";
import { FormProvider } from "react-hook-form";
import { PopUpPatienEntryControl } from "../PopUps/PopUpPatientEntryControl";

export const Checkin = ({
  patient,
  careIsLocked = false,
  careID,
  checkin = null,
  toggle = false,
  displayAll = true,
  disabled = false,
  displayAddBtns = true,
  ...props
}) => {
  const {
    state,
    error,
    methods,
    editCheckin,
    saveData,
    returnBtn,
    clearErrors,
    risk_check,
    oldesCare,
    open,
    onPopUpClose,
    onPopUpSubmit,
  } = CheckinLogic(checkin, patient, careID, careIsLocked, toggle);

  const isRiskLevelDisabled = methods.getValues("consultation_requested");

  return (
    <>
      {open && (
        <PopUpPatienEntryControl
          onClose={onPopUpClose}
          oldesCare={oldesCare}
          onSubmit={onPopUpSubmit}
        />
      )}
      {error.error && (
        <PopUpCheckin message={error.message} toggle={clearErrors} />
      )}
      <FormProvider {...methods}>
        <Form>
          {state.loading ? (
            <h2>Loading...</h2>
          ) : (
            <>
              {(displayAll || checkin.polypharmacy) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="polypharmacy"
                      drug={true}
                      drugName="drugs"
                      notes={true}
                      noteName="polypharmacy_note"
                      title="Polypragmazie"
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.narrow_therapeutic_window) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="narrow_therapeutic_window"
                      drugName="narrow_therapeutic_window_drugs"
                      noteName="narrow_therapeutic_window_note"
                      title="Léčivo s úzkým terapeutickým oknem"
                      drug={true}
                      notes={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.high_interaction_potential) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="high_interaction_potential"
                      drug={true}
                      drugName="high_interaction_potential_drugs"
                      notes={true}
                      noteName="high_interaction_potential_note"
                      title="Vysoký interakční potenciál"
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.systemic_corticosteroids) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="systemic_corticosteroids"
                      noteName="systemic_corticosteroids_note"
                      title="Systémové kortikoidy nebo jiná imunosupresiva"
                      notes={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.toggle_diagnoses) && (
                <div className="form-row">
                  <div className="form-column">
                    <Diagnoses
                      sectionName="toggle_diagnoses"
                      diagnoseName="diagnoses"
                      drugName="drugs"
                      noteName="diagnoses_note"
                      title="Riziková diagnóza"
                      notes={true}
                      diagnose={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.renal_insufficiency) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="renal_insufficiency"
                      noteName="renal_insufficiency_note"
                      title="Renální insuficience"
                      notes={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.hepatic_insufficiency) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="hepatic_insufficiency"
                      noteName="hepatic_insufficiency_note"
                      title="Hepatální insuficience"
                      notes={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.significant_biochemical_changes) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="significant_biochemical_changes"
                      noteName="significant_biochemical_changes_note"
                      title="Další významné změny biochemických / hematologických parametrů"
                      notes={true}
                      displayAddBtns={displayAddBtns}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.intensive_care) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="intensive_care"
                      noteName="intensive_care_note"
                      title="Intenzivní péče"
                      notes={true}
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.pharmacist_intervention_required) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="pharmacist_intervention_required"
                      title="Nutný zásah KF"
                      disabled={disabled}
                      onClick={risk_check}
                    />
                  </div>
                </div>
              )}
              {(displayAll || checkin.consultation_requested) && (
                <div className="form-row">
                  <div className="form-column">
                    <PatientDrugsInp
                      sectionName="consultation_requested"
                      title="Vyžádané konzilium"
                      disabled={disabled}
                    />
                  </div>
                </div>
              )}

              {!isRiskLevelDisabled && (
                <div className="form-row-radio">
                  <span className="strong formSubTitle">
                    Výsledný stupeň rizikovosti
                  </span>
                  <input
                    type="radio"
                    value="1"
                    id="1"
                    className="btn-radio"
                    {...methods.register("risk_level")}
                    disabled={isRiskLevelDisabled}
                  />
                  <label className="strong" htmlFor="1">
                    1.stupeň
                  </label>
                  <input
                    type="radio"
                    value="2"
                    id="2"
                    className="btn-radio"
                    {...methods.register("risk_level")}
                    disabled={isRiskLevelDisabled}
                  />
                  <label className="strong" htmlFor="2">
                    2.stupeň
                  </label>
                  <input
                    type="radio"
                    value="3"
                    id="3"
                    className="btn-radio"
                    {...methods.register("risk_level")}
                    disabled={isRiskLevelDisabled}
                  />
                  <label className="strong" htmlFor="3">
                    3.stupeň
                  </label>
                </div>
              )}
              <div className="form-row-button">
                {displayAll && !state.locked && (
                  <>
                    <button
                      type="submit"
                      disabled={state.submit}
                      className="button"
                      onClick={methods.handleSubmit(saveData)}
                    >
                      Uložit
                    </button>
                  </>
                )}
                {displayAll && (
                  <button
                    type="button"
                    disabled={state.submit}
                    className="button btn-light"
                    onClick={returnBtn}
                  >
                    Zpět
                  </button>
                )}
                {toggle && !state.locked && (
                  <>
                    <button
                      type="button"
                      className="button"
                      onClick={editCheckin}
                    >
                      Editovat
                    </button>
                  </>
                )}
                {toggle && (
                  <button
                    type="button"
                    className="button btn-light"
                    onClick={toggle}
                  >
                    Sbalit zpět
                  </button>
                )}
              </div>
              <div className="form-row">
                <div className="form-column">
                  {state.submit && (
                    <span className="strong">Data se ukládají.</span>
                  )}
                  {state.dataSaved && (
                    <span className="strong">Data byla uložena.</span>
                  )}
                </div>
              </div>
            </>
          )}
        </Form>
      </FormProvider>
    </>
  );
};
