import React, { useContext } from 'react' 
import {TableContext} from './PatientInformationTable'

export const PatientInformationHead = () => {
    const {
        headerGroups, 
    } = useContext(TableContext)

    return (
        <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))  }
        </thead> 
    )
}