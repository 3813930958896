import React, { Fragment } from "react";
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'
import { NestedAsyncSelectArrCompLogic } from "./NestedAsyncSelectArrCompLogic";
import AsyncSelect  from 'react-select/async'
import { FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa";

export const NestedAsyncSelectArrComp =  ({ nestedIndex, name, arrName, displayBtns = true, endPoint, labelClass, label, active, ...props }) => { 
    const {setValue, control}  = useFormContext()
    const {fields, remove, append} = useFieldArray({name: `${arrName}[${nestedIndex}].${name}`, control})
    const { promiseOptions } = NestedAsyncSelectArrCompLogic(name, endPoint)

    const styles = {
        control: (props) => ({
            ...props,
            borderRadius: 0,
            minHeight: 42,
    })}

    const onChange = (e,name) => {
        setValue(name.name, e)
    }

    return (
        <>
        {fields.map ((item,k) => (
            <Fragment key={item.id}>
            <div className='input-group'>
                <label className={labelClass}>{label}</label>
                <Controller name={`${arrName}[${nestedIndex}].${name}[${k}]`} control={control} render = { ( {field} ) => (
                    <AsyncSelect isClearable {...field} styles={styles} onChange={(e, name) => onChange(e,name)} isDisabled={active} placeholder='Vyhledejte...' cacheOptions loadOptions={promiseOptions} {...props} /> )}
                />
            {displayBtns && 
                <div className='input-icons'>
                    <a onClick={() => append ({label: "Vyhledejte...", value: ""})}>
                        <FaRegPlusSquare id="plus" />
                    </a>
                    <a onClick={() => { fields.length <= 1 ? alert("Nemůžete odstranit všechna léčiva") : remove(k) } }>
                        <FaRegMinusSquare id="minus" />
                    </a>
                </div> 
            }
            </div>
            </Fragment>
        ))}
        </>
    )
};