import React, { useContext, Fragment } from "react";
import { TableContext } from "./Table";
import {
  PatientHospitalPastTable,
  PatientInfoTable,
  PatientSearchTable,
} from "../../components";

export const TableBody = ({ id, nestedTable, status = "", ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
            {row.isExpanded ? (
              <tr>
                <td colSpan={visibleColumns.length}>
                  {status === "past" ? (
                    <PatientHospitalPastTable row={row} />
                  ) : status === "all" ? (
                    <PatientSearchTable row={row} />
                  ) : (
                    <PatientInfoTable
                      row={row}
                      careType={row.original.current_care.care_type}
                    />
                  )}
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
