import React from "react";
import  { helpFunc } from '../../helpers/helpFunction'
function Footer () {
    return (
        <div className ='layout-footer'> 
            <span>iPharm verze {process.env.REACT_APP_VERSION} ({helpFunc.currentDate()})</span>
        </div>
    )
}

export default Footer;