import { useHistory } from "react-router-dom";

export const PopUpPharmaPlanLogic = (
  hide,
  saveData,
  clinicID,
  activeCare,
  careType,
  backUrl
) => {
  const history = useHistory();

  const hidePopUp = () => {
    hide();
  };

  const goBack = () => {
    let location;

    if (backUrl) {
      history.push(backUrl);
      return;
    }

    if (careType !== "external") {
      if (activeCare) {
        location = {
          pathname: `/patients-hospital/${clinicID}/present/${careType}`,
        };
      } else {
        location = {
          pathname: `/patients-hospital/${clinicID}/past/${careType}`,
        };
      }
    } else {
      location = {
        pathname: `/patients-external/present`,
      };
    }

    history.push(location);
  };

  const saveForm = () => {
    saveData();
  };

  return { hidePopUp, goBack, saveForm };
};
