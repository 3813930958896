import React, { useState, useEffect, useMemo } from "react";
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";
import { GrEdit } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegCaretSquareDown } from "react-icons/fa";

export const PatientInformationLogic = (patient, careIsLocked) => {
  const [state, setState] = useState({ deleting: false });
  const [error, setError] = useState("");
  const [table, setTable] = useState({
    loading: true,
    loaded: false,
    noData: false,
  });

  //load data on compoennet mount
  useEffect(() => {
    getTableData();
  }, []);

  //Get set data to table and parse data for table
  const getTableData = async () => {
    try {
      setTable({ loading: true });
      const response = await patientService.getPatientInformation(patient.id);
      const pData = await parseTableData(response);
      if (pData.length == 0) {
        setTable({ loading: false, data: pData, noData: true });
      } else {
        setTable({ loading: false, data: pData, noData: false });
      }
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání dat pacienta." });
    }
  };

  //Parse data for table
  const parseTableData = async (data) => {
    const pData = data.map((item) => {
      const date = helpFunc.formatDateWithTime(item.updated_at);
      return { ...item, updated_at: date };
    });
    return pData;
  };

  //Table section
  const columns = useMemo(() => [
    { Header: "Datum", accessor: "updated_at" },
    { Header: "Popis", accessor: "text" },
    {
      Header: () => null,
      id: "icons",
      Cell: ({ row }) => (
        <>
          {!careIsLocked ? (
            <>
              <span {...row.getToggleRowExpandedProps()}>
                <GrEdit />
              </span>
              <span onClick={() => deletePatientInformation(row)}>
                <RiDeleteBin6Line />
              </span>
            </>
          ) : (
            <span {...row.getToggleRowExpandedProps()}>
              <FaRegCaretSquareDown />
            </span>
          )}
        </>
      ),
    },
  ]);

  //add new row with new patient information
  const addNewRow = () => {
    const date = helpFunc.currentDate();
    const data = table.data.concat({
      created_at: date,
      name: "",
      text: "",
      file: "",
    });
    setTable({ ...table, data: data });
  };

  //Delete patient info
  const deletePatientInformation = async (row) => {
    const id = row.original.id;
    setState({ ...state, deleting: true });
    try {
      await patientService.deletePatientInformation(id);
      setState({ ...state, deleting: false });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při mazání záznamu." });
    }
    window.location.reload(false);
  };

  return { state, columns, table, error, addNewRow };
};
