import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { careServices } from "../../services/care.services";
import { patientService } from "../../services/patient.services";

export const PatientRequestsListLogic = (
  requisitions,
  category,
  careId,
  getPatientData
) => {
  const [request, setRequest] = useState(requisitions[0]);

  const [message, setMessage] = useState(undefined);
  const [options, setOptions] = useState({});
  const [showSideEffectForm, setShowSideEffectForm] = useState(false);
  const [tab, setTab] = useState("reaction");

  useEffect(() => {
    getRequestSubType();
    getPatientCategories();
  }, []);

  const methods = useForm({
    defaultValues: {
      answer: request?.answer,
      category: {
        name: category?.id ?? "",
        label: category?.name ?? "Nepřiřazeno",
      },
      subtype: {
        name: request?.subtype?.id ?? "",
        label: request?.subtype?.name ?? "Nepřiřazeno",
        subtype: request?.subtype?.subtype,
      },
    },
  });

  useEffect(() => {
    if (request.subtype?.subtype === "ipahrm_adverse_effect") {
      setShowSideEffectForm(true);
      setTab("side-effect");
    } else {
      setShowSideEffectForm(false);
      setTab("reaction");
    }
  }, [request]);

  const handleSetRequest = (req) => {
    setRequest(req);
    setMessage(undefined);
    methods.setValue("answer", req.answer);
    methods.setValue("subtype", {
      name: req.subtype.id,
      label: req.subtype.name,
    });
    methods.setValue("category", {
      name: category?.id,
      label: category?.name,
    });
  };

  const getRequestSubType = async () => {
    const response = await patientService.getRequisitionsType({
      type: "ipharm",
    });
    const subtype = response.map((item) => ({
      name: item.id,
      label: item.name,
      subtype: item.subtype,
    }));
    setOptions((prevState) => ({ ...prevState, subtype }));
  };

  const getPatientCategories = async () => {
    const response = await patientService.getPatientCategories();
    const category = response.map((item) => ({
      name: item.id,
      label: item.name,
    }));
    setOptions((prevState) => ({ ...prevState, category }));
  };

  const handleSubmit = async (data) => {
    const answer = helpFunc.removeHTMLTagsWithoutNewLine(data.answer);

    let state;

    if (
      data.category.name &&
      data.subtype.name &&
      request.state !== "locked" &&
      request.state !== "solved"
    ) {
      state = "locked";
    }

    if (answer) {
      state = "solved";
    }

    const patchData = {
      subtype: data.subtype.name,
      answer: data.answer,
      state: state,
    };

    let response;
    try {
      response = await patientService.patchRequisitions(patchData, request.id);

      if (response.status === 200) {
        response = await careServices.patchCareById(
          { category: data.category.name },
          careId
        );
      }
      handleResponse(response);
    } catch (error) {
      console.error(error);
      setMessage({ type: "danger", text: "Žádanku se nepodařilo uložit." });
    }
  };

  const onChangeState = async (state) => {
    let alert = false;
    try {
      alert = confirm(
        `Určitě si přejete ${
          state === "refused" ? "odmítnout" : "přijmout"
        } žádanku ?`
      );

      if (alert) {
        const response = await patientService.patchRequisitions(
          { state },
          request.id
        );

        handleResponse(response);
      }
    } catch (error) {
      console.error(error);
      setMessage({ type: "danger", text: "Žádanku se nepodařilo uložit." });
    }
  };

  const handleResponse = (response) => {
    if (response.status !== 200) {
      setMessage({ type: "danger", text: "Žádanku se nepodařilo uložit." });
      return;
    }

    setMessage({ type: "success", text: "Žádanka byla úspěšne uložena." });
    setTimeout(() => {
      getPatientData();
    }, 2000);
  };

  return {
    request,
    methods,
    options,
    message,
    showSideEffectForm,
    tab,
    setTab,
    handleSubmit,
    handleSetRequest,
    onChangeState,
  };
};
