import React from "react";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";

export const Pagination = (props) => {
  const {
    onSubmit,
    pagination,
    paramsState,
    limit = PAGINATION_PER_PAGE_LIMIT,
  } = props;
  const countPages = Math.ceil(pagination.count / limit);

  const arrayPages = [];
  let pageCount = 3;

  const getPages = () => {
    if (countPages === 0) {
      return null;
    }

    // if (paramsState.page !== 1) {
    //   arrayPages.push(
    //     <button
    //       key="first"
    //       className="button pagination-button mr-2"
    //       onClick={() => onSubmit(0, 1)}
    //     >
    //       První
    //     </button>
    //   );
    // }

    if (paramsState.page !== 1) {
      arrayPages.push(
        <button
          key="previous"
          className="button pagination-button mr-2"
          onClick={() =>
            onSubmit(paramsState.offset - limit, paramsState.page - 1)
          }
        >
          Zpět
        </button>
      );
    }

    // for (let i = 1; i <= countPages; i++) {
    //   let offset = (i - 1) * limit;
    //   if (Math.abs(i - paramsState.page) < pageCount) {
    //     arrayPages.push(
    //       <button
    //         className="button pagination-button mr-2"
    //         disabled={i === paramsState.page}
    //         key={i}
    //         onClick={() => onSubmit(offset, i)}
    //       >
    //         {i}
    //       </button>
    //     );
    //   }
    // }

    for (let i = 1; i <= countPages; i++) {
      let offset = (i - 1) * limit;
      arrayPages.push(
        <button
          className="button pagination-button mr-2"
          disabled={i === paramsState.page}
          key={i}
          onClick={() => onSubmit(offset, i)}
        >
          {i}
        </button>
      );
    }

    if (countPages !== paramsState.page) {
      arrayPages.push(
        <button
          key="next"
          className="button pagination-button mr-2"
          onClick={() =>
            onSubmit(paramsState.offset + limit, paramsState.page + 1)
          }
        >
          Další
        </button>
      );
    }

    // if (paramsState.page !== countPages) {
    //   arrayPages.push(
    //     <button
    //       key="last"
    //       className="button pagination-button mr-2"
    //       onClick={() => onSubmit(countPages * limit - limit, countPages)}
    //     >
    //       Poslední
    //     </button>
    //   );
    // }
    return arrayPages;
  };

  return <div className="pagination">{getPages()}</div>;
};
