import React from "react";
import { SwitchComp, TextInput } from "../..";
import { FieldArray } from "../../Form/FieldArray/FieldArray";
import { DiagnosesLogic } from "./DiagnosesLogic";

export const Diagnoses = ({
  sectionName,
  drug,
  drugName,
  diagnoseName,
  noteName,
  notes,
  title,
  disabled,
  fieldArrayLabel,
  clearForm = () => {},
  toggle = true,
  deactivateFunc = true,
  ...props
}) => {
  const { state, toggleSection } = DiagnosesLogic(
    sectionName,
    noteName,
    diagnoseName,
    clearForm,
    deactivateFunc
  );
  return (
    <>
      {toggle && (
        <SwitchComp
          name={sectionName}
          label={title}
          classLabel="strong"
          inputGroup="input-group"
          toggleSection={toggleSection}
          disabled={disabled}
        />
      )}
      <FieldArray
        label="Diagnóza"
        name={diagnoseName}
        endPoint="diagnoses"
        labelClass={fieldArrayLabel}
        nestedEndPoint="drugs"
        nestedName={drugName}
        active={!state.active || disabled}
        displayBtns={disabled ? !disabled : state.active}
      />
      {notes && (
        <TextInput
          name={noteName}
          id={noteName}
          label="Poznámka"
          disabled={!state.active || disabled}
        />
      )}
    </>
  );
};
