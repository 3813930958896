import React from "react";
import { ControlSelect, Radio } from "..";

export const UserSettingsForm = ({
  title,
  label,
  name,
  options,
  checkbox,
  handleChangeCheckbox,
  ...props
}) => {
  return (
    <>
      <h1>{title}</h1>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <ControlSelect
              options={options}
              label={label + " 1"}
              name={`${name}_0`}
            />
            <ControlSelect
              options={options}
              label={label + " 2"}
              name={`${name}_1`}
            />
            <ControlSelect
              options={options}
              label={label + " 3"}
              name={`${name}_2`}
            />
          </div>
          <div className="col-12 col-lg-6">
            <ControlSelect
              options={options}
              label={label + " 4"}
              name={`${name}_3`}
            />
            <ControlSelect
              options={options}
              label={label + " 5"}
              name={`${name}_4`}
            />
            <ControlSelect
              options={options}
              label={label + " 6"}
              name={`${name}_5`}
            />
          </div>
        </div>
        {name === "hospital" && (
          <div className="row">
            <div className="col-12">
              <div className="input-group">
                <input
                  className="styled-checkbox"
                  type="checkbox"
                  id="cela-nemocnice"
                  name="cela-nemocnice"
                  checked={checkbox}
                  onChange={() => handleChangeCheckbox()}
                />
                <label className="ml-3" htmlFor={"cela-nemocnice"}>
                  Celá nemocnice
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
