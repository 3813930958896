import React from 'react' 
import { useFormContext } from 'react-hook-form'

export const FileInput = ({name, defaultValue="", disabled, ...props}) => {
    const methods = useFormContext () 

    return (
        <div className='file-input'>
            <input type='file' {...methods.register(name)} accept="application/pdf,application/vnd.ms-excel, application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document" multiple='false' disabled={disabled} />
        </div> 
    )

}