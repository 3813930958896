import React, { useContext, Fragment } from "react";
import { useState } from "react";
import { FaHospitalSymbol } from "react-icons/fa";
import { IoMdAlert } from "react-icons/io";
import { PageNavigation, PatientInfoTable } from "..";
import { PatientRequestsList } from "../PatientRequests/PatientRequestsList";
import { TableContext } from "./PatientHospitalPresentTable";
import {
  DateFieldFilter,
  DiagnosisNameFilter,
  NumberFieldFilter,
  SelectFieldFilter,
  TextFieldFilter,
} from "./TableFilter";

export const TableBody = ({ id, ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);
  const getRowProps = (row) => {
    if (row.original.status === "noCheckin") {
      return { style: { background: "#454552", color: "#ffffff" } };
    } else if (row.original.require_attention) {
      return { style: { background: "#0164A7", color: "#ffffff" } };
    } else if (row.original.status === "none") {
      return { style: { background: "#0164A7", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelOne") {
      return { style: { background: "#279112", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelTwo") {
      return { style: { background: "#ECA100", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelThree") {
      return { style: { background: "#A63A49", color: "#ffffff" } };
    }
  };

  const [navigation, setNavigation] = useState("info");

  // hight attention 0164a7
  // plasmatic concentration 7030a0
  // nezadouci ucinnek f00001

  return (
    <tbody {...getTableBodyProps()}>
      {props.careType === "hospitalization" && (
        <tr role="row">
          <td></td>
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="external_id"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="name"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="birth_number"
          />
          <NumberFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="age_display"
          />
          <DiagnosisNameFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_department_description"
          />
          <NumberFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_days"
          />
          <DateFieldFilter
            onChange={props.handleDateChange}
            filter={props.filter}
            field="current_care_last_dekurz_date"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_doctor_name"
          />
          <td>
            <button className="button" onClick={props.handleResetFilter}>
              Resetovat filtr
            </button>
          </td>
        </tr>
      )}
      {props.careType === "ambulation" && (
        <tr role="row">
          <td></td>
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="external_id"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="name"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="birth_number"
          />
          <NumberFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="age_display"
          />
          <DiagnosisNameFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_department_description"
          />
          <DateFieldFilter
            onChange={props.handleDateChange}
            filter={props.filter}
            field="current_care_started_at"
          />
          <TextFieldFilter
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_doctor_name"
          />
          <SelectFieldFilter
            onChange={props.handleFilterChange}
            filter={props.filter}
            options={props.options.category}
            field="current_care_category"
          />
          <td>
            <button className="button" onClick={props.handleResetFilter}>
              Resetovat filtr
            </button>
          </td>
        </tr>
      )}
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr {...row.getRowProps(getRowProps(row))}>
              <td>
                {row.original.requisitions?.length !== 0 && (
                  <IoMdAlert
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Žádanka"
                    style={
                      row.original.requisitions?.some(
                        (item) => item.state === "pending"
                      )
                        ? { color: "#A63A49", fontSize: 20 }
                        : { color: "#b0b0b6", fontSize: 20 }
                    }
                  />
                )}
                {row.original.planned_hospitalization && (
                  <FaHospitalSymbol
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Plánovaná hospitalizace"
                    style={{ color: "#b0b0b6", fontSize: 17, marginLeft: 4 }}
                  />
                )}
              </td>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
            {row.isExpanded ? (
              <tr role="row">
                <td
                  colSpan={visibleColumns.length}
                  style={{ flexDirection: "column" }}
                >
                  <div style={{ width: "80%" }}>
                    <PageNavigation className="sub-page-navigation present">
                      <div>
                        <a
                          className={navigation === "info" && "active"}
                          onClick={() => setNavigation("info")}
                        >
                          Informace o pacientovi
                        </a>
                      </div>

                      <div>
                        <a
                          className={navigation === "requests" && "active"}
                          onClick={() => setNavigation("requests")}
                        >
                          Žádanky pacienta ({row.original.requisitions?.length}){" "}
                          {row.original.requisitions?.some(
                            (item) => item.state === "pending"
                          ) && (
                            <IoMdAlert
                              style={{ color: "#A63A49", fontSize: 20 }}
                            />
                          )}
                        </a>
                      </div>
                    </PageNavigation>
                    {navigation === "info" && (
                      <PatientInfoTable
                        id={row.original.id}
                        row={row}
                        careType={row.original.current_care.care_type}
                      />
                    )}
                    {navigation === "requests" && (
                      <PatientRequestsList
                        requisitions={row.original.requisitions}
                        disableCommentSection={true}
                        careId={row.original.current_care.id}
                        careType={props.careType}
                        category={row.original.current_care.category}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
