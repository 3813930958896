import { authHeader } from "../auth/authHeader";
import axios from "axios";
import { userService } from "./user.services";

const getCare = async (id) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/cares/${id}/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return handleError(error);
  }
};

const patchCareById = async (data, careId) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + `/cares/${careId}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const finishCare = async (careId) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + `/cares/${careId}/finish/`,
      {},
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

const createCare = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + `/cares/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

function handleError(error) {
  if (error.response && error.response.status === 401) {
    userService.logout();
    window.location.replace("/signin");
  }

  return Promise.reject(error);
}

export const careServices = {
  getCare,
  patchCareById,
  finishCare,
  createCare,
};
