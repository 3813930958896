import React from 'react' 
import Switch  from 'react-switch'

export const Toggle = ({ label, title, disabled = false, checked, classLabel, inputGroup, ...props}) => {

    const switchStyle = {
        offColor: '#cdd2d6',
        onColor: '#06c102',
        uncheckedIcon: false, 
        checkedIcon: false, 
        activeBoxShadow: '0 0 2px 3px #06c102',
        height: 24,
        width: 36, 
        handleDiameter: 18
        }

    return (
        <>
        <div className={`${inputGroup}`} > 
                <Switch  disabled={disabled} checked={checked} onChange={() => {}} {...switchStyle} {...props} readOnly={checked} />  
            <input type="checkbox" hidden checked={checked} readOnly={checked} />
            <span className={`${classLabel} formSubTitle`}>{label}</span>
        </div>
        </>

    )
}