import { authHeader } from "../auth/authHeader";
import axios from "axios";

const login = async (data) => {
  const options = {
    method: "POST",
    mode: "cors",
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/login_basic/",
      data,
      { ...options }
    );
    //set token to local storage
    localStorage.setItem("authToken", JSON.stringify(response.data));
    //get user and save it to local storage
    await setUpUser();
  } catch (error) {
    //handle error codes
    return handleError(error);
  }
};

const loginKerberos = async () => {
  let response = await fetch(
    process.env.REACT_APP_ROOT_API + "/login_kerberos/",
    {
      method: "POST",
      credentials: "include",
    }
  );

  if (response.status === 401) {
    let error = {};
    error.message = "Špatné uživatelské jméno nebo heslo";
    return error;
  } else if (response.status === 403) {
    let error = {};
    error.message = "Uživatel nemá oprávnění přihlásit se do aplikace";
    return error;
  } else if (response.ok) {
    //save token to local storage
    let data = await response.json();
    //set token to local storage
    localStorage.setItem("authToken", JSON.stringify(data));
    //get user and save to local storage
    await setUpUser();
    return response;
  }
};

const setUpUser = async () => {
  try {
    const user = await getUser();
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error) {
    return Promise.reject(error);
  }
};

const logout = () => {
  //remove user from local storage
  localStorage.removeItem("user");
  //remove token from local storage
  localStorage.removeItem("authToken");
};

const getUser = async () => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/user/`,
      { ...options }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const patchUser = async (data) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      process.env.REACT_APP_ROOT_API + "/user/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const handleError = (error) => {
  //handle 401 error not auth
  if (error.response.status === 401) {
    error.message = "Špatné uživatelské jméno nebo heslo";
  }
  //hande 403 error not in a group
  else if (error.response.status === 403) {
    error.message = "Uživatel nemá oprávnění přihlásit se do aplikace";
  }
  //handle 400 error for bad request
  else if (error.response.status === 400) {
    error.message = "Vyplňte uživatelské jméno a heslo";
  }
  //return promise error, with error message
  return Promise.reject(error);
};

const postPredefinedText = async (data) => {
  const options = authHeader("POST");
  try {
    const response = await axios.post(
      process.env.REACT_APP_ROOT_API + "/predefined-texts/",
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const patchPredefinedText = async (data, id) => {
  const options = authHeader("PATCH");
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_ROOT_API}/predefined-texts/${id}/`,
      data,
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAllPredefinedText = async () => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/predefined-texts/",
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const removePredefinedText = async (id) => {
  const options = authHeader("DELETE");
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_ROOT_API}/predefined-texts/${id}`,
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLicense = async () => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + "/features/",
      { ...options }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const userService = {
  login,
  loginKerberos,
  logout,
  getUser,
  patchUser,
  postPredefinedText,
  getAllPredefinedText,
  removePredefinedText,
  patchPredefinedText,
  getLicense,
};
