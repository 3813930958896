import React, { useContext, Fragment } from "react";
import { PatientInfoTable } from "../PatientInfoTable/PatientInfoTable";
import { NumberField, TextField, DateField } from "../TableFilter";
import { TableContext } from "./AllPatientsListTable";

export const AllPatientsListTableBody = ({ id, careType, ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);

  return (
    <tbody {...getTableBodyProps()}>
      <tr role="row">
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="external_id"
        />
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="name"
        />
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="birth_number"
        />
        <NumberField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="age_display"
        />
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="current_care_diagnosis_name"
        />
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="current_care_last_dekurz_clinic_description"
        />
        <TextField
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="current_care_department_description"
        />
        {careType === "hospitalization" && (
          <NumberField
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_days"
          />
        )}
        {careType === "ambulation" && (
          <DateField
            onChange={props.handleDateChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_started_at"
          />
        )}
        {careType === "hospitalization" && (
          <DateField
            onChange={props.handleDateChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_last_dekurz_date"
          />
        )}

        {careType === "hospitalization" && (
          <TextField
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_doctor_name"
          />
        )}

        {careType === "ambulation" && (
          <TextField
            onChange={props.handleFilterChange}
            onSubmit={props.onSubmit}
            filter={props.filter}
            field="current_care_category"
          />
        )}

        <td>
          <button
            style={{ width: 116 }}
            className="button"
            onClick={() => props.onSubmit()}
          >
            Filtrovat
          </button>
          <button
            style={{ width: 122 }}
            className="button"
            onClick={props.handleResetFilter}
          >
            Resetovat filtr
          </button>
        </td>
      </tr>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr {...row.getRowProps(row)}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
            {row.isExpanded ? (
              <tr role="row">
                <td
                  colSpan={visibleColumns.length}
                  style={{ flexDirection: "column" }}
                >
                  <div style={{ width: "80%" }}>
                    <PatientInfoTable
                      id={row.original.id}
                      row={row}
                      careType={row.original.current_care.care_type}
                    />
                  </div>
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
