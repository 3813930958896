import { useEffect, useState } from "react";
import { patientService } from "../../services/patient.services";
import { helpFunc } from "../../helpers/helpFunction";
import { careServices } from "../../services/care.services";

export const PatientInfoLogic = (
  id,
  patientData = {},
  careID = null,
  careData
) => {
  const [state, setState] = useState({ loading: true, moreInfo: false });
  const [error, setError] = useState("");
  const [patient, setPatient] = useState("");
  const [care, setCare] = useState("");

  //Parse patient data
  const pPatientData = async (data) => {
    const birth_date = helpFunc.formatDate(data?.birth_date);
    const bmi = helpFunc.countBmi(data?.weight, data?.height);
    const days = helpFunc.countDaysInHospital(data?.current_care?.started_at);
    const last_dekurz = data?.current_care?.last_dekurz
      ? helpFunc.formatDate(data?.current_care?.last_dekurz?.made_at)
      : "Chybí data";

    return {
      ...data,
      bmi: bmi,
      days: days,
      last_dekurz: last_dekurz,
      birth_date: birth_date,
    };
  };

  //Get patient info
  const getPatient = async (patientData, id, careData, careID) => {
    setState({ ...state, loading: true, loaded: false });

    if (!Object.keys(patientData).length) {
      //need to get data call API
      try {
        let data = await patientService.getPatient(id);
        data = await pPatientData(data);
        setPatient(data);
      } catch (error) {
        console.error(error);
        setError({
          error: true,
          message: "Chyba při načítání pacientových dat.",
        });
      }
    } else {
      let data = await pPatientData(patientData);
      setPatient(data);
    }
    if (!Object.keys(careData).length && careID) {
      try {
        let care = await careServices.getCare(careID);
        setCare(care);
      } catch (e) {
        console.error(e);
        setError({
          error: error,
          message: "Chyba při načítání pacientových dat.",
        });
      }
    } else {
      setCare(careData);
    }

    setState({ ...state, loading: false });
  };

  useEffect(() => {
    getPatient(patientData, id, careData, careID);
  }, []);

  const showMoreInfo = () => {
    setState({ ...state, moreInfo: !state.moreInfo });
  };

  return { patient, error, state, care, showMoreInfo };
};
