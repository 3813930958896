import React, { useContext, Fragment } from "react";
import { PatientInformationFiles } from "./PatientInformationFiles";
import { TableContext } from "./PatientInformationTable";

export const PatientInformationBody = ({
  patientId,
  careIsLocked,
  ...props
}) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
            {row.isExpanded || row.original.name === "" ? (
              <tr>
                <td colSpan={visibleColumns.length} key={row.id}>
                  {/* refactor pass full row and then get to single var in comp */}
                  <PatientInformationFiles
                    patientId={patientId}
                    careIsLocked={careIsLocked}
                    data={row.original}
                  />
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
