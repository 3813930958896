import React from "react";
import { PopUp, Pagination, PageNavigation } from "../../components";
import { TagsSettingsLogic } from "./TagsSettingsLogic";
import { PredefinedTextForm } from "../../components/PredefinedText/PredefinedTextForm";
import { PredefinedTextItem } from "../../components/PredefinedText/PredefinedTextItem";

export const TagsSettings = () => {
  const {
    paramsState,
    error,
    tags,
    tab,
    pagination,
    togglePopup,
    getTags,
    setTab,
    texts,
    removePredefinedText,
    savePredefinedText,
    methods,
    handleExpand,
    showTextForm,
  } = TagsSettingsLogic();
  return (
    <div>
      <h1>{tab === "TAG" ? "Nastavení štítků" : "Předdefinované texty"}</h1>
      <PageNavigation className="sub-page-navigation present">
        <div>
          <a
            className={tab === "TAG" && "active"}
            id="pharmaPlanVerification"
            onClick={() => setTab("TAG")}
            style={{ paddingLeft: 12, paddingRight: 12 }}
          >
            Štítky
          </a>
          <a
            className={tab === "TEXT" && "active"}
            id="pharmaPlanVerification"
            onClick={() => setTab("TEXT")}
            style={{ paddingLeft: 12, paddingRight: 12 }}
          >
            Předdefinované texty
          </a>
        </div>
      </PageNavigation>

      {tab === "TAG" && (
        <div className="max-w-1524">
          <div className="myContent">
            <div className="grid-row wrap tag">
              {error.error && (
                <PopUp toggle={togglePopup} message={error.message} />
              )}
              {tags.loading ? <h2>Načítání štítků...</h2> : tags.tags}
            </div>
            <div className="grid-row">
              <div className="col-12">
                <Pagination
                  onSubmit={getTags}
                  pagination={pagination ? pagination : {}}
                  paramsState={paramsState}
                  limit={60}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {tab === "TEXT" && (
        <div className="max-w-1524">
          <div className="myContent">
            <button
              className="button mb-5"
              onClick={() => handleExpand(undefined)}
            >
              Nový text
            </button>
            {showTextForm && (
              <PredefinedTextForm
                savePredefinedText={savePredefinedText}
                methods={methods}
              />
            )}
            {texts.map((item) => (
              <PredefinedTextItem
                text={item}
                handleRemove={removePredefinedText}
                handleExpand={handleExpand}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
