import React from "react";
import { Input, DatePickerPlain, AsyncSelectComp } from "../../../components";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

export const MainDrugTableInput = (props) => {
  const { fields, remove, error } = props;
  return (
    <table class="table table-bordered adverse-effect-table">
      <thead>
        <tr>
          <th scope="col">
            <label htmlFor="">Název léčiva</label>
          </th>
          <th scope="col">
            <label htmlFor="">Síla</label>
          </th>
          <th scope="col">
            <label htmlFor="">Dávkování</label>
          </th>
          <th scope="col">
            <label htmlFor="">Podávání od</label>
          </th>
          <th scope="col">
            <label htmlFor="">Podávání do</label>
          </th>
          <th scope="col">
            <label htmlFor="">Číslo šarže</label>
          </th>
          <th scope="col">
            <label htmlFor="">Způsob/y podávání</label>
          </th>
          <th scope="col">
            <label htmlFor="">Indikace pro podání</label>
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((item, index) => (
          <tr key={`index${index}`}>
            <td style={{ width: 300 }}>
              <AsyncSelectComp
                name={`drugs.${index}.drug`}
                endPoint="drugs"
                active={false}
                labelClass="d-none"
                containerClass="w-100"
              />
              {error && error[index] && (
                <p
                  style={{
                    position: "absolute",
                    bottom: "-2px",
                    left: "20px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {error[index].drug?.message}
                </p>
              )}
            </td>
            <td>
              <Input
                name={`drugs.${index}.strength`}
                error={error ? error[index]?.strength : undefined}
              />
            </td>
            <td>
              <Input
                name={`drugs.${index}.dosage`}
                error={error ? error[index]?.dosage : undefined}
              />
            </td>
            <td>
              <DatePickerPlain
                name={`drugs.${index}.application_from`}
                isClearable={false}
                error={error ? error[index]?.application_from : undefined}
              />
            </td>
            <td>
              <DatePickerPlain
                name={`drugs.${index}.application_to`}
                isClearable={false}
                error={error ? error[index]?.application_to : undefined}
              />
            </td>
            <td>
              <Input
                name={`drugs.${index}.batch_number`}
                error={error ? error[index]?.batch_number : undefined}
              />
            </td>
            <td>
              <Input
                name={`drugs.${index}.application_method`}
                error={error ? error[index]?.application_method : undefined}
              />
            </td>
            <td>
              <Input
                name={`drugs.${index}.application_indication`}
                error={error ? error[index]?.application_indication : undefined}
              />
            </td>
            <td>
              <IoMdClose
                onClick={remove}
                style={{ color: "red", cursor: "pointer", margin: "12 8" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
