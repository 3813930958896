import { useParams } from "react-router-dom";
import { AllPatientsList } from "../../components/AllPatients/AllPatientsList";

export const AllPatients = () => {
  const { careType } = useParams();

  return (
    <div>
      <h1>
        Celá nemocnice -{" "}
        {careType === "hospitalization" ? "hospitalizovaní pacienti" : ""}
      </h1>
      <AllPatientsList careType={careType} />
    </div>
  );
};
