import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";
import { patientService } from "../../services/patient.services";
import { useHistory } from "react-router-dom";
import { BiSortAlt2 } from "react-icons/bi";

const externalId = [
  "external_id",
  "external_id__contains",
  "external_id__endswith",
  "external_id__startswith",
];
const age = ["age_display", "age_display__max", "age_display__min"];
const birthNumber = [
  "birth_number",
  "birth_number__contains",
  "birth_number__startswith",
  "birth_number__endswith",
];
const patientName = [
  "name",
  "name__contains",
  "name__startswith",
  "name__endswith",
];

const departmentDescription = [
  "current_care_department_description",
  "current_care_department_description__contains",
  "current_care_department_description__endswith",
  "current_care_department_description__startswith",
];
const startedAt = [
  "current_care_started_at",
  "current_care_started_at__max",
  "current_care_started_at__min",
];
const doctorName = [
  "current_care_doctor_name",
  "current_care_doctor_name__contains",
  "current_care_doctor_name__endswith",
  "current_care_doctor_name__startswith",
];

const defaultFilter = {
  ...externalId.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...age.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...birthNumber.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...patientName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...departmentDescription.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...startedAt.reduce((a, v) => ({ ...a, [v]: undefined }), {}),
  ...doctorName.reduce((a, v) => ({ ...a, [v]: undefined }), {}),

  has_checkin: undefined,
  risk_level: undefined,
  require_attention: undefined,

  noCheckin: false,
  hasCheckin: false,

  riskLevelThree: false,
  riskLevelOne: false,
  riskLevelTwo: false,
  riskLevelNone: false,
};

export const PatientsExternalPresentLogic = (id) => {
  const [table, setTable] = useState({ loading: true });
  const [error, setError] = useState({ error: false });

  const [paramsState, setParamsState] = useState({
    current_care_type: "external",
    offset: localStorage.getItem("patient-external-present-page")
      ? Number(localStorage.getItem("patient-external-present-page")) === 1
        ? 0
        : Number(localStorage.getItem("patient-external-present-page")) *
            PAGINATION_PER_PAGE_LIMIT -
          PAGINATION_PER_PAGE_LIMIT
      : 0,
    page: localStorage.getItem("patient-external-present-page")
      ? localStorage.getItem("patient-external-present-page")
      : 1,
    limit: PAGINATION_PER_PAGE_LIMIT,
    ordering: localStorage.getItem("patient-external-present-ordering")
      ? localStorage.getItem("patient-external-present-ordering")
      : "-current_care_started_at",
  });

  const storageFilter = JSON.parse(
    localStorage.getItem("patient-external-present-filter")
  );

  const [filter, setFilter] = useState(
    storageFilter ? storageFilter : defaultFilter
  );
  let history = useHistory();

  //Get data on componenet mount
  // care_type ='external'
  useEffect(() => {
    findPatients();
  }, []);

  const handleTableSort = (value) => {
    getExternalPatients(value);
  };

  useEffect(() => {
    localStorage.setItem(
      "patient-external-present-filter",
      JSON.stringify(filter)
    );
  }, [filter]);

  const handleDateChange = (event, field) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      current_care_started_at: undefined,
      current_care_started_at__max: undefined,
      current_care_started_at__min: undefined,
    }));

    setFilter((prevFilter) => ({
      ...prevFilter,
      [field]: event,
    }));
  };

  const handleFilterChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    resetTableFilterValues(name);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const handleCheckboxFilterChange = (name) => {
    resetCheckboxFilterValues(name);

    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: !filter[name],
    }));
  };

  const handleResetFilter = () => {
    localStorage.removeItem("patient-external-present-filter");
    window.location.reload(false);
  };

  //get patients with care type external
  const getExternalPatients = async (value) => {
    let orderingValue = paramsState.ordering;
    if (value) {
      orderingValue = paramsState.ordering.includes("-") ? value : `-${value}`;
      localStorage.setItem("patient-external-present-ordering", orderingValue);
    }
    const dataFilter = { ...filter };

    try {
      setTable({ ...table, loading: true });
      let data = await patientService.getPatientsFilter({
        ...paramsState,
        ordering: orderingValue,
        ...filterMapper(dataFilter),
      });
      const pagination = { count: data.count };
      setParamsState({
        ...paramsState,
        page: data.page,
        offset: data.offset,
        ordering: orderingValue,
      });
      data = await pTableData(data.results);
      setTable({ ...table, loading: false, data, pagination });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba pči načítání pacientů" });
    }
  };

  const onPaginate = async (offset, page) => {
    localStorage.setItem("patient-external-present-page", page);
    const data = { ...filter };
    try {
      setTable({ ...table, loading: true });
      let response = await patientService.getPatientsFilter({
        ...paramsState,
        ...filterMapper(data),
        offset,
        page,
      });
      const pagination = {
        count: response.count,
      };
      setParamsState({
        ...paramsState,
        page: response.page,
        offset: response.offset,
      });
      const tableData = await pTableData(response.results);
      setTable({ ...table, loading: false, data: tableData, pagination });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba pči načítání pacientů" });
    }
  };
  //FORM SECTION
  const methods = useForm({
    defaultValues: {
      care_date_from: "",
      care_date_to: helpFunc.getCurrentDate(),
    },
  });

  //Get patients acc to options
  //Update to be able to find according to picked date
  const findPatients = async (data) => {
    //date picker => start "" => when delete null
    let params = {
      clinic: id,
      is_active: true,
      current_care_type: "external",
      ordering: paramsState.ordering,
      page: paramsState.page,
      offset: paramsState.offset,
      limit: PAGINATION_PER_PAGE_LIMIT,
    };
    localStorage.setItem("patient-external-present-page", 1);

    const dataFilter = { ...filter };

    try {
      //call api
      let response = await patientService.getPatientsFilter({
        ...params,
        page: 1,
        offset: 0,
        ...filterMapper(dataFilter),
      });
      if (response.results.length === 0)
        setError({ error: true, message: "Žádný pacient nebyl nalezen" });
      const pagination = {
        ...table.pagination,
        count: response.count,
      };

      setParamsState({
        ...params,
        page: response.page,
        offset: response.offset,
      });
      response = await pTableData(response.results);
      setTable({ ...table, loading: false, data: response, pagination });
    } catch (erorr) {
      console.error(error);
      setError({ error: true, message: "Chyba při vyhledávání pacientů." });
    }
  };

  const pTableData = async (data) => {
    return data.map((val) => {
      return {
        ...val,
        external_id: helpFunc.pStrToNum(val.external_id),
        age: helpFunc.countPatientAge(
          val.age_display,
          val.age_display_measure_unit
        ),
        department: val.current_care.external_department.department,
        startedAt: helpFunc.formatDate(val.current_care.started_at),
        doctor_name: val.current_care_doctor_name,
        status: getPatientStatus(val.current_care),
      };
    });
  };

  const getPatientStatus = (activeCare) => {
    if (activeCare.checkin === "" || activeCare.checkin === null)
      return "noCheckin";
    if (activeCare.checkin.risk_level === "3") return "riskLevelThree";
    if (activeCare.checkin.risk_level === "2") return "riskLevelTwo";
    if (activeCare.checkin.risk_level === "1") return "riskLevelOne";
    if (activeCare.checkin.risk_level === "none") return "none";
    return "hasCheckin";
  };

  const resetTableFilterValues = (name) => {
    if (externalId.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        external_id: undefined,
        external_id__contains: undefined,
        external_id__startswith: undefined,
        external_id__endswith: undefined,
      }));
    }

    if (age.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        age_display: undefined,
        age_display__max: undefined,
        age_display__min: undefined,
      }));
    }

    if (birthNumber.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        birth_number: undefined,
        birth_number__contains: undefined,
        birth_number__startswith: undefined,
        birth_number__endswith: undefined,
      }));
    }

    if (patientName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        name: undefined,
        name__contains: undefined,
        name__startswith: undefined,
        name__endswith: undefined,
      }));
    }

    if (departmentDescription.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_department_description_description: undefined,
        current_care_department_description_description__contains: undefined,
        current_care_department_description_description__startswith: undefined,
        current_care_department_description_description__endswith: undefined,
      }));
    }

    if (startedAt.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_started_at: undefined,
        current_care_started_at__max: undefined,
        current_care_started_at__min: undefined,
      }));
    }

    if (doctorName.some((item) => item === name)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        current_care_doctor_name_description: undefined,
        current_care_doctor_name_description__contains: undefined,
        current_care_doctor_name_description__startswith: undefined,
        current_care_doctor_name_description__endswith: undefined,
      }));
    }
  };

  const resetCheckboxFilterValues = (name) => {
    if (name === "noCheckin") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        hasCheckin: false,
        require_attention: undefined,
        riskLevelThree: false,
        riskLevelOne: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        risk_level: undefined,
        has_checkin: false,
      }));
    }

    if (name === "hasCheckin") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        noCheckin: false,
        has_checkin: true,
      }));
    }

    if (name === "require_attention") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelThree") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelOne: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelTwo") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelOne: false,
        riskLevelThree: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelOne") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelThree: false,
        riskLevelTwo: false,
        riskLevelNone: false,
        noCheckin: false,
      }));
    }

    if (name === "riskLevelNone") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        riskLevelThree: false,
        riskLevelTwo: false,
        riskLevelOne: false,
        noCheckin: false,
      }));
    }
  };

  //TABLE SECTION
  const columns = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age_display")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>Zdravotnícke oddelení</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleTableSort(
                  "current_care_last_dekurz_department_description"
                )
              }
            />
          </span>
        </>
      ),
      accessor: "department",
    },
    {
      Header: () => (
        <>
          <span>Jméno žádajíciho lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
    },
    {
      Header: () => (
        <>
          <span>Datum návštěvy</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_started_at")}
            />
          </span>
        </>
      ),
      accessor: "startedAt",
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button desktop-visible"
            onClick={() => openPatient(row)}
          >
            Otevřít pacienta
          </button>
          <i
            style={{ color: "#ffffff" }}
            className="fas fa-user-injured mobile-visible mr-2 table-icon"
            onClick={() => openPatient(row)}
          ></i>
          <i
            className="fas fa-info-circle mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
            style={{ color: "#ffffff" }}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },

    { Header: "", accessor: "status" },
    { Header: "", accessor: "more_information" },
  ]);

  const openPatient = (row) => {
    row.original.cares[0].checkin !== null
      ? history.push(
          `/patient-profile/${row.original.id}/care/${row.original.cares[0].id}/pharmacological-plan`
        )
      : history.push(
          `/patient-entry-control/${row.original.id}/care/${row.original.cares[0].id}`
        );
  };

  //Handle erorr popup
  const togglePopup = () => {
    setError({ error: false });
  };

  const filterMapper = (obj) => {
    const maped = obj;
    for (const key in maped) {
      if (
        maped[key] === undefined ||
        maped[key] === "" ||
        maped[key] === null
      ) {
        delete maped[key];
      }
    }

    if (maped.require_attention === false) {
      maped.require_attention = undefined;
    }

    let risk_level = undefined;
    if (maped.riskLevelOne) risk_level = "1";
    if (maped.riskLevelTwo) risk_level = "2";
    if (maped.riskLevelThree) risk_level = "3";
    if (maped.riskLevelNone) risk_level = "none";

    maped.risk_level = risk_level;

    let has_checkin = undefined;
    if (maped.hasCheckin) has_checkin = true;
    if (maped.noCheckin) has_checkin = false;

    maped.has_checkin = has_checkin;

    if (maped.current_care_started_at)
      maped.current_care_started_at = helpFunc.parseDate(
        maped.current_care_started_at
      );
    if (maped.current_care_started_at__max)
      maped.current_care_started_at__max = helpFunc.parseDate(
        maped.current_care_started_at__max
      );
    if (maped.current_care_started_at__min)
      maped.current_care_started_at__min = helpFunc.parseDate(
        maped.current_care_started_at__min
      );

    delete maped.noCheckin;
    delete maped.hasCheckin;
    delete maped.riskLevelThree;
    delete maped.riskLevelOne;
    delete maped.riskLevelTwo;
    delete maped.riskLevelNone;
    return maped;
  };

  return {
    error,
    table,
    columns,
    methods,
    filter,
    paramsState,
    togglePopup,
    findPatients,
    getExternalPatients,
    setParamsState,
    onPaginate,
    handleDateChange,
    handleFilterChange,
    handleCheckboxFilterChange,
    handleResetFilter,
  };
};
