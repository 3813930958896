import { useEffect } from 'react';
import { helpFunc } from '../../../helpers/helpFunction';
import { patientService } from '../../../services/patient.services';

export const FieldArrayLogic = (name = "", endPoint = "", active) => {
    const filterOptions = async (inputValue) => {
        //set it as async to api
        //set data parsing
        let response = await patientService.liveSearch({search: inputValue}, endPoint)
        let data = response.data.results
        if (data.length === 0) {
            return [{label: "Záznam nebyl nalezen", value: ""}]
        }
        data = await helpFunc.pDataForSelect (data, name)
        return data
      };
      
      const promiseOptions = (inputValue) =>
        new Promise ((resolve) => {
            setTimeout(() => {
                resolve(filterOptions(inputValue));
            }, 1000);
        });
    
    useEffect (() => {
        changeFormValues()
    }, [active])

    const changeFormValues = () => {
        
    }

    return {promiseOptions}
}
