import React from 'react' 
import { RiDeleteBinLine } from 'react-icons/ri'
import { CommentLogic } from './CommentLogic'

export const Comment = ({text, date, name, img, id, type, careIsLocked=false, ...props}) => {
    const { error, deleteComment } = CommentLogic (type, id)
    let user = name !== null ? name.username : 'NO USER'
    console.log(careIsLocked)
    return (
        <div className='comment'>
            <div className='commentHeader'>
                <i className='fas fa-user-circle'></i>  <span className='commentName strong'>{user}</span> |<span> {date}</span> 
                {!careIsLocked && <div className='delete-icon'><span><RiDeleteBinLine onClick={deleteComment}/></span></div>}
            </div>
            <div className='error'>
                {error.error && <span className='strong'>{error.message}</span>}
            </div>
            <div className='commentBody'>
                <p>{text}</p>
            </div> 
        </div>
    )
} 