import React from 'react' 

export const PopUp = ({message, clearErrors, toggle, ...props}) => {
    const handleClick = () => {
        toggle()
    }

    return (
        <div className="modal flex-container-column">
            <div className="modal_content">
                <div className='text'>
                    <p className='strong'>{message}</p>
                </div>
                <div className='btn-row'>
                    <button type='button' className="button" onClick={handleClick}>OK</button>
                </div>
            </div>
        </div>
    )

}