import {useState, useEffect} from 'react' 
import { useForm } from 'react-hook-form'
import { patientService } from '../../services/patient.services'
import { useHistory } from 'react-router-dom'

//REFACTOR
//default values
//setting values according to data from previous evaluation 

export const FtdEvaluationReasonFormLogic = () => {
    const [ state, setState ] = useState({submit: false, dataSaved: false, locked: false})
    const [ evaluation, setEvaluation ] = useState ({loading: true, data: {}})
    const [ error, setError ] = useState ("")
    const history = useHistory ()
    const id = history.location.state.id

    //Load evaluation on component mount
    useEffect (() => {
        //find evaluation based on id I am lookign for
        getEvaluation ()
    }, [])

    //Gets new data from evaluation after previous evaluation form is changed
    const getEvaluation = async () => {
        try {
            //load evaluation data from db
            let data = await patientService.getEvaluation(id)
            await setFormValues (data)
            setEvaluation({loading: false, data: data})
        } catch (error) {
            console.error(error) 
            setError({error: true, message: 'Chyba při načítání FTD hodnocení.'})
        }
    }

    //Saving evaluation reason data
    const saveData = async (data) => {
        setState ({...state, submit: true, dataSaved: false})
        try {
            //evaluation id to patch it correctly 
            await patientService.patchEvaluation(data, id)
            setState({...state, submit: false, dataSaved: true})
        } catch (error) {
            console.error(error) 
            setError ({error:true, message: 'Chyba při ukládání dat.'})
        }
    }
    //go back when care is locked
    const backToEvaluation = () => {
        setState({...state, locked: true})
    }

    //Form section
    const formMethods = useForm({
        defaultValues: {
            deployment_initial_diagnosis: false,
            deployment_during_diagnosis: false, 
            deployment_ft_approach: false,
            deployment_other_reason: '',
            continuation_medical_intervention: false, 
            continuation_other_reason: '',
            discontinuation_contradiction: false, 
            discontinuation_adverse_effect: false, 
            discontinuation_adverse_effect_risk: false, 
            discontinuation_missing_indication: false, 
            discontinuation_allergies: false, 
            discontinuation_underdosage_risk: false, 
            discontinuation_overdosage_risk: false, 
            discontinuation_drug_interaction: false, 
            discontinuation_duplicity: false, 
            discontinuation_renal_insufficiency: false, 
            discontinuation_hepatic_insufficiency: false,
            discontinuation_medical_intervention: false,
            discontinuation_other_reason: '',
            dose_change_adverse_effect: false,
            dose_change_adverse_effect_risk: false,
            dose_change_hepatic_insufficiency: false,
            dose_change_renal_insufficiency: false,
            dose_change_drug_interaction: false,
            dose_change_underdosage: false,
            dose_change_overdosage: false,
            dose_change_laboratory_findings: false,
            dose_change_dosage_reduction: false,
            dose_change_dosage_increase: false,
            dose_change_other_reason: '',
            deployment_ft_approach: false,
            deployment_ft_approach: false,
            deployment_ft_approach: false,
            deployment_ft_approach: false,
            deployment_ft_approach: false,
            deployment_ft_approach: false,
            deployment_ft_approach: false,

            
        }
    })
    const {setValue} = formMethods

    //Setting values according to what was picked in form before. 
    const setFormValues = async (data) => {
        if (data.deployment) {
            setValue('deployment_initial_diagnosis', data.deployment_initial_diagnosis)
            setValue('deployment_during_diagnosis', data.deployment_during_diagnosis)
            setValue('deployment_ft_approach', data.deployment_ft_approach)
            setValue('deployment_other_reason', data.deployment_other_reason || '')
        }
        if (data.continuation_drug_reintroduction) {
            setValue('continuation_medical_intervention', data.continuation_medical_intervention)
            setValue('continuation_other_reason', data.continuation_other_reason || '')
        }
        if (data.discontinuation) {
            setValue('discontinuation_contradiction', data.discontinuation_contradiction)
            setValue('discontinuation_adverse_effect', data.discontinuation_adverse_effect)
            setValue('discontinuation_adverse_effect_risk', data.discontinuation_adverse_effect_risk)
            setValue('discontinuation_missing_indication', data.discontinuation_missing_indication)
            setValue('discontinuation_allergies', data.discontinuation_allergies)
            setValue('discontinuation_underdosage_risk', data.discontinuation_underdosage_risk)
            setValue('discontinuation_overdosage_risk', data.discontinuation_overdosage_risk)
            setValue('discontinuation_drug_interaction', data.discontinuation_drug_interaction)
            setValue('discontinuation_duplicity', data.discontinuation_duplicity)
            setValue('discontinuation_renal_insufficiency', data.discontinuation_renal_insufficiency)
            setValue('discontinuation_hepatic_insufficiency', data.discontinuation_hepatic_insufficiency)
            setValue('discontinuation_medical_intervention', data.discontinuation_medical_intervention)
            setValue('discontinuation_other_reason', data.discontinuation_other_reason || '')
        }
        if (data.dose_change) {
            setValue('dose_change_adverse_effect', data.dose_change_adverse_effect)
            setValue('dose_change_adverse_effect_risk', data.dose_change_adverse_effect_risk)
            setValue('dose_change_hepatic_insufficiency', data.dose_change_hepatic_insufficiency)
            setValue('dose_change_drug_interaction', data.dose_change_drug_interaction)
            setValue('dose_change_underdosage', data.dose_change_underdosage)
            setValue('dose_change_overdosage', data.dose_change_overdosage)
            setValue('dose_change_laboratory_findings', data.dose_change_laboratory_findings)
            setValue('dose_change_dosage_reduction', data.dose_change_dosage_reduction)
            setValue('dose_change_dosage_increase', data.dose_change_dosage_increase)
            setValue('dose_change_other_reason', data.dose_change_other_reason || '')
        }
    }

    //Control toggle pop up and clean errors
    const togglePopUp = () => {
        setError({error:false})
        setState({...state, submit: false, dataSaved: false})
    }
    
    // const evaluation = original
    return {state, formMethods, saveData, evaluation, error, togglePopUp, backToEvaluation}
}