import { cs } from "date-fns/locale";
import { useEffect, useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";

export const DateField = (props) => {
  const { filter, onChange, field } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  const opt = [
    { value: equal, label: "Rovná se" },
    { value: max, label: "Menší nebo rovná se" },
    { value: min, label: "Větší nebo rovná se" },
  ];

  const [option, setOption] = useState({
    value: equal,
    label: "Rovná se",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[max]) {
      setValue(filter[max]);
      setOption(opt.find((item) => item.value === max));
    } else if (filter[min]) {
      setValue(filter[min]);
      setOption(opt.find((item) => item.value === min));
    } else if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[max]) {
      setOption(opt.find((item) => item.value === max));
    } else if (filter[min]) {
      setOption(opt.find((item) => item.value === min));
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    onChange(value ? value : "", option.value);
  };

  registerLocale("cz", cs);

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="age-select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <ReactDatePicker
        dateFormat="dd.MM.yyyy"
        calendarStartDay={1}
        className="table-filter-input"
        placeholderText="dd.mm.rrrr"
        onChange={(event) => onChange(event, option.value)}
        selected={value && new Date(value)}
        locale="cz"
        isClearable={false}
        name={option.value}
      />
    </td>
  );
};
