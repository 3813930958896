import React from "react";
import { useFormContext, Controller } from "react-hook-form";

export const Radio = ({
  id,
  value,
  label,
  name,
  labelClass,
  radioClass,
  error = undefined,
  ...props
}) => {
  const methods = useFormContext();

  return (
    <>
      <input
        type="radio"
        id={id}
        data-value={value}
        value={value}
        className={radioClass}
        {...methods.register(name)}
        {...props}
      />
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      {error && (
        <p
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "0px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </>
  );
};
