import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { patientService } from "../../services/patient.services";
import { careServices } from "../../services/care.services";

export const PatientEntryControlLogic = () => {
  const [error, setError] = useState("");
  const [patient, setPatient] = useState({ loading: true });
  const [navigation, setNavigation] = useState("entry-control");
  const { id, care_id } = useParams();

  const getPatient = async () => {
    try {
      setPatient({ ...patient, loading: true });
      const data = await patientService.getPatient(id);
      const care = await careServices.getCare(care_id);
      setPatient({ loading: false, data: data, care: care, careID: care.id });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání pacienta." });
    }
  };

  useEffect(() => {
    getPatient();
  }, []);

  return { patient, error, navigation, setNavigation };
};
