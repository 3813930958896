import React, { useContext } from "react";
import { TableContext } from "./PatientHospitalPresentTable";

export const TableHead = (props) => {
  const { headerGroups } = useContext(TableContext);

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          <th>Ž {props.careType === "hospitalization" && "/ PH"}</th>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
