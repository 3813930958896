import { useState, useEffect } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { cs } from "date-fns/esm/locale";
import { filter } from "lodash";

export const NumberFieldFilter = (props) => {
  const { filter, onChange, onSubmit, field, type = "number" } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  const opt = [
    { value: equal, label: "Rovná se" },
    { value: min, label: "Větší nebo rovná se" },
    { value: max, label: "Menší nebo rovná se" },
  ];

  const [option, setOption] = useState({
    value: equal,
    label: "Rovná se",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[min]) {
      setValue(filter[min]);
      setOption(opt.find((item) => item.value === min));
    } else if (filter[max]) {
      setValue(filter[max]);
      setOption(opt.find((item) => item.value === max));
    } else if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[min]) {
      setOption(opt.find((item) => item.value === min));
    } else if (filter[max]) {
      setOption(opt.find((item) => item.value === max));
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    const oldEvent = {
      target: { value: value ? value : "", name: option.value },
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="age-select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <input
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </td>
  );
};

export const TextFieldFilter = (props) => {
  const { filter, onChange, onSubmit, field, type = "text" } = props;

  const equal = field;
  const contains = `${field}__contains`;
  const startswith = `${field}__startswith`;
  const endswith = `${field}__endswith`;

  const opt = [
    { value: contains, label: "Obsahuje" },
    { value: startswith, label: "Začíná na" },
    { value: endswith, label: "Končí na" },
    { value: equal, label: "Rovná se" },
  ];

  const [option, setOption] = useState({
    value: contains,
    label: "Obsahuje",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else if (filter[startswith]) {
      setValue(filter[startswith]);
      setOption(opt.find((item) => item.value === startswith));
    } else if (filter[endswith]) {
      setValue(filter[endswith]);
      setOption(opt.find((item) => item.value === endswith));
    } else if (filter[contains]) {
      setValue(filter[contains]);
      setOption(opt.find((item) => item.value === contains));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else if (filter[startswith]) {
      setOption(opt.find((item) => item.value === startswith));
    } else if (filter[endswith]) {
      setOption(opt.find((item) => item.value === endswith));
    } else if (filter[contains]) {
      setOption(opt.find((item) => item.value === contains));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    const oldEvent = {
      target: { value: value ? value : "", name: option.value },
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <input
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </td>
  );
};

export const DiagnosisNameFilter = (props) => {
  const { filter, onChange, onSubmit } = props;

  const opt = [
    { value: "current_care_diagnosis_name__contains", label: "Obsahuje" },
    { value: "current_care_diagnosis_name__startswith", label: "Začíná na" },
    { value: "current_care_diagnosis_name__endswith", label: "Končí na" },
  ];

  const [option, setOption] = useState({
    value: "current_care_diagnosis_name__contains",
    label: "Obsahuje",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter.current_care_diagnosis_name__startswith) {
      setValue(filter.current_care_diagnosis_name__startswith);
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__startswith"
        )
      );
    } else if (filter.current_care_diagnosis_name__endswith) {
      setValue(filter.current_care_diagnosis_name__endswith);
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__endswith"
        )
      );
    } else if (filter.current_care_diagnosis_name__contains) {
      setValue(filter.current_care_diagnosis_name__contains);
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__contains"
        )
      );
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter.current_care_diagnosis_name__startswith) {
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__startswith"
        )
      );
    } else if (filter.current_care_diagnosis_name__endswith) {
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__endswith"
        )
      );
    } else if (filter.current_care_diagnosis_name__contains) {
      setOption(
        opt.find(
          (item) => item.value === "current_care_diagnosis_name__contains"
        )
      );
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    const oldEvent = {
      target: { value: value ? value : "", name: option.value },
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <input
        type="text"
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </td>
  );
};

export const DateFieldFilter = (props) => {
  const { filter, onChange, field } = props;

  const equal = field;
  const min = `${field}__min`;
  const max = `${field}__max`;

  const opt = [
    { value: equal, label: "Rovná se" },
    { value: max, label: "Menší nebo rovná se" },
    { value: min, label: "Větší nebo rovná se" },
  ];

  const [option, setOption] = useState({
    value: equal,
    label: "Rovná se",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[max]) {
      setValue(filter[max]);
      setOption(opt.find((item) => item.value === max));
    } else if (filter[min]) {
      setValue(filter[min]);
      setOption(opt.find((item) => item.value === min));
    } else if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[max]) {
      setOption(opt.find((item) => item.value === max));
    } else if (filter[min]) {
      setOption(opt.find((item) => item.value === min));
    } else if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    onChange(value ? value : "", option.value);
  };

  registerLocale("cz", cs);

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="age-select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <ReactDatePicker
        dateFormat="dd.MM.yyyy"
        calendarStartDay={1}
        className="table-filter-select"
        placeholderText="dd.mm.rrrr"
        onChange={(event) => onChange(event, option.value)}
        selected={value && new Date(value)}
        locale="cz"
        isClearable={false}
        name={option.value}
      />
    </td>
  );
};

export const SelectFieldFilter = (props) => {
  const { onChange, filter, field, options } = props;

  const [option, setOption] = useState({ id: 0, name: "Vyberte" });

  useEffect(() => {
    if (filter[field] && options.length !== 0) {
      setOption(options.find((item) => item.id == filter[field]));
    }
  }, [options]);

  return (
    <td role="cell" className="td-filter">
      <select className="table-filter-select" name={field} onChange={onChange}>
        <option value={option.id}>{option.name}</option>
        {options.map((item, key) => (
          <option
            key={key}
            className={item.id == option.id ? "hidden" : ""}
            value={item.id}
          >
            {item.name}
          </option>
        ))}
      </select>
    </td>
  );
};
