import React from "react";
import { Table,PatientInfo, PopUp, Checkin } from "../../components";
import { FaRegCaretSquareDown } from 'react-icons/fa'
import { FtdEvaluationLogic } from "./FtdEvaluationLogic";

export const FtdEvaluation = ({patient, careIsLocked = false, careID, ftdEvaluation, checkin, ...props}) => {
    const {state, columns, table, error, evaluation, togglePopUp, addNewEvalution, showCheckin } = FtdEvaluationLogic (checkin, ftdEvaluation, careID, careIsLocked)

    return (
        <>
        <div className="patient-profile">
            {error.error && <PopUp toggle={togglePopUp} message={error.message} />}
            <PatientInfo patientData={patient} displayCareProcedures={true} careID={careID} /> 
            {/* Simple table */}
            {state.popUp ? <PopUp message={error.message} toggle={togglePopUp} /> : null}
            
            <div className='myContent'>
                <div className='grid-row'>
                    <div className='grid-column'>
                    {/* Talbe with basic patient data */}
                    {table.loading ? <h2>Načítání...</h2> :
                        ( table.data ? 
                        <>
                        <Table data={table.data} columns={columns} />
                        <div className="iconMoreInfo">
                            <FaRegCaretSquareDown onClick={showCheckin}/>
                        </div>
                        </> : <h2>Pacient nemá vstupní kontrolu</h2>
                    )}
                    </div>
                </div>
                {/* Show checkin information when click on arrow button */}
                {(state.checkinInfo && checkin) &&
                    <>
                    <div className="grid-row">
                        <div className="grid-column patient-checkin-table">
                            <Checkin displayAll={false} toggle={showCheckin} checkin={checkin} disabled={true} careIsLocked={careIsLocked} />
                        </div>
                    </div>
                    </>
                }
                {/* Load forms based on how many evaluations patient has */}
                {!evaluation.loading && evaluation.list}
                {/* Add new empty evaluation form */}
                <div className="form-row-button">
                    {!careIsLocked && <button className="button" onClick={addNewEvalution}>Přidat léčivo</button> }
                </div>
            </div>
        </div>
        </>
    )
}