import React from "react";
import { useFormContext } from "react-hook-form";

export const Input = ({
  id,
  name,
  placeholder = "",
  type = "text",
  disabled = false,
  error = undefined,
  ...props
}) => {
  const methods = useFormContext();

  return (
    <div>
      <input
        type={type}
        id={id}
        className="inp-text"
        {...methods.register(name)}
        placeholder={placeholder}
        {...props}
        disabled={disabled}
      />

      <p
        style={{
          position: "relative",
          bottom: "-2px",
          left: "0",
          fontSize: "12px",
          color: "red",
        }}
      >
        {error?.message}
      </p>
    </div>
  );
};
