import React from 'react'
import { Route, Redirect } from 'react-router-dom'

//Create a component which check if user is login 
//It is Route component -> user loged in it can access the route if not then no
export const PrivateRoute = ( {component: Component, ...rest } ) => (
    <Route {...rest } 
        render = { props => (
            localStorage.getItem('authToken') 
            ? <Component { ...props } />
            : <Redirect to= {{ pathname:'/signin', state: { from: props.location } }} />
        )}  
    />
)

