import { useState, useEffect, useContext } from 'react' 
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { userService } from '../../services/user.services'
import { AuthContext } from '../../contexts/AuthContext'


export const AuthetificationLogic = () => {
    const [ state, setState ] = useState ({submit: false, signingIn: false, basicAuth: false, kerberosAuth: false, basicAuthBtn: false})
    const [ error, setError ] = useState ({error: false})
    const { setAuthToken } = useContext (AuthContext)
    let history  = useHistory()



    //on component mount 
    useEffect (() => {
        logOut ()//delete token from aplication
        authentificationType ()
    }, [])

    //logout user and reset authentification context
    const logOut = () => {
        //setting global storage to null
        setAuthToken (null)
        //removing user and token from local storage
        userService.logout()
    }

    //decide what uathentification to use 
    const authentificationType = async () => {
        if (process.env.REACT_APP_ENABLE_KERBEROS === 'True' && process.env.REACT_APP_ENABLE_BASICAUTH === 'True') {
            //show btn to activate basic auth and allow kerberos signin
            setState ({...state, basicAuthBtn: true, kerberosAuth: true})
            //login user using kerberos
            kerberosAuth ()
        } else if (process.env.REACT_APP_ENABLE_KERBEROS === 'True') {
            //allow kerberos authentification
            setState ({...state, kerberosAuth: true})
            //login user only with kerberos
            kerberosAuth ()
        } else {
            //login user only with basic authentification
            setState ({...state, basicAuth: true})
        }
    }

    const enableBasicAuth = () => { 
        //show form for basic authentification
        setState ({...state, basicAuth: true, basicAuthBtn: false, kerberosAuth: false})
        clearError ()
    }

    //form mehtods and form default values
    const methods = useForm ({
        defaultValues: {
            username: "", 
            password: ""
        }
    })

    const kerberosAuth = async () => {
            //kerberos authentification
            let response = await userService.loginKerberos()
            //redirect on home page
            if (response.ok) {
                //setting authentification token to global context state 
                setAuthToken (localStorage.getItem('authToken'))
                history.push('/') 
            }
            else {
                handleError (response)
            }

    }


    //runs if 
    //on form submit, user authentification
    const userAuth = async (data, e) => {
        //prevent default reload
        e.preventDefault(); 
        clearError ()
        setState({...state, submit: true, signingIn: true})
        //check if form is full 
            //error if not full, do it with library own scheme req 
        
        //post data to endpoint  -> change userservice login, logout 
        try {
            //login user
            await userService.login(data)
            //setting authentification token to global context state 
            setAuthToken (localStorage.getItem('authToken'))
            //set context state with user
            history.push ('/')

        } catch (error) {
            handleError (error)
        }
    }

    const clearError = () => {
        setError ({error: false})
    }

    const handleError = (error) => {
        console.error(error)
        //set app to error state
        setError ({error: true, message: error.message})
        //reset submit app state to defautl
        if (state.basicAuth) {
            setState ({...state, submit: false, signingIn: false, basicAuthBtn: false})
        } else {
            setState ({...state, submit: false, signingIn: false, basicAuthBtn: true})
        }
        
    }

    return {state, error, methods, userAuth, enableBasicAuth}
} 