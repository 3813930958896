import React from "react";
import { FormProvider } from "react-hook-form";
import {
  Form,
  TextAreaInp,
  FileInput,
  TextInput,
  PopUp,
} from "../../components";
import { PatientInformationFilesLogic } from "./PatientInformationFilesLogic";

export const PatientInformationFiles = ({
  data,
  patientId,
  careIsLocked,
  ...props
}) => {
  const { formMethods, saveData, error, togglePopUp } =
    PatientInformationFilesLogic(data, patientId);

  return (
    <div>
      {error.error && <PopUp message={error.message} toggle={togglePopUp} />}
      <FormProvider {...formMethods}>
        <Form>
          <div className="grid-row">
            <div className="grid-column">
              <TextAreaInp
                rows={10}
                name="text"
                label="Popis"
                disabled={careIsLocked}
                lineHeight="24px"
              />
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column">
              <span>Připojit soubor:</span>
            </div>
            <div className="grid-column">
              <FileInput name="file" disabled={careIsLocked} />
              {!careIsLocked && (
                <button
                  type="submit"
                  className="button"
                  onClick={formMethods.handleSubmit(saveData)}
                >
                  Uložit
                </button>
              )}
            </div>
            <div className="grid-column">
              <span>
                Pouze soubory typu pdf, csv, xls, doc, docx. Velikost max 2 MB.
              </span>
            </div>
          </div>
          <div className="grid-row">
            {data.file !== null ? (
              <a href={data.file}>Soubor {data.name} ke stažení</a>
            ) : null}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};
