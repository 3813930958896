export function authHeader (method = 'GET') {
    //return authorization header with basic auth credentials 
    let token = JSON.parse(localStorage.getItem('authToken'));

    if (token) {
        return {
            method: method, 
            headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token.token}` },
        }
    }
    else {
        return {}
    }
}