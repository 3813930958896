import React from 'react'
import { FtdEvaluationFormLogic, Form, ControlSelect, AsyncSelectComp, SwitchGroup, TextAreaInp, PopUp } from "../../components";
import { FormProvider } from "react-hook-form";
import { Redirect, useRouteMatch } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const FtdEvaluationForm = ({evaluation = null, careID, index, careIsLocked = false, ...props}) => {
    const {state, error, formMethods, editPlan, saveData, deleteDrug, tags, 
        comments, commentsMethods, saveComment, togglePopUp, toggleEvaluation, openReasons, saveAndCreateNew} = FtdEvaluationFormLogic(evaluation, careID, careIsLocked)
    const {params} = useRouteMatch()

    return (
        <>    
        {error.error && <PopUp toggle={togglePopUp} message={error.message} />}
        {state.delete ? null : state.hideEvaluation ? 
            <>
                <div className='grid-row ftd-evaluation p-bot-1' >
                    <div onClick={toggleEvaluation} style={{cursor: 'pointer', width: '100%'}}>
                        <span>{evaluation ? `${evaluation.drug.name} | ${evaluation.drug.name_supplement}` : "Léčivo"}</span>
                    </div>
                    <div className="iconMoreInfo">
                        {!careIsLocked && <span><FaTimes onClick={deleteDrug} style={{cursor: 'pointer', fontSize: 28}} /></span>}
                    </div>
                </div>
            </>
        : state.loading ? <h1>Načítání hodnocení...</h1> :
            <FormProvider {...formMethods} >
                <Form className="myForm">
                    <div className='grid-row ftd-evaluation p-bot-1' >
                        <div onClick={toggleEvaluation} style={{cursor: 'pointer', width: '100%'}}>
                            <span>{evaluation ? `${evaluation.drug.name} | ${evaluation.drug.name_supplement}` : "Léčivo"}</span>
                        </div>
                        <div className="iconMoreInfo">
                        {!careIsLocked && <span><FaTimes onClick={deleteDrug} style={{cursor: 'pointer', fontSize: 28}} /></span>}
                        </div>
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column'> 
                            <AsyncSelectComp name='drug' id="drug" label='Léčivo' labelClass="strong" isDisabled={!state.activateForm} displayBtns={false} active={state.activateForm} endPoint='drugs'/>
                        </div>
                    </div>
                        <div className='grid-row'>
                            <div className='grid-column'>
                                <SwitchGroup names={['deployment','discontinuation','continuation','continuation_drug_reintroduction','dose_change']} 
                                labels={['Nasazení léčiva', 'Vysazení léčiva', 'Pokračování v terapii', 'Znovunasazení léčiva', 'Změna dávky']} 
                                disabled ={!state.activateForm} />
                            </div>
                        </div>

                    <div className='grid-row form-background'>
                        <div className='grid-column'> 
                            <SwitchGroup sectionTitle={'Doporučené vyšetření'} num={4} 
                            names={['recommended_investigation_by_specialist','recommended_investigation_by_laboratory', 'recommended_investigation_by_physical']} 
                            labels={['Specialistou', 'Laboratoří','Fyzikální']} disabled ={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column'> 
                            <SwitchGroup sectionTitle={'TDM'} num={4}
                            names={['tdm_interpretation','tdm_measure_level_recommendation']} 
                            labels={['Interpretace', 'Doporučení změření hladiny']} disabled ={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row form-background'>
                        <div className='grid-column'> 
                            <SwitchGroup sectionTitle={'Specifika'} num={4}
                            names={['specific_adverse_effect_diagnosis', 'specific_adverse_effect_reporting', 'specific_consultation']}
                            labels = {['Diagnostika nežádoucího účinku','Hlášení NÚ', 'Konzultace']} disabled ={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column'> 
                            <SwitchGroup sectionTitle={'Stanovení dávky (při zahájení terapie)'} num={1}
                            names={['dosage_determination']}
                            labels = {['']} disabled ={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row form-background'>
                        <div className='grid-column'> 
                            <SwitchGroup sectionTitle={'Optimalizace způsobu podání'} num={1}
                            names={['administration_method_optimization']}
                            labels = {['']} disabled ={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column'> 
                            <ControlSelect options={tags.data} id={"tags"} label={'Štítky'} labelClass='strong' name='tags' isMulti={true} isDisabled={!state.activateForm} />
                        </div> 
                    </div>
                    <div className='grid-row'>
                        <div className='grid-column'>
                            {state.submit && <span className="strong">Data se ukládají...</span>}
                            {state.dataSaved && <span className="strong">Data jsou uložena.</span>}
                            {state.deleting && <span className="strong">Mažu data...</span>}
                        </div>
                    </div>
                    <div className='form-row-button'>
                        {!careIsLocked ? (state.activateForm ?  
                        <>
                            <button type='submit' className='button' onClick={formMethods.handleSubmit(saveData)}>Uložit</button>
                            <button type='submit' className='button' onClick={formMethods.handleSubmit(saveAndCreateNew)}>Uložit a přidat nové léčivo</button>
                        </> :
                            <button type='button' className='button' onClick={editPlan}>Editovat</button>) : evaluation ? 
                            <button type='button' className='button' onClick={openReasons}>Otevřít důvody</button> : null
                        }
                        {!careIsLocked && <button type='button' className='button' onClick={deleteDrug}>Odebrat léčivo</button>}
                        
                    </div>
                    {state.showReason && <Redirect to={{
                        pathname: `/patient-profile/${params.id}/care/${careID}/ftd-evaluation-reason/`,
                        state: {id: state.evaluationID} 
                    }} />}
                </Form>
            </FormProvider>
        }
        {!state.hideEvaluation && state.update ? comments.loading ? <h1>Načítání komentářů...</h1> :
            <>
               {!careIsLocked && 
               <FormProvider {...commentsMethods}>
                    <Form className="myForm">
                        <div className="grid-row">
                            <div className="grid-column"> 
                                <TextAreaInp labelClass={"strong"} id="comment" label="Komentář" name="text" row={5} columns={15} maxlenght={500} />
                            </div>
                        </div>
                        <div className='form-row-button'>
                            <button type='submit' className='button btn-light' onClick={commentsMethods.handleSubmit(saveComment)}>Uložit komentář</button>
                        </div>
                    </Form>
                </FormProvider>
            } 
                <div className="grid-row comments divider">
                    <div className="grid-column comments">
                        {comments.list}
                    </div>
                </div>
            </> : null }
        </>
    )
}