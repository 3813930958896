import React, { useState, useEffect, useMemo } from "react";
import { useWatch, useForm } from "react-hook-form";
import { helpFunc } from "../../../helpers/helpFunction";
import { historyServices } from "../../../services/history.services";
import { isBefore, isEqual } from "date-fns";

export const CareHistoryLogic = (careData = null, patient = null) => {
  //setup from context & default values
  const [error, setError] = useState({ error: false });
  const [historyData, setHistoryData] = useState({
    loading: true,
    data: null,
    hide: true,
    from: null,
    to: null,
  });
  const [filters, setFilters] = useState({
    loading: true,
    date: { from: null, to: null },
  });

  //load data on component mount
  useEffect(() => {
    getHistoryData(careData, patient);
  }, []);

  //Get history data in current data
  const getHistoryData = async (careData, patient) => {
    try {
      setHistoryData({ ...historyData, loading: true });
      let data = await historyServices.getPatientHistory(careData, patient);
      data = await pTableData(data);
      let from = helpFunc.formatDate(careData.started_at);
      let to = careData?.finished_at
        ? helpFunc.formatDate(careData.finished_at)
        : null;
      setHistoryData({
        ...historyData,
        loading: false,
        data: data,
        from: from,
        to: to,
      });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání historie." });
    }
  };

  //Parse data for table default view
  const pTableData = async (data) => {
    const pData = data.flatMap((item) =>
      item?.data?.length > 0
        ? item.data.map((item) => {
            let user = helpFunc.getAuthor(item?.user);
            return {
              date_original: item.date,
              date: helpFunc.formatDateWithTime(item.date),
              user: user,
              value: helpFunc.parseNameToStringHistory(item.entity_name),
              value_original: item.entity_name,
              changes: item.field_changes,
            };
          })
        : []
    );
    //from data find a filter options for filter select
    getFilterOptions(pData);
    return pData;
  };

  // FORM SECTION
  const methods = useForm({
    defaultValues: {
      users: { label: "Vyberte uživatele", value: false },
      values: { label: "Vyberte záznam", value: false },
      from: "",
      to: "",
    },
  });

  //getting data from filters
  const user = useWatch({ control: methods.control, name: "users" });
  const value = useWatch({ control: methods.control, name: "values" });
  const from = useWatch({ control: methods.control, name: "from" });
  const to = useWatch({ control: methods.control, name: "to" });

  //Set date filter
  const dateRangeFilter = (rows, id, filterValue) => {
    const startDate =
      filterValue.from !== "1.1.1970" && filterValue.from !== null
        ? new Date(filterValue.from)
        : undefined;
    let endDate =
      filterValue.to !== "1.1.1970" && filterValue.to !== null
        ? new Date(filterValue.to)
        : undefined;
    endDate = endDate !== undefined ? endDate.setHours(23) : undefined;

    if (startDate || endDate) {
      return rows.filter((r) => {
        const cellDate = new Date(r.original["date_original"]);

        if (startDate && endDate) {
          return cellDate >= startDate && cellDate <= endDate;
        } else if (startDate) {
          return cellDate >= startDate;
        } else if (endDate) {
          return cellDate <= endDate;
        }
      });
    } else {
      return rows;
    }
  };

  useEffect(() => {
    if (filters.date.from && filters.date.to) {
      if (isBefore(filters.date.to, filters.date.from)) {
        setError({
          error: true,
          message: "Datum Od nesmí být větší než datum Do",
        });
      }
    }
  }, [filters.date.from, filters.date.to]);

  //control and set user filter
  useEffect(() => {
    const filterUser = (user) => {
      setFilters({
        ...filters,
        userFilter: { id: "user", value: user?.value },
      });
    };

    filterUser(user);
  }, [user]);

  //control and set value filter
  useEffect(() => {
    const filterVal = (value) => {
      setFilters({
        ...filters,
        valueFilter: { id: value?.id, value: value?.value },
      });
    };

    filterVal(value);
  }, [value]);

  //control and set date filter from
  useEffect(() => {
    const filterDate = (from) => {
      setFilters({ ...filters, date: { ...filters.date, from: from } });
    };
    filterDate(from);
  }, [from]);

  //control and set date filter to
  useEffect(() => {
    const filterDate = (to) => {
      setFilters({ ...filters, date: { ...filters.date, to: to } });
    };
    filterDate(to);
  }, [to]);

  //get possible options from table data
  const getFilterOptions = async (data) => {
    let values = [...new Set(data.map((i) => i.value))];
    values = await helpFunc.pDataForSelect(values, "historyFilter");
    values.unshift({ label: "Všechny změny", value: "" });
    let users = [
      ...new Set(data.map((i) => (i.user == null ? "no_user" : i.user))),
    ];
    users = await helpFunc.pDataForSelect(users, "historyFilter");
    users.unshift({ label: "Všichni uživatelé", value: "" });
    setFilters({
      ...filters,
      loading: false,
      users: users,
      values: values,
      dateRangeFilter: dateRangeFilter,
    });
  };

  // DEFINE TABLE DATA
  const columns = useMemo(() => [
    { Header: "Datum", accessor: "date", filter: dateRangeFilter },
    { Header: "Kdo", accessor: "user" },
    { Header: "Změna", accessor: "value" },
    {
      Header: () => null,
      id: "detail",
      Cell: ({ row }) => (
        <>
          <i
            className="fas fa-chevron-circle-down mobile-visible ml-2 table-icon"
            {...row.getToggleRowExpandedProps()}
          ></i>
          <button
            type="button"
            className="button desktop-visible"
            {...row.getToggleRowExpandedProps()}
          >
            Zobrazit detail
          </button>
        </>
      ),
    },
  ]);

  //Clean errors and toggle popup
  const togglePopUp = () => {
    setError({ error: false });
  };

  const toggleCare = () => {
    setHistoryData((prevState) => {
      return {
        ...prevState,
        hide: !prevState.hide,
      };
    });
  };

  return {
    error,
    historyData,
    filters,
    methods,
    columns,
    toggleCare,
    togglePopUp,
  };
};
