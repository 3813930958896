import React from "react";
import { Legend, Pagination, PopUp } from "..";
import { Table } from "./PatientExternalPresentTable";
import { PatientsExternalPresentLogic } from "./PatientsExternalPresentLogic";

export const PatientsExternalPresent = () => {
  const {
    error,
    filter,
    columns,
    table,
    togglePopup,
    findPatients,
    onPaginate,
    paramsState,
    handleFilterChange,
    handleCheckboxFilterChange,
    handleDateChange,
    handleResetFilter,
  } = PatientsExternalPresentLogic();

  return (
    <div className="patient-external">
      <div className="max-w-auto">
        <div className="myContent">
          {error.error && (
            <PopUp toggle={togglePopup} message={error.message} />
          )}
          <div className="container-fluid">
            <div className="col-xl-8 col-lg-12">
              <div className="row">
                <FilterCheckbox
                  value={filter.noCheckin}
                  name="noCheckin"
                  onChange={handleCheckboxFilterChange}
                  label="bez vstupní kontroly"
                />
                <FilterCheckbox
                  value={filter.hasCheckin}
                  name="hasCheckin"
                  onChange={handleCheckboxFilterChange}
                  label="po vstupní kontrole"
                />
                <FilterCheckbox
                  value={filter.require_attention}
                  name="require_attention"
                  onChange={handleCheckboxFilterChange}
                  label="vyžadující vyšší pozornost"
                />
                <FilterCheckbox
                  value={filter.riskLevelOne}
                  name="riskLevelOne"
                  onChange={handleCheckboxFilterChange}
                  label="s rizikovostí 1"
                />
                <FilterCheckbox
                  value={filter.riskLevelTwo}
                  name="riskLevelTwo"
                  onChange={handleCheckboxFilterChange}
                  label="s rizikovostí 2"
                />
                <FilterCheckbox
                  value={filter.riskLevelThree}
                  name="riskLevelThree"
                  onChange={handleCheckboxFilterChange}
                  label="s rizikovostí 3"
                />
                <FilterCheckbox
                  value={filter.riskLevelNone}
                  name="riskLevelNone"
                  onChange={handleCheckboxFilterChange}
                  label="bez rizikovostí"
                />
              </div>
            </div>
          </div>
          <div className="form-row-button" style={{ paddingBottom: 10 }}>
            <button type="submit" className="button" onClick={findPatients}>
              Vyhledat
            </button>
          </div>
          <div className="grid-row">
            <div className="container-fluid">
              {/* Results */}
              <div className="row">
                {table.loading ? (
                  <div className="col-12">
                    <h2>Načítání...</h2>
                  </div>
                ) : (
                  <>
                    <div className="col-12">
                      <Legend />
                    </div>
                    <div className="col-12">
                      <Table
                        data={table.data}
                        columns={columns}
                        filter={filter}
                        handleFilterChange={handleFilterChange}
                        handleDateChange={handleDateChange}
                        handleResetFilter={handleResetFilter}
                        onSubmit={findPatients}
                      />
                    </div>
                    <div className="col-12">
                      <Pagination
                        onSubmit={onPaginate}
                        pagination={table.pagination}
                        paramsState={paramsState}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterCheckbox = (props) => {
  const { value, name, onChange, label } = props;
  return (
    <div className="col-xl-4 col-md-6 col-sm-12 mb-2">
      <div
        style={{
          display: "flex",
          msFlexDirection: "row",
          justifyItems: "center",
        }}
      >
        <input
          className="styled-checkbox"
          id={name}
          type="checkbox"
          checked={value}
          onChange={() => onChange(name)}
        />
        <label className="ml-3" htmlFor={name}>
          {label}
        </label>
      </div>
    </div>
  );
};
