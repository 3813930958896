import React from "react";
import {
  Form,
  PopUp,
  TextInput,
  NumInput,
  DatePicker,
  CheckboxComp,
  CheckboxGroup,
  TextAreaInp,
  Radio,
} from "../../../components";
import { FormProvider, useForm } from "react-hook-form";
import { useSideEffect } from "../useSideEffect";
import { MainDrugTableInput } from "./MainDrugTableInput";
import { SecondaryDrugTableInput } from "./SecondaryDrugTableInput";
import { FaRegPlusSquare } from "react-icons/fa";

export const SideEffectForm = (props) => {
  const { requisition } = props;
  const {
    state,
    error,
    methods,
    drugsFields,
    simultaneousFields,
    edit,
    is_patient_died,
    saveData,
    updateData,
    togglePopup,
    handleAppendDrugs,
    handleAppendSimultaneous,
    drugsRemove,
    simultaneousRemove,
  } = useSideEffect({ requisition });

  return (
    <div className="max-w-2500 mt-3">
      <div className="myContent side-effect">
        {error.error && <PopUp toggle={togglePopup} message={error.message} />}
        {state.loading ? (
          <h1>Načítání formuláře...</h1>
        ) : (
          <FormProvider {...methods}>
            <Form>
              <div className="container">
                <h2>Informace o hlásícím</h2>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <TextInput
                      label="Titul před jménem"
                      id="specialist_prefix"
                      name="specialist_prefix"
                    />
                    <TextInput
                      label="Jméno hlásícího *"
                      id="specialist_first_name"
                      name="specialist_first_name"
                      error={
                        methods.formState.errors.specialist_first_name?.message
                      }
                    />
                    <TextInput
                      label="Email hlásícího *"
                      id="specialist_email"
                      name="specialist_email"
                      error={methods.formState.errors.specialist_email?.message}
                    />
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <TextInput
                      label="Titul za jménem"
                      id="specialist_postfix"
                      name="specialist_postfix"
                    />
                    <TextInput
                      label="Příjmení hlásícího *"
                      id="specialist_last_name"
                      name="specialist_last_name"
                      error={
                        methods.formState.errors.specialist_last_name?.message
                      }
                    />
                    <NumInput
                      label="Telefon hlásícího"
                      id="specialist_phone"
                      name="specialist_phone"
                    />
                  </div>
                </div>
                <h2>Informace o pacientovi a nežádoucím účinku</h2>
                <div className="row">
                  <div className="col-xl-6 col-md-12">
                    <TextInput
                      label="Jméno pacienta *"
                      id="patient_first_name"
                      name="patient_first_name"
                      error={
                        methods.formState.errors.patient_first_name?.message
                      }
                    />
                    <DatePicker
                      label="Datum narození *"
                      id="patient_birth_date"
                      name="patient_birth_date"
                      error={
                        methods.formState.errors.patient_birth_date?.message
                      }
                    />
                    <CheckboxComp
                      name="is_patient_died"
                      num={12}
                      containerClass="patient-form-checkbox mb-3 mt-3"
                      label="Pacient zemřel"
                    />
                    {is_patient_died && (
                      <DatePicker
                        id="patient_death_date"
                        name="patient_death_date"
                        label="Datum smrti"
                      />
                    )}
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <TextInput
                      label="Příjmení pacienta *"
                      id="patient_last_name"
                      name="patient_last_name"
                      error={
                        methods.formState.errors.patient_last_name?.message
                      }
                    />

                    <DatePicker
                      label="Nástup reakce *"
                      id="reaction_date"
                      name="reaction_date"
                      error={methods.formState.errors.reaction_date?.message}
                    />
                    <div className="form-check mb-2">
                      <Radio
                        id="patient_gender"
                        name="patient_gender"
                        value="M"
                        label="Muž"
                        radioClass="form-check-input"
                        labelClass="ml-2"
                      />
                    </div>

                    <div className="form-check">
                      <Radio
                        id="patient_gender"
                        name="patient_gender"
                        value="F"
                        label="Žena"
                        radioClass="form-check-input"
                        labelClass="ml-2"
                        error={methods.formState.errors.patient_gender?.message}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-xl-6 col-md-12">
                    <CheckboxComp
                      label="Došlo k ohrožení života"
                      name="has_life_threatening"
                      num={12}
                      containerClass="patient-form-checkbox mb-3"
                    />
                    <CheckboxComp
                      label="Nežádoucí účinek byl důvodem hospitalizace nebo jejího prodloužení"
                      name="is_hospitalization_reason"
                      num={12}
                      containerClass="patient-form-checkbox mb-3"
                    />
                    <CheckboxComp
                      label="Vznikly trvalé následky"
                      name="has_lasting_consequences"
                      num={12}
                      containerClass="patient-form-checkbox mb-3"
                    />
                  </div>
                  <div className="col-xl-6 col-md-12">
                    <CheckboxComp
                      label="Vrozená vada / perinatální poškození"
                      name="has_perinatal_damage"
                      num={12}
                      containerClass="patient-form-checkbox mb-3"
                    />
                    <CheckboxComp
                      label="Jiná lékařsky významná událost"
                      name="has_other_significant_event"
                      num={12}
                      containerClass="patient-form-checkbox mb-3"
                    />
                  </div>
                </div>

                {/* logic for hiddne unles the top checkbox is true */}
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <TextAreaInp
                      label="Popis nežádoucícho/cích účinku/ů"
                      id="adverse_effect_description"
                      name="adverse_effect_description"
                      rows={3}
                      containerClass="flex-column mb-4"
                      textAreaClass="inp-textarea mt-0"
                    />
                    <TextAreaInp
                      label="Výsledky souvisejících vyšetření (včetně dat provedení)"
                      id="examination_results"
                      name="examination_results"
                      rows={3}
                      containerClass="flex-column mb-4"
                      textAreaClass="inp-textarea mt-0"
                    />
                    <TextAreaInp
                      label="Další podstatné anamnestické údaje"
                      id="relevant_anamnesis"
                      name="relevant_anamnesis"
                      rows={3}
                      containerClass="flex-column mb-4"
                      textAreaClass="inp-textarea mt-0"
                    />
                  </div>
                </div>

                <h2>Informace o léčivu / léčivech</h2>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-flex justify-content-between">
                      <span className="strong mb-3">
                        Lék podezřelý z vyvolání
                      </span>
                      <FaRegPlusSquare
                        className="cursor-pointer"
                        onClick={() => handleAppendDrugs()}
                      />
                    </div>
                    <div className="tableInputContainer">
                      <MainDrugTableInput
                        fields={drugsFields}
                        append={handleAppendDrugs}
                        remove={drugsRemove}
                        error={methods.formState.errors.drugs}
                      />
                      {methods.formState.errors.drugs && (
                        <p
                          style={{
                            position: "absolute",
                            bottom: "-2px",
                            left: "12px",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {methods.formState.errors.drugs?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12 col-xl-8">
                    <div className="d-flex justify-content-between">
                      <span className="strong mb-3">Souběžná léčiva</span>{" "}
                      <FaRegPlusSquare
                        className="cursor-pointer"
                        onClick={() => handleAppendSimultaneous()}
                      />
                    </div>
                    <div className="tableInputContainer">
                      <SecondaryDrugTableInput
                        fields={simultaneousFields}
                        append={handleAppendSimultaneous}
                        remove={simultaneousRemove}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <TextAreaInp
                      label="Léčba nežádoucího  účinku"
                      id="adverse_effect_treatment"
                      name="adverse_effect_treatment"
                      rows={3}
                      containerClass="flex-column mb-4"
                      textAreaClass="inp-textarea mt-0"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <label
                      htmlFor="reaction_after_discontinuation"
                      className=""
                    >
                      Odezněla reakce po vysazení léčiva?
                    </label>
                    <div className="side-effect-last-form-part">
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_discontinuation"
                          name="reaction_after_discontinuation"
                          value="Y"
                          label="ANO"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_discontinuation"
                          name="reaction_after_discontinuation"
                          value="N"
                          label="NE"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_discontinuation"
                          name="reaction_after_discontinuation"
                          value="NA"
                          label="Neaplikovatelné"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 mt-4">
                    <label htmlFor="reaction_after_reintroduction" className="">
                      Objevila se reakce znovu po opětovném nasazení léčiva?
                    </label>
                    <div className="side-effect-last-form-part">
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_reintroduction"
                          name="reaction_after_reintroduction"
                          value="Y"
                          label="ANO"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_reintroduction"
                          name="reaction_after_reintroduction"
                          value="N"
                          label="NE"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                      <div className="form-check mb-2 mr-4">
                        <Radio
                          id="reaction_after_reintroduction"
                          name="reaction_after_reintroduction"
                          value="NA"
                          label="Neaplikovatelné"
                          radioClass="form-check-input"
                          labelClass="ml-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row-button">
                  <button
                    type="submit"
                    className="button"
                    onClick={methods.handleSubmit(edit ? updateData : saveData)}
                  >
                    Uložit
                  </button>
                </div>
                <div className="row">
                  <div className="col-12">
                    {state.message && (
                      <h2 className="text-success">{state.message}</h2>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};
