import React from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { PageNavigation, PatientsExternalPresent } from "../../components/";

export const PatientsExternal = () => {
  const { state } = useParams();
  return (
    <div>
      <h1>Soupis externích pacientů</h1>

      <PageNavigation className="sub-page-navigation present">
        <div>
          <NavLink
            to={`/patients-external/present/`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Aktuálně přijatí pacienti
          </NavLink>
        </div>
        {/* <div>
          <NavLink
            to={`/patients-external/past/#`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Propuštění pacienti
          </NavLink>
        </div> */}
      </PageNavigation>
      {state == "present" ? <PatientsExternalPresent /> : <div>Past</div>}
    </div>
  );
};
