import { authHeader } from "../auth/authHeader";
import axios from "axios";

const getPatientHistory = async (care, patient) => {
    const options = authHeader()
    const url = process.env.REACT_APP_ROOT_API 
    const requests = []
    requests.push(axios.get(`${url}/patients/${patient.id}/history/`, {...options}))
    
    // can have multiple api calls   
    if (care?.riskdrughistory) {
        requests.push(axios.get(`${url}/risk-drug-histories/${care.riskdrughistory.id}/history/`, {...options}))   
    }
    
    // can have multiple api calls   
    if (care?.checkin) {
        requests.push(axios.get(`${url}/checkins/${care.checkin.id}/history/`, {...options}))
    }

    //get pharma plan 
    if (care?.pharmacologicalplan) {
        requests.push(axios.get(`${url}/pharmacological-plans/${care.pharmacologicalplan.id}/history/`, {...options}))
    }

    //get ftd drugs evaluations 
    if (care?.pharmacological_evaluations.length){
        //map api calls to arr 
        care.pharmacological_evaluations.map (item => {
            let call = axios.get(`${url}/pharmacological-evaluations/${item.id}/history/`, {...options})
            requests.push(call)
        })
    }
    
    //get patient infromations
    if (care?.patient_informations.length > 0) {
        //map calls to arr 
        care.patient_informations.map (item => {
            let call = axios.get(`${url}/patient-informations/${item.id}/history/`, {...options})
            requests.push(call)
        })
    }
    
    const [...responses] = await axios.all(requests)
    
    return responses
}

const getInsuranceCompany = async (id) => {
    const options = authHeader();
    try {
        const response = await axios.get(process.env.REACT_APP_ROOT_API + `/insurances/${id}/`, { ...options });
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const historyServices = {
    getPatientHistory,
    getInsuranceCompany,
}