import React from 'react' 
import { FormProvider } from 'react-hook-form'
import { TextInput, Form, PopUp, Pencil, FloppyDisk } from '../../components'
import {TagLogic} from './TagLogic'


export const Tag = ({id, value, ...props}) => {
    const {state, error, methods, togglePopup, toggleTag, saveTag} = TagLogic (id, value)
    return (
        <>
        {error.error && <PopUp toggle={togglePopup} message={error.message} />}
        <div className='grid-column relative tag'>
            <FormProvider {...methods}>
                <Form>
                    <TextInput name="name" disabled={!state.active} id={id} {...props} />
                    <div className='icons'>
                        {!state.active ? <Pencil onClick={toggleTag} /> : null}
                        {state.active &&<FloppyDisk onClick={methods.handleSubmit(saveTag)} />}
                    </div>
                </Form>
            </FormProvider>
        </div>
        </>
    )
}