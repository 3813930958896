import React from 'react'
import { SwitchComp, TextInput, AsyncSelectArrComp } from '..'
import { PatientDrugsInpLogic } from './PatientDrugsInpLogic'

export const PatientDrugsInp = ({sectionName, drug, drugName, noteName, notes, title, disabled=false, ...props}) => {
    const {state, toggleSection} = PatientDrugsInpLogic (sectionName, drugName, noteName)

    return (
        <>
            <SwitchComp name={sectionName} label={title} classLabel='strong' inputGroup='input-group' toggleSection={toggleSection} disabled={disabled} />
        {drug && <AsyncSelectArrComp name={drugName} isDisabled={!state.active || disabled} displayBtns={disabled ? !disabled : state.active} endPoint='drugs' />}
        {notes && <TextInput name={noteName} id={noteName} label="Poznámka" disabled={!state.active || disabled} />}
        </>
        )
}

