import React, {useEffect, useState} from 'react' 
import { translateLabels } from '../../../helpers/translateLabels'
import { TagBlock } from '../..'
import { helpFunc } from '../../../helpers/helpFunction'
import { ContentContainer } from '../ContentContainer'
import { Toggle } from '../components/Toggle'

export const CareHistoryChangesLogic = (data) => {
    const [ changes, setChanges ] = useState ({loading: true, oldValues: null, newValues: null})
    const [ error, setError ] = useState ({error: false})

    useEffect (() => {
        getChanges (data)
    }, [])

    //MISSING 
    //DIAGNOSES 
    //PHARMA PLAN EVALUATION 
    //PHARMA PLAN RECOMMENDATIAON 
    //PHARMA PLAN RECOMMENDATION DISCHARGE
    //FORMATED TEXT

    //put data together according to inputs types I need 
    const getChanges = (data) => {
        setChanges ({...changes, loading: true})
        //map list old values
        const infoList = data.changes.map((item) => {
            //translate label from database variable to user friendly language
            let label = translateLabels(item.field, data.value_original)
            label = <label className='strong'>{label}:</label>

            //check for boolean values to return toggle component
            if (typeof item.new_value === 'boolean') {
                return typeof item.new_value !== 'object' && listToggles (label, item.old_value, item.new_value) 
            }

            //logic to decide what kind of format is needed for history data
            switch (item.field) {
                case 'tags': return listTags (label, item.old_value, item.new_value)

                case 'diagnoses': return listDiagnoses (label, item.old_value, item.new_value) 
                
                case 'drug': 
                    return listDrugs (label, [item.old_value], [item.new_value])
                case 'drugs': 
                case 'narrow_therapeutic_window_drugs': 
                case 'high_interaction_potential_drugs':
                    return listDrugs (label, item.old_value, item.new_value)

                case 'insurance_company': return listInsuranceCompany (label, item.old_value, item.new_value)

                case "notification_datetime":
                case "created_at": 
                    return listDates (label, item.old_value, item.new_value)
                
                    
                case 'height': return listHeight (label, item.old_value, item.new_value)
                
                case 'weight': return listWeight (label, item.old_value, item.new_value) 
                
                case 'author': return {}
                    
                case "verifications":
                case "recommendations":
                case "discharge_recommendations":
                    return listOtherPharmaPlanInformation (label, item.old_value, item.new_value)
                case 'texts':
                    return listPharmaPlanTexts (label, item.old_value, item.new_value)

                default: return listText (label, item.old_value, item.new_value) 
            }
        })

        
        //there is a list of promises which needs to be chain after the promises are resolved
        const oldValues = infoList.map ((item,i) => { return <ContentContainer key={i} label={item.old_value?.label} value={item.old_value?.value} old={true} />})
        const newValues = infoList.map ((item,i) => { return <ContentContainer key={i} label={item.new_value?.label} value={item.new_value?.value} />})

        setChanges (prev => {return {...prev, loading: false, oldValues: oldValues, newValues: newValues}})
    }

    //list all bolean values in toggle compoenent 
    const listToggles = (label, oldVal, newVal) => {
        oldVal = <Toggle disabled={true} checked={oldVal} onChange={true} /> 
        newVal = <Toggle disabled={true} checked={newVal} onChange={true} /> 
        
        return {
            old_value: {label: label, value: oldVal },
            new_value: {label: label, value: newVal }
        }
    }

    //listing all tags into blocks
    const listTags = (label, oldTags, newTags) => {
        let oldList = oldTags.length ? oldTags.map (tag => {return (<TagBlock key ={tag.id} title={tag.name} active={false} containerClass='m-bot-05'/>)}) : noData ('old')
        let newList = newTags.length ? newTags.map (tag => {return (<TagBlock key ={tag.id} title={tag.name} active={false} containerClass='m-bot-05'/>)}) : noData ('new')
        return {
            old_value: { label: label, value: oldList },
            new_value: { label: label, value: newList }}
    }
    
    //list all drgus into a text format 
    const listDrugs = (label, oldDrugs, newDrugs) => {
        let newList = newDrugs.length ? (<ul>{newDrugs.map ((drug,i) =>  { return <li key={i}>{drug.name} | {drug.name_supplement}</li> })}</ul>) : noData ('new')
        let oldList = oldDrugs.length ? (<ul>{oldDrugs.map ((drug,i) =>  { return <li key={i}>{drug.name} | {drug.name_supplement}</li> })}</ul>) : noData ('old')

        return {
            old_value: { label: label, value: oldList },
            new_value: { label: label, value: newList }}
    }

    const listInsuranceCompany = (label, oldVal, newVal) => {
        oldVal = oldVal.length ? oldVal : noData ('old')
        newVal = newVal.length ? newVal : noData ('new')
        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal}
        }
    }

    const listDates = (label, oldVal, newVal) => {
        oldVal = oldVal ? helpFunc.formatDate(oldVal) : noData('old')
        newVal = newVal ? helpFunc.formatDate(newVal) : noData('new')

        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal}
        }
    }

    //list all drgus into a text format 
    const listDiagnoses = (label, oldDiagnose, newDiagnose) => {
        let oldList = oldDiagnose.length ? (<ul>{oldDiagnose.map ((diagnose) =>  { 
            return (
                <li>
                    <span className='strong'>{diagnose.diagnosis.name}</span>
                    <ul>
                        {diagnose.drugs.map ((drug, i) => {
                            return <li>{drug.name} | {drug.name_supplement}</li>
                        })}
                    </ul>
                </li>
            )})}</ul>)
            : noData ('old')
        let newList = newDiagnose.length ? (<ul>{newDiagnose.map ((diagnose) =>  { 
            return (
                <li>
                    <span className='strong'>{diagnose.diagnosis.name}:</span>
                    <ul>
                        {diagnose.drugs.map ((drug, i) => {
                            return <li>{drug.name} | {drug.name_supplement}</li>
                        })}
                    </ul>
                </li>
            )})}</ul>)
            : noData ('new')
        return {
            old_value: { label: label, value: oldList },
            new_value: { label: label, value: newList }}
    }

    //list patient height 
    const listHeight = (label, oldVal, newVal) => {
        oldVal = oldVal ? `${oldVal} cm` : noData ('old')
        newVal = newVal ? `${newVal} cm` : noData ('new')
        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal}
        }
    }

    //list patient weight 
    const listWeight = (label, oldVal, newVal) => {
        oldVal = oldVal ? `${oldVal} kg` : noData ('old')
        newVal = newVal ? `${newVal} kg` : noData ('new')

        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal}
        }
    }

    //list pharmaplan verifications, recommendations, recommendation_discharge
    const listOtherPharmaPlanInformation = (label, oldVal, newVal) => {
        oldVal = oldVal.length ? (<ul>{oldVal.map((item,i) => {
            return (
                <li><span className='strong'>{helpFunc.formatDateWithTime(item.updated_at)}: </span><div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(item.text)} /></li>
            )
        })} </ul>) : noData ('old')

        newVal = newVal.length ? (<ul>{newVal.map((item,i) => {
            return (
                <li><span className='strong'>{helpFunc.formatDateWithTime(item.updated_at)}: </span><div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(item.text)} /></li>
            )
        })} </ul>) : noData ('new')
        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal},
        }
    }

    //list pharmacological plan texts as array
    const listPharmaPlanTexts = (label, oldVal, newVal) => {
        oldVal = oldVal.length ? oldVal.map(item => { return <div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(item.text)} />}) : noData ('old')
        newVal = newVal.length ? newVal.map(item => { return <div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(item.text)} />}) : noData ('new')
        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal},
        }
    }

    //list text values 
    const listText = (label, oldVal, newVal) => {
        oldVal = oldVal ? <div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(oldVal)} /> : noData ('old')
        newVal = newVal ? <div dangerouslySetInnerHTML={helpFunc.createHTMLMarkup(newVal)} /> : noData ('new')
        return {
            old_value: {label: label, value: oldVal},
            new_value: {label: label, value: newVal}
        }
    }

    //list patient weight 
    const listFormatedText = (label, oldVal, newVal) => {
        return {
            old_value: {label: label, value: helpFunc.createHTMLMarkup(oldVal)},
            new_value: {label: label, value: helpFunc.createHTMLMarkup(newVal)}
        }
    }

    //return message when there are no new or old information
    const noData = (type="") => {
        return type ==='new' ? <p>Žádné nové záznamy</p> : <p>Žádné záznamy v historii</p>
    }

    return {changes, error}
}