import React from "react";
import { Login, Footer } from "../../components";

export const Signin = (props) => {    
        return (
            <div>
                <Login history={props.history} />
                <Footer />
            </div>
        )
}
