import React, {useContext, Fragment} from 'react' 
import { TableContext } from './PatietnHistoryTable'
import { CareHistoryChanges } from '../CareHistoryChanges/CareHistoryChanges'

export const PatientHistoryBody = ({id, ...props}) => {
    const {getTableBodyProps, rows, prepareRow, visibleColumns} = useContext(TableContext)

    return (
        <tbody {...getTableBodyProps()}> 
            {rows.map((row) => {
                prepareRow(row)
                return (
                    <Fragment key={row.index}>
                        <tr {...row.getRowProps()} key={row.id}>
                            {row.cells.map (cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        { row.isExpanded || row.original.name === "" ? (
                            <tr>
                            <td colSpan={visibleColumns.length} key={row.id}>
                                <CareHistoryChanges data={row.original} row={row} />
                            </td>
                        </tr>
                        ) : null} 
                    </Fragment>
                )
            })}
        </tbody>
    )
}   