import React from "react";
import { CheckinPharmaPlanLogic } from "./CheckinPharmaPlanLogic";
import { CheckinPharmaPlanItem } from "./CheckinPharmaPlanItem";
export const CheckinPharmaPlan = ({
  checkin = null,
  careIsLocked = false,
  hidePharmaPlanItem = true,
  ...props
}) => {
  const { patientRiskLevel, editCheckin } = CheckinPharmaPlanLogic(checkin);

  return (
    <div className="grid-container-column pharma-plan-checkin">
      <div className={`grid-row bg-color-${patientRiskLevel}`}>
        <span className="header">Výsledky vstupní kontroly</span>
        {!careIsLocked && (
          <button className="button" onClick={editCheckin}>
            Editovat
          </button>
        )}
      </div>

      {checkin ? (
        <>
          {checkin?.polypharmacy && (
            <CheckinPharmaPlanItem
              title="Polypragmazie"
              text={checkin.polypharmacy_note}
              drugs={checkin.drugs}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.narrow_therapeutic_window && (
            <CheckinPharmaPlanItem
              title="Léčivo s úzkým terapeutickým oknem"
              text={checkin.narrow_therapeutic_window_note}
              drugs={checkin.narrow_therapeutic_window_drugs}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.high_interaction_potential && (
            <CheckinPharmaPlanItem
              title="Vysoký interakční potenciál"
              text={checkin.high_interaction_potential_note}
              drugs={checkin.high_interaction_potential_drugs}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.systemic_corticosteroids && (
            <CheckinPharmaPlanItem
              title="Systémové kortikoidy nebo jiná imunosupresiva"
              text={checkin.systemic_corticosteroids_note}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.toggle_diagnoses && (
            <CheckinPharmaPlanItem
              title="Riziková diagnóza"
              text={checkin.diagnoses_note}
              diagnoses={checkin.diagnoses}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.renal_insufficiency && (
            <CheckinPharmaPlanItem
              title="Renální insuficience"
              text={checkin.renal_insufficiency_note}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.hepatic_insufficiency && (
            <CheckinPharmaPlanItem
              title="Hepatální insuficience"
              text={checkin.hepatic_insufficiency_note}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.significant_biochemical_changes && (
            <CheckinPharmaPlanItem
              title="Další významné změny biochemických / hematologických parametrů"
              text={checkin.significant_biochemical_changes_note}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.intensive_care && (
            <CheckinPharmaPlanItem
              title="Intenzivní péče"
              text={checkin.intensive_care_note}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.pharmacist_intervention_required && (
            <CheckinPharmaPlanItem
              title="Nutný zásah KF"
              bool={checkin.pharmacist_intervention_required}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}

          {checkin.consultation_requested && (
            <CheckinPharmaPlanItem
              title="Vyžádané konzilium"
              bool={checkin.consultation_requested}
              hidePharmaPlanItem={hidePharmaPlanItem}
            />
          )}
        </>
      ) : (
        <div className="grid-row">
          <h2>Pacient nemá vstupní kontrolu</h2>
        </div>
      )}
    </div>
  );
};
