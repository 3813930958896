import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cs } from "date-fns/esm/locale";
import { FaCalendarAlt } from "react-icons/fa";

export const DatePickerPlain = ({
  name,
  id,
  options,
  defaultValue = "",
  isDisabled,
  isClearable = true,
  error = undefined,
  ...props
}) => {
  const methods = useFormContext();
  registerLocale("cz", cs);

  return (
    <div className="date-picker-container">
      <Controller
        control={methods.control}
        name={name}
        defaultValue={defaultValue}
        {...props}
        render={({ field }) => (
          <ReactDatePicker
            {...field}
            dateFormat="dd/MM/yyyy"
            calendarStartDay={1}
            className="inp-datepicker"
            placeholderText="dd/mm/rrrr"
            onChange={(e) => field.onChange(e)}
            selected={field.value}
            disabled={isDisabled}
            locale="cz"
            isClearable={isClearable}
          />
        )}
      />
      <FaCalendarAlt />
      <p
        style={{
          position: "relative",
          bottom: "-2px",
          left: "0",
          fontSize: "12px",
          color: "red",
        }}
      >
        {error?.message}
      </p>
    </div>
  );
};
