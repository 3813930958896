import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const TextAreaFormInp = ({
  id,
  label,
  labelClass,
  name,
  required = false,
  placeholder,
  txtInpClass = "inp-textarea",
  containerClass = "input-group",
  disabled = false,
  ...props
}) => {
  const methods = useFormContext();
  useEffect(() => {
    methods.register(name, { required: required });
  }, [name]);

  const onEdit = (editorState) => {
    methods.setValue(name, editorState);
  };

  const value = methods.watch(name);

  const styles = {
    minHeight: "300px",
  };

  return (
    <div className={containerClass}>
      {label && (
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      )}
      <ReactQuill
        theme="snow"
        formats={["font", "bold", "italic", "underline", "link"]}
        value={value}
        onChange={onEdit}
        placeholder="Text..."
        readOnly={disabled}
        style={styles}
        {...props}
      />
    </div>
  );
};
