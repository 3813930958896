import { useState, useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { patientService } from "../../services/patient.services";
import { helpFunc } from "../../helpers/helpFunction";
import { PAGINATION_PER_PAGE_LIMIT } from "../../helpers/constants";
import { BiSortAlt2 } from "react-icons/bi";

export const PatientSearchLogic = () => {
  const [state, setState] = useState("");
  const [error, setError] = useState({ error: false });
  const [patients, setPatients] = useState({
    loading: false,
    data: false,
    pagination: { count: 0 },
  });
  const [tags, setTags] = useState({ loading: true });
  const [paramsState, setParamsState] = useState({
    ordering: "",
    page: 1,
    offset: 0,
    limit: PAGINATION_PER_PAGE_LIMIT,
  });

  //load tags on component mount
  useEffect(() => {
    getTags();
  }, []);

  const handleTableSort = (value) => {
    handleSort(value);
  };

  //Load tags options from db
  const getTags = async () => {
    try {
      setTags({ ...tags, loading: true });
      let data = await patientService.getTags();
      data = await helpFunc.pDataForSelect(data.results);
      setTags({ ...tags, loading: false, data: data });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání štítků." });
    }
  };

  //FORM SECTION
  const methods = useForm({
    defaultValues: {
      external_id: "",
      birth_number__contains: "",
      tag: null,
      last_name__startswith: "",
      first_name__startswith: "",
    },
  });

  //Patient search
  const data = useWatch({
    control: methods.control,
    name: [
      "external_id",
      "birth_number__contains",
      "tag",
      "last_name__startswith",
      "first_name__startswith",
    ],
    defaultValue: [],
  });
  let timer;

  useEffect(() => {
    if (data.length > 0) getPatients(data);
  }, [data]);

  const getPatients = (val) => {
    setPatients({ loading: true, data: false });
    let data = methods.getValues();
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      if (data.external_id.length > 0) {
        try {
          let result = await patientService.getPatientsFilter({
            page: 1,
            offset: 0,
            limit: PAGINATION_PER_PAGE_LIMIT,
            external_id: data.external_id,
          });
          const pagination = { count: result.count };
          setParamsState({
            ...data,
            page: result.page,
            offset: result.offset,
            ordering: "",
            limit: PAGINATION_PER_PAGE_LIMIT,
          });
          result = await pTableData(result.results);
          setPatients({ loading: false, data: result, pagination });
        } catch (error) {
          setError(error);
        }
      } else {
        //conver data to key/valeu arr
        if (data.tag !== null) data = { ...data, tag: data?.tag?.value };
        const arr = Object.entries(data);
        const filter = arr.filter(
          ([key, val]) => val !== null && (val.length >= 3 || key === "tag")
        );
        data = Object.fromEntries(filter);

        if (Object.keys(data).length !== 0) {
          try {
            let result = await patientService.getPatientsFilter({
              ...data,
              page: 1,
              offset: 0,
              limit: PAGINATION_PER_PAGE_LIMIT,
            });
            const pagination = { count: result.count };
            setParamsState({
              ...data,
              page: result.page,
              offset: result.offset,
              ordering: "",
              limit: PAGINATION_PER_PAGE_LIMIT,
            });
            result = await pTableData(result.results);
            setPatients({ loading: false, data: result, pagination });
          } catch (error) {
            riseError(error);
          }
        }
      }
    }, 2000);
    timer = newTimer;
  };

  const onPaginate = async (offset, page) => {
    try {
      setPatients({ loading: true, data: false });
      let result = await patientService.getPatientsFilter({
        ...paramsState,
        offset,
        page,
      });
      const pagination = {
        count: result.count,
      };
      setParamsState({
        ...paramsState,
        page: result.page,
        offset: result.offset,
      });
      result = await pTableData(result.results);
      setPatients({ loading: false, data: result, pagination });
    } catch (error) {
      riseError(error);
    }
  };

  const handleSort = async (value) => {
    const orderingValue = paramsState.ordering.includes("-")
      ? value
      : `-${value}`;
    try {
      setPatients({ loading: true, data: false });
      let result = await patientService.getPatientsFilter({
        ...paramsState,
        ordering: orderingValue,
      });
      const pagination = {
        count: result.count,
      };
      setParamsState({
        ...paramsState,
        page: result.page,
        offset: result.offset,
        ordering: orderingValue,
      });
      result = await pTableData(result.results);
      setPatients({ loading: false, data: result, pagination });
    } catch (error) {
      riseError(error);
    }
  };

  //Parse patiens data for result table
  const pTableData = async (data) => {
    return data.map((patient) => {
      return {
        ...patient,
        id: patient?.id,
        external_id: patient?.external_id,
        age: helpFunc.countPatientAge(
          patient.age_display,
          patient.age_display_measure_unit
        ),
        diagnoses: patient?.current_care?.main_diagnosis?.name,
        days_in_hospital: helpFunc.countDaysInHospital(
          patient?.current_care?.started_at
        ),
        last_dekurz:
          patient?.current_care?.last_dekurz !== null
            ? helpFunc.formatDate(patient?.current_care?.last_dekurz?.made_at)
            : "Chybí data",
        doctor_name:
          patient?.current_care?.last_dekurz !== null
            ? `${patient?.current_care?.last_dekurz?.doctor?.f_title} ${patient?.current_care?.last_dekurz?.doctor?.name} ${patient?.current_care?.last_dekurz?.doctor?.l_title}`
            : patient?.current_care?.doctor,
        department:
          patient?.current_care?.department !== null
            ? patient?.current_care?.department?.description
            : "Chybí data",
      };
    });
  };

  const riseError = (error) => {
    console.error(error);
    setError({
      error: true,
      message: "Vyskytla se chyba při vyhledávání pacienta",
    });
  };

  const togglePopup = () => {
    setError({ error: false });
  };

  const columns = useMemo(() => [
    {
      Header: () => (
        <>
          <span>ID pacienta</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("external_id")}
            />
          </span>
        </>
      ),
      accessor: "external_id",
    },
    {
      Header: () => (
        <>
          <span>Příjmení a jméno</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("name")}
            />
          </span>
        </>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <>
          <span>RČ</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("birth_number")}
            />
          </span>
        </>
      ),
      accessor: "birth_number",
    },
    {
      Header: () => (
        <>
          <span>Věk</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("age_display")}
            />
          </span>
        </>
      ),
      accessor: "age",
    },
    {
      Header: () => (
        <>
          <span>Jméno lékaře</span>
          <span>
            <BiSortAlt2
              style={{ cursor: "pointer" }}
              onClick={() => handleTableSort("current_care_doctor_name")}
            />
          </span>
        </>
      ),
      accessor: "doctor_name",
    },
    {
      Header: () => null,
      id: "expander",
      Cell: ({ row }) => (
        <>
          <button
            type="button"
            className="button"
            {...row.getToggleRowExpandedProps()}
          >
            Info o pacientovi
          </button>
        </>
      ),
    },
  ]);

  return {
    state,
    error,
    patients,
    tags,
    methods,
    onPaginate,
    getPatients,
    togglePopup,
    paramsState,
    columns,
  };
};
