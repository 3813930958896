import React from "react";
import { useFormContext } from "react-hook-form";

export const TextInput = ({
  id,
  label,
  error = undefined,
  labelClass,
  labelDisplay = true,
  name,
  placeholder = "",
  type = "text",
  disabled = false,
  ...props
}) => {
  const methods = useFormContext();

  return (
    <div className="input-group">
      {labelDisplay && (
        <label htmlFor={id} className={labelClass}>
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        className="inp-text"
        {...methods.register(name)}
        placeholder={placeholder}
        {...props}
        disabled={disabled}
      />
      {error && (
        <p
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "0px",
            fontSize: "12px",
            color: "red",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};
