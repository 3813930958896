import React from 'react' 
import { CareHistoryChangesLogic } from './CareHistoryChangesLogic'

//diagnoses drugs from RLA and checkin


export const CareHistoryChanges = ({data}) => {
    const { changes, error } = CareHistoryChangesLogic (data)

    return (
        <>
            {error.error && <div>{error.message}</div>}
            <div className='flex-container-row history-changes'>
                <div className='flex-container-column'>
                    <h4 className='m-bot-1'>Původní záznamy:</h4>
                    {!changes.loading && changes.oldValues}
                </div>
                <div className='flex-container-column'>
                    <h4 className='m-bot-1'>Nové záznamy:</h4>
                    {!changes.loading && changes.newValues}
                </div>
            </div>
        </>
    )
}