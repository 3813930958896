import React, { createContext } from 'react' 
import { useExpanded, useFilters, useTable } from 'react-table'
import { TableBody } from './PatientExternalPresentTableBody'
import { TableHead } from './PatientExternalPresentTableHead'

export const TableContext = createContext({})

export const Table = ({ columns, data, filters={}, checkFilter, id, ...props}) => {
    
    const tableInstance = useTable({columns, data,
        initialState: {
            hiddenColumns: ['status'],
        }, 
    }, useFilters, useExpanded)
    const { getTableProps } = tableInstance 

    return (
        <TableContext.Provider value={{ ...tableInstance}}>
            <table className='myTable' {...getTableProps()} > 
                <TableHead /> 
                <TableBody id={id} {...props} />
            </table>
        </TableContext.Provider>
    )
}