import React, { createContext } from "react";
import { useExpanded, useFilters, useTable } from "react-table";
import { TableBody } from "./PatientHospitalPresentTableBody";
import { TableHead } from "./PatientHospitalPresentTableHead";

export const TableContext = createContext({});

export const Table = ({
  columns,
  data,
  filters = {},
  checkFilter,
  id,
  ...props
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["status"],
      },
    },
    useFilters,
    useExpanded
  );
  const { getTableProps } = tableInstance;

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="myTable" {...getTableProps()}>
        <TableHead {...props} />
        <TableBody id={id} {...props} />
      </table>
    </TableContext.Provider>
  );
};
