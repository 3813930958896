import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { PopUpPharmaPlan } from "../../components";
import { helpFunc } from "../../helpers/helpFunction";

export const PatientRequestsLogic = (
  plan = null,
  checkin = null,
  patient = null,
  care = null,
  careIsLocked = false
) => {
  const [error, setError] = useState({ error: false, popUp: false });
  const [table, setTable] = useState({ loading: true, data: false });
  const [popUp, setPopUp] = useState({ hide: true, popUp: null });
  const [procedures, setProcedures] = useState({ reload: false });

  const { id } = useParams();

  const history = useHistory();
  localStorage.setItem("planId", plan?.id);

  //LOADING DATA ON COMPONENT MOUNT
  useEffect(() => {
    getPatientData(patient, care);
  }, []);
  //get patient risk level for stripe color under the table and checkin heading background
  const getPatientRiskLevel = (checkin = {}) => {
    switch (checkin?.risk_level) {
      case "1":
        return "blue";
      case "2":
        return "orange";
      case "3":
        return "red";
      case "none":
        return "none";
    }
  };
  const patientRiskLevel = getPatientRiskLevel(checkin);

  // Get data from patient and parse to for use in toggeling table
  const getPatientData = async (patient, care) => {
    if (patient) {
      try {
        setTable({ ...table, loading: true });
        let data = await helpFunc.pPatientData(patient);
        data = {
          ...data,
          main_diagnosis: care?.main_diagnosis?.name || "Žádná",
          last_dekurz:
            helpFunc.formatDate(care?.last_dekurz?.made_at) || "Žádné datum",
          doctor:
            helpFunc.pDoctorName(care?.last_dekurz?.doctor) ||
            "Chybí jméno lékaře",
          clinic: care?.clinic?.abbreviation || "Chybí data o klinice",
          department:
            helpFunc.getPatientDepartment(patient) || "Chybí data o oddělení",
          ambulant_doctor: care?.ambulant_doctor
            ? `${care?.ambulant_doctor.f_title} ${care?.ambulant_doctor.name} ${care?.ambulant_doctor.l_title}`
            : "Žádné informace",
        };
        setTable({ ...table, loading: false, data: [data] });
      } catch (e) {
        console.log(e);
        raiseError("Chyba při načítání informací o pacientovi.");
      }
    } else {
      setTable({ ...table, loading: false, data: null });
    }
  };

  //HANDELING COMMENTS ERROR POPUP
  const togglePopUp = () => {
    resetError();
  };

  //raise error with message
  const raiseError = (message) => {
    setError({ error: true, message: message });
  };

  //resetError state
  const resetError = () => {
    setError({ error: false, message: "" });
  };

  //TABLE SECTION
  const columns = useMemo(() => [
    {
      Header: "Informace o pacientovi ",
      columns: [
        {
          Header: "Příjmení a jméno pacienta",
          accessor: "name",
        },
        {
          Header: "Datum narození",
          accessor: "birth_date",
        },
        {
          Header: "Diagnóza",
          accessor: "main_diagnosis",
        },
        {
          Header: "Hmotnost",
          accessor: "weight",
        },
        {
          Header: "Výška",
          accessor: "height",
        },
        {
          Header: "BMI",
          accessor: "bmi",
        },
        {
          Header: "Rodné číslo",
          accessor: "birth_number",
        },
        {
          Header: "ID pacienta",
          accessor: care.care_type !== "external" ? "external_id" : "id",
        },
        {
          Header: "Dnů hospitalizace (k dnešnímu dni)",
          accessor: "days",
          show: care.care_type === "hospitalization",
        },
        {
          Header:
            care?.care_type === "hospitalization"
              ? "Datum posledního dekurzu"
              : "Datum návštěvy ambulance",
          accessor:
            care?.care_type === "hospitalization"
              ? "last_dekurz"
              : "first_visit",
        },
        {
          Header:
            care?.care_type === "hospitalization"
              ? "Ošetřující lékař"
              : "Jméno lékaře (žadatele)",
          accessor:
            care?.care_type === "external"
              ? "external_doctor"
              : care?.care_type === "ambulation"
              ? "ambulant_doctor"
              : "doctor",
        },
        {
          Header:
            care?.care_type !== "external"
              ? "Klinika"
              : "Zdravotnické zařízení",
          accessor: "clinic",
        },
        {
          Header: "Oddělení",
          accessor: "department",
        },
      ],
    },
  ]);

  const handleBackButton = () => {
    history.push(
      `/patients-hospital/${care?.clinic?.id}/present/hospitalization`
    );
  };

  const hidePopUp = () => {
    setPopUp({ hide: true, popUp: null });
  };

  return {
    error,
    table,
    popUp,
    procedures,
    patientRiskLevel,
    columns,
    togglePopUp,
    handleBackButton,
  };
};
