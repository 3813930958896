import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { helpFunc } from "../../../helpers/helpFunction";

export const PatientHospitalPastTableLogic = (row) => {
  const [state, setState] = useState({ loading: true, moreInfo: false });
  const [error, setError] = useState("");
  const [patient, setPatient] = useState("");
  const [care, setCare] = useState({ loading: true });
  const patientData = row.original;
  const history = useHistory();

  useEffect(() => {
    getPatient(patientData);
  }, []);

  //Get patient info
  const getPatient = async (patientData) => {
    let data = await pPatientData(patientData);
    let cares = await listCareData(patientData.cares, patientData.id);

    setPatient(data);
    setCare({ ...care, loading: false, list: cares });
    setState({ ...state, loading: false });
  };

  //Parse patient data
  const pPatientData = async (data) => {
    const birth_date = helpFunc.formatDate(data.birth_date);
    const bmi = helpFunc.countBmi(data.weight, data.height);

    return { ...data, bmi: bmi, birth_date: birth_date };
  };

  //List all care data
  //Hospital table -> Only active cares
  //Patient search -> All cares
  //Released patients -> Only unactive cares
  const listCareData = async (cares, patientID) => {
    return cares.map((care) => (
      <div key={care.id}>
        <span>Péče od: {helpFunc.formatDate(care.started_at)}</span>
        <br />
        <span>
          Péče do:{" "}
          {care.finished_at !== null
            ? helpFunc.formatDate(care.finished_at)
            : ""}
        </span>
        <br />
        {care.is_locked && <span>Uzamčena</span>} <br />
        <button className="button" onClick={() => openCare(care, patientID)}>
          Otevřít pacienta
        </button>
      </div>
    ));
  };

  const openCare = (care, patientID) => {
    let location;
    if (care?.checkin) {
      location = {
        pathname: `/patient-profile/${patientID}/care/${care.id}/pharmacological-plan`,
        state: { pastPatients: true },
      };
    } else {
      location = {
        pathname: `/patient-entry-control/${patientID}/care/${care.id}/`,
        state: { pastPatients: true },
      };
    }
    history.push(location);
  };

  const showMoreInfo = () => {
    setState({ ...state, moreInfo: !state.moreInfo });
  };

  return { patient, error, state, showMoreInfo, care };
};
