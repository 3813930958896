import { Pagination } from "../Pagination";
import { PopUp } from "../PopUps/PopUp";
import { AllPatientsListLogic } from "./AllPatientsListLogic";
import { AllPatientsListTable } from "./AllPatientsListTable";

export const AllPatientsList = (props) => {
  const { careType } = props;

  const {
    columns,
    data,
    loading,
    params,
    filter,
    error,
    togglePopup,
    getAllPatients,
    handleFilterChange,
    handleResetFilter,
    handleDateChange,
  } = AllPatientsListLogic({ careType });
  return (
    <div className="requisitions">
      <div className="max-w-auto">
        <div className="myContent">
          {loading ? (
            <h2>Načítání...</h2>
          ) : (
            <>
              {error.error && (
                <PopUp toggle={togglePopup} message={error.message} />
              )}
              <AllPatientsListTable
                data={data.data}
                columns={columns[careType]}
                filter={filter}
                handleFilterChange={handleFilterChange}
                handleDateChange={handleDateChange}
                handleResetFilter={handleResetFilter}
                onSubmit={getAllPatients}
                careType={careType}
              />

              <Pagination
                onSubmit={getAllPatients}
                pagination={data.pagination}
                paramsState={params}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
