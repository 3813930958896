import { useState, useEffect } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { helpFunc } from "../../helpers/helpFunction";
import { patientService } from "../../services/patient.services";
import { validation } from "../../helpers/validation_schemes/patientValidation";

const drugsSchema = {
  drug: yup
    .object()
    .shape({
      label: yup
        .string(validation.string)
        .required(validation.required)
        .nullable(),
      value: yup
        .string(validation.string)
        .required(validation.required)
        .nullable(),
    })
    .required(validation.required)
    .nullable(),
  dosage: yup.string().required(validation.required),
  strength: yup.string().required(validation.required),
  application_from: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  application_to: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  batch_number: yup.string().required(validation.required),
  application_method: yup.string().required(validation.required),
  application_indication: yup.string().required(validation.required),
};

const schema = yup.object().shape({
  specialist_first_name: yup
    .string(validation.string)
    .required(validation.required),
  patient_last_name: yup
    .string(validation.string)
    .required(validation.required),
  specialist_email: yup.string(validation.string).required(validation.required),
  patient_first_name: yup
    .string(validation.string)
    .required(validation.required),
  patient_last_name: yup
    .string(validation.string)
    .required(validation.required),
  patient_birth_date: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  patient_gender: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  reaction_date: yup
    .string(validation.string)
    .required(validation.required)
    .nullable(),
  drugs: yup.array().of(yup.object().shape(drugsSchema)).min(1, "kokoko"),
});

export const useSideEffect = (props) => {
  const { requisition } = props;
  const [state, setState] = useState({ loading: false, message: false });
  const [error, setError] = useState({ error: false });
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getSideEffect();
  }, [requisition.id]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      patient_first_name: requisition.care.patient.first_name,
      patient_last_name: requisition.care.patient.last_name,
      patient_birth_date: new Date(requisition.care.patient.birth_date),
      patient_gender: requisition.care.patient.gender,
    },
  });
  const control = methods.control;

  const {
    fields: drugsFields,
    append: drugsAppend,
    remove: drugsRemove,
    insert: drugsInsert,
  } = useFieldArray({
    control,
    name: "drugs",
  });

  const {
    fields: simultaneousFields,
    append: simultaneousAppend,
    remove: simultaneousRemove,
    insert: simultaneousInsert,
  } = useFieldArray({
    control,
    name: "simultaneous",
  });

  const handleAppendDrugs = () => {
    drugsAppend({
      drug: "",
      dosage: "",
      strength: "",
      application_from: "",
      application_to: "",
      batch_number: "",
      application_method: "",
      application_indication: "",
    });
  };

  const handleAppendSimultaneous = () => {
    simultaneousAppend({
      drug: "",
      dosage: "",
      strength: "",
    });
  };

  const is_patient_died = useWatch({
    control: control,
    name: "is_patient_died",
  });

  useEffect(() => {
    if (!is_patient_died) {
      methods.setValue("patient_death_date", null);
    }
  }, [is_patient_died]);

  const saveData = async (data) => {
    if (
      birthDateAndDeathDateValidation(
        data.patient_birth_date,
        data.patient_death_date
      )
    ) {
      setError({
        error: true,
        message: "Datum smrti nesmí být dříve než datum narození",
      });
      return;
    }

    if (!drugsDateValidation(data.drugs)) {
      setError({
        error: true,
        message: "Datum podávání do nesmí být dříve než datum podávání od",
      });
      return;
    }

    const { formatData, formatDrugs, formatSimultaneous } =
      dataFormatterToAPI(data);

    try {
      const response = await patientService.postRequisitionsAdverseEffect(
        formatData,
        requisition.id
      );

      if (response.status !== 201) {
        setError({
          error: true,
          message: "Nahlášení nežádoucího účinku se nepodařilo uložit",
        });
        return;
      }

      if (formatDrugs.length !== 0) {
        await saveRequsitionsAdverseEffectDrugs(formatDrugs);
      }

      if (formatSimultaneous.length !== 0) {
        await saveRequisitionsAdverseEffectSimultaneous(formatSimultaneous);
      }

      setState({
        loading: false,
        message: "Nahlášení nežádoucího účinku bylo uloženo",
      });
      methods.reset();
    } catch (error) {
      console.error(error);
      setState({
        loading: false,
        message: "",
      });
      if (error.response.data.drug) {
        setError({
          error: true,
          message: "Název léčiva je povinní pole.",
        });
        return;
      }
      setError({
        error: true,
        message: "Nahlášení nežádoucího účinku se nepodařilo uložit",
      });
    }
  };

  const saveRequsitionsAdverseEffectDrugs = async (data) => {
    const response = await Promise.all(
      data.map(
        async (item) =>
          await patientService.postRequisitionsAdverseEffectDrugs(
            item,
            requisition.id
          )
      )
    );
  };

  const saveRequisitionsAdverseEffectSimultaneous = async (data) => {
    const response = await Promise.all(
      data.map(
        async (item) =>
          await patientService.postRequisitionsAdverseEffectSimultaneous(
            item,
            requisition.id
          )
      )
    );
  };

  const updateData = async (data) => {
    if (
      birthDateAndDeathDateValidation(
        data.patient_birth_date,
        data.patient_death_date
      )
    ) {
      setError({
        error: true,
        message: "Datum smrti nesmí být dříve než datum narození",
      });
      return;
    }

    if (!drugsDateValidation(data.drugs)) {
      setError({
        error: true,
        message: "Datum Podávání do nesmí být dříve než datum Podávání od",
      });
      return;
    }

    const { formatData, formatDrugs, formatSimultaneous } =
      dataFormatterToAPI(data);
    try {
      const response = await patientService.patchRequisitionsAdverseEffect(
        formatData,
        requisition.id
      );

      if (response.status !== 200) {
        setError({
          error: true,
          message: "Nahlášení nežádoucího účinku se nepodařilo uložit",
        });
        return;
      }

      if (formatDrugs.length !== 0) {
        await patchRequsitionsAdverseEffectDrugs(formatDrugs);
      }

      if (formatSimultaneous.length !== 0) {
        await patchRequisitionsAdverseEffectSimultaneous(formatSimultaneous);
      }

      setState({
        loading: false,
        message: "Nahlášení nežádoucího účinku bylo uloženo",
      });
    } catch (error) {
      console.error(error.response);
      setState({
        loading: false,
        message: "",
      });
      if (error.response.data.drug) {
        setError({
          error: true,
          message: "Název léčiva je povinní pole.",
        });
        return;
      }
      setError({
        error: true,
        message: "Nahlášení nežádoucího účinku se nepodařilo uložit",
      });
    }
  };

  const patchRequsitionsAdverseEffectDrugs = async (data) => {
    await Promise.all(
      data.map(async (item) =>
        item.id
          ? await patientService.patchRequisitionsAdverseEffectDrugs(
              item,
              requisition.id
            )
          : await patientService.postRequisitionsAdverseEffectDrugs(
              item,
              requisition.id
            )
      )
    );
  };

  const patchRequisitionsAdverseEffectSimultaneous = async (data) => {
    await Promise.all(
      data.map(async (item) =>
        item.id
          ? await patientService.patchRequisitionsAdverseEffectSimultaneous(
              item,
              requisition.id
            )
          : await patientService.postRequisitionsAdverseEffectSimultaneous(
              item,
              requisition.id
            )
      )
    );
  };

  const getSideEffect = async () => {
    const response = await patientService.getRequisitionsAdverseEffect(
      requisition.id
    );

    if (response.status === 200) {
      const data = await dataFormatterFromAPI(response.data);
      setFormValues(data);
      setEdit(true);
    }
  };

  const setFormValues = (data) => {
    methods.setValue("specialist_prefix", data.specialist_prefix);
    methods.setValue("specialist_first_name", data.specialist_first_name);
    methods.setValue("specialist_email", data.specialist_email);
    methods.setValue("specialist_postfix", data.specialist_postfix);
    methods.setValue("specialist_last_name", data.specialist_last_name);
    methods.setValue("patient_death_date", data.patient_death_date);
    methods.setValue("specialist_phone", data.specialist_phone);
    methods.setValue("patient_first_name", data.patient_first_name);
    methods.setValue("patient_birth_date", data.patient_birth_date);
    methods.setValue("is_patient_died", data.is_patient_died);
    methods.setValue("patient_last_name", data.patient_last_name);
    methods.setValue("reaction_date", data.reaction_date);
    methods.setValue("patient_gender", data.patient_gender);
    methods.setValue("has_life_threatening", data.has_life_threatening);
    methods.setValue(
      "is_hospitalization_reason",
      data.is_hospitalization_reason
    );
    methods.setValue("has_lasting_consequences", data.has_lasting_consequences);
    methods.setValue("has_perinatal_damage", data.has_perinatal_damage);
    methods.setValue(
      "has_other_significant_event",
      data.has_other_significant_event
    );
    methods.setValue(
      "adverse_effect_description",
      data.adverse_effect_description
    );
    methods.setValue("examination_results", data.examination_results);
    methods.setValue("relevant_anamnesis", data.relevant_anamnesis);
    methods.setValue("adverse_effect_treatment", data.adverse_effect_treatment);
    methods.setValue(
      "reaction_after_discontinuation",
      data.reaction_after_discontinuation
    );

    methods.setValue(
      "reaction_after_reintroduction",
      data.reaction_after_reintroduction
    );

    if (data.drugs.length !== 0) {
      data.drugs.map(async (item, index) => {
        const drug = await patientService.getDrug(item.drug);

        drugsAppend({
          id: item.id,
          drug: { value: drug.id, label: drug.name },
          dosage: item.dosage,
          strength: item.strength,
          application_from: new Date(item.application_from),
          application_to: new Date(item.application_to),
          batch_number: item.batch_number,
          application_method: item.application_method,
          application_indication: item.application_indication,
        });
      });
    }

    if (data.simultaneous_drugs.length !== 0) {
      data.simultaneous_drugs.map(async (item, index) => {
        const drug = await patientService.getDrug(item.drug);

        simultaneousAppend({
          id: item.id,
          drug: { value: drug.id, label: drug.name },
          dosage: item.dosage,
          strength: item.strength,
        });
      });
    }
  };

  const togglePopup = () => {
    setError({ error: false });
  };

  return {
    state,
    error,
    methods,
    drugsFields,
    simultaneousFields,
    edit,
    is_patient_died,
    saveData,
    updateData,
    togglePopup,
    handleAppendDrugs,
    handleAppendSimultaneous,
    drugsRemove,
    simultaneousRemove,
  };
};

const dataFormatterToAPI = (data) => {
  console.log("data", data);
  const formatDrugs = data.drugs.map((item) => ({
    ...item,
    drug: item.drug.value,
    application_from: helpFunc.parseDate(item.application_from),
    application_to: helpFunc.parseDate(item.application_to),
  }));

  const formatSimultaneousFiltered = data.simultaneous.filter(
    (item) => item.drug.value !== undefined
  );

  const formatSimultaneous = formatSimultaneousFiltered.map((item) => ({
    ...item,
    drug: item.drug.value,
  }));

  const formatData = {
    ...data,
    patient_birth_date: helpFunc.parseDate(data.patient_birth_date),
    patient_death_date: helpFunc.parseDate(data.patient_death_date),
    reaction_date: helpFunc.parseDate(data.reaction_date),
  };

  delete formatData.drugs;
  delete formatData.simultaneous;

  return { formatData, formatDrugs, formatSimultaneous };
};

const dataFormatterFromAPI = async (data) => {
  const formatData = {
    ...data,
    patient_birth_date: new Date(data.patient_birth_date),
    reaction_date: new Date(data.reaction_date),
    patient_death_date: new Date(data.patient_death_date),
  };

  return formatData;
};

const birthDateAndDeathDateValidation = (birthDate, deathDate) => {
  if (!deathDate) return false;
  const bDate = new Date(birthDate).getTime();
  const dDate = new Date(deathDate).getTime();

  return bDate > dDate;
};

const drugsDateValidation = (drugs) => {
  let validation = true;
  drugs.forEach((item) => {
    let applicationFrom = new Date(item.application_from).getTime();
    let applicationTo = new Date(item.application_to);
    if (applicationFrom > applicationTo) validation = false;
  });

  return validation;
};
