import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Form, TextInput, DatePicker, ControlSelect } from "../../components";
import { FormProvider } from "react-hook-form";
import { ReportsLogic } from "./ReportsLogic";

export const Reports = () => {
  const {
    state,
    error,
    methods,
    clinics,
    departments,
    reports,
    filters,
    formState,
    generateReport,
  } = ReportsLogic();

  return (
    <FormProvider {...methods}>
      <Form>
        {reports.loading ? (
          <h2>Načítání...</h2>
        ) : (
          <div className="form-row">
            <ControlSelect
              label="VYBERTE REPORT:"
              labelClass={"strong"}
              options={reports.options}
              name="report"
            />
          </div>
        )}
        {formState.timeRange && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Časové období"
                name="time_range"
                options={filters.time_range}
              />
            </div>
          </div>
        )}
        {formState.customDate && (
          <div className="form-row">
            <div className="form-column">
              <DatePicker label="Časové období od:" name="date_from" />
            </div>
            <div className="form-column">
              <DatePicker label="Časové období do:" name="date_to" />
            </div>
          </div>
        )}
        {formState.year && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect label="Rok:" name="year" options={filters.years} />
            </div>
          </div>
        )}
        {formState.month && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Měsíc:"
                name="month"
                options={filters.months}
              />
            </div>
          </div>
        )}
        {formState.clinic && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Klinika:"
                name="clinic"
                options={clinics.options}
              />
            </div>
          </div>
        )}
        {formState.department && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Oddělení:"
                name="department"
                options={departments.options}
              />
            </div>
          </div>
        )}
        {formState.hasNote && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Pacienti:"
                name="has_note"
                options={filters.hasNote}
              />
            </div>
          </div>
        )}
        {formState.actGroupExact && (
          <div className="form-row">
            <div className="form-column">
              <TextInput label="ACT Skupina:" name="atc_group_exact" />
            </div>
          </div>
        )}
        {formState.actGroupStart && (
          <div className="form-row">
            <div className="form-column">
              <TextInput label="ACT Skupina:" name="atc_group_startswith" />
            </div>
          </div>
        )}
        {formState.careType && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Pacienti:"
                name="care_type"
                options={filters.careType}
                required
              />
            </div>
          </div>
        )}
        {formState.careState && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Péče:"
                name="care_state"
                options={filters.careState}
                required
              />
            </div>
          </div>
        )}
        {formState.format && (
          <div className="form-row">
            <div className="form-column">
              <ControlSelect
                label="Formát:"
                name="format"
                options={filters.formats}
                required
              />
            </div>
          </div>
        )}
        <div className="form-row-button">
          <button
            className="button"
            onClick={methods.handleSubmit(generateReport)}
          >
            Generovat report
          </button>
          <div>
            <ClipLoader loading={state.submit} color="#02385D" size={35} />
          </div>
          {state.generated && (
            <div>
              <span className="strong">Report je vygenerovaný</span>
            </div>
          )}
          {error.error && (
            <div>
              <span className="strong">{error.message}</span>
            </div>
          )}
        </div>
      </Form>
    </FormProvider>
  );
};
