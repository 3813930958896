import React from "react";
import { CareHistoryLogic } from "./CareHistoryLogic";
import { FaRegCaretSquareDown, FaRegCaretSquareUp } from "react-icons/fa";
import { PatientHistoryTable } from "../HistoryTable/PatietnHistoryTable";
import { PatientHistoryFilters } from "../PatientHistoryFilters";

export const CareHistory = ({ careData = null, patient = null }) => {
  const { filters, historyData, columns, methods, toggleCare } =
    CareHistoryLogic(careData, patient);

  return (
    <>
      <div className="care-history-container">
        <div className="care-history-title">
          <span>
            Historie péče od {historyData.from}{" "}
            {historyData.to && `do ${historyData.to}`}
          </span>
        </div>
        <div>
          {historyData.hide ? (
            <FaRegCaretSquareDown onClick={toggleCare} />
          ) : (
            <FaRegCaretSquareUp onClick={toggleCare} />
          )}
        </div>
      </div>
      <div className={`care-history-content ${historyData.hide && "hidden"}`}>
        {!filters.loading && (
          <PatientHistoryFilters
            users={filters.users}
            values={filters.values}
            methods={methods}
          />
        )}
        <div className="grid-row">
          <div className="grid-column">
            {/* Table with patient history data */}
            {historyData.loading ? (
              <h1>Načítání...</h1>
            ) : (
              <PatientHistoryTable
                data={historyData.data}
                columns={columns}
                filters={filters}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
