import {useState, useEffect} from 'react'
import { helpFunc } from '../../helpers/helpFunction'
import { patientService } from '../../services/patient.services'

export const CareProceduresLogic = (careId, displayCareProcedures, reload=false) => {
    const [procedures, setProcedures] = useState ({loading: true})
    const [error, setError ] = useState("")    

    useEffect (() => {
        if (displayCareProcedures) {
            getProcedures (careId)
        }
    },[])

    useEffect (() => {
        if (reload) {
            getProcedures (careId)
        }
    }, [reload])

    const getProcedures = async (careId) => {
        try {
            let data = await patientService.getProcedures(careId)
            data = await parseProcedures(data)
            setProcedures({...procedures, loading: false, data: data})
        } catch (error) {
            console.error(error)
            setError ({error: true, message:"Chyba načítání výkazů."})
        }
    }

    const parseProcedures = (data) => {
        return {
            ...data, 
            procedure_05751_count: helpFunc.pNumToStr(data.procedure_05751_count),
            procedure_05753_count: helpFunc.pNumToStr(data.procedure_05753_count),
            procedure_05755_count: helpFunc.pNumToStr(data.procedure_05755_count),
            unreported_05755_count: helpFunc.pNumToStr(data.unreported_05755_count),
        }
    }

    return {procedures, error}
}