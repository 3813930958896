import React from "react";
import { CheckinPharmaPlanItemLogic } from "./CheckinPharmaPlanItemLogic";
import { FaRegCaretSquareDown, FaRegCaretSquareUp } from "react-icons/fa";

export const CheckinPharmaPlanItem = ({
  title,
  hidePharmaPlanItem = true,
  text = null,
  diagnoses = null,
  drugs = null,
  bool = null,
  riskLevel = null,
  ...props
}) => {
  const { data, hide, toggleMoreInfo } = CheckinPharmaPlanItemLogic(
    text,
    diagnoses,
    drugs,
    bool,
    riskLevel,
    hidePharmaPlanItem
  );

  return (
    <>
      <div className="grid-row">
        <span className="strong">{title}</span>
        <div className="checkin-more-info">
          {hide ? (
            <FaRegCaretSquareDown onClick={toggleMoreInfo} />
          ) : (
            <FaRegCaretSquareUp onClick={toggleMoreInfo} />
          )}
        </div>
      </div>
      {!hide && (
        <div className="grid-row checkin-info">
          <div>{data.diagnoses}</div>
          <div>{data.drugs}</div>
          <div>{data.text}</div>
          <div>{data.bool}</div>
          <div>{data.riskLevel}</div>
        </div>
      )}
    </>
  );
};
