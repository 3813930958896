export const PredefinedTextItem = ({ text, handleRemove, handleExpand }) => {
  const onExpand = () => {
    handleExpand(text);
  };

  return (
    <div className="predefeined-text-wrapper">
      <div className="predefeined-text-item">
        <label onClick={() => onExpand(text)}>
          {text.name} | {text.is_own ? "Vlastní" : "Obecné"}
        </label>

        <i className="fas fa-times" onClick={() => handleRemove(text.id)}></i>
      </div>
    </div>
  );
};
