import { FormProvider } from "react-hook-form";
import { IoMdAlert } from "react-icons/io";
import { ControlSelect } from "../Form/ControlSelect";
import { Form } from "../Form/Form";
import { TextAreaFormInp } from "../Form/TextAreaFormInp";
import { PageNavigation } from "../PageNavigation/PageNavigation";
import { SideEffectForm } from "../SideEffect/components/SideEffectForm";
import { PatientRequestsListLogic } from "./PatientRequestListLogic";
import { Button } from "../Form/Button";

export const PatientRequestsList = (props) => {
  const {
    requisitions,
    disableCommentSection = false,
    getPatientData = () => {},
    careId,
    careType,
    category,
  } = props;

  if (requisitions.length === 0) {
    return null;
  }

  const disableCategory = careType === "hospitalization";

  const {
    request,
    methods,
    options,
    showSideEffectForm,
    message,
    tab,
    setTab,
    handleSubmit,
    handleSetRequest,
    onChangeState,
  } = PatientRequestsListLogic(requisitions, category, careId, getPatientData);

  return (
    <div id="patient-request">
      <div className="request-picker">
        <span>ŽÁDANKA Č.:</span>{" "}
        {requisitions.map((item, key) => (
          <div
            key={item.id}
            onClick={() => handleSetRequest(item)}
            style={
              item.id === request.id
                ? { color: "#0164a7" }
                : { color: "#b0b0b6" }
            }
          >
            {key + 1}{" "}
            {item.state === "pending" && (
              <IoMdAlert style={{ color: "#A63A49", fontSize: 16 }} />
            )}
          </div>
        ))}
      </div>
      <FormProvider {...methods}>
        <Form>
          <div className="patient-requests-list-wrapper">
            <div className="row m-0">
              <div className="col-6 p-0">
                <div className="col-12 header">ID žádanky</div>
                <div className="col-12 title">Žadatel</div>
                <div className="col-12 title">Typ žádanky</div>

                {!disableCategory && (
                  <div className="col-12 title">Kategorie</div>
                )}
                <div className="col-12 title">Příloha</div>
              </div>
              <div className="col-6 p-0">
                <div className="col-12 header">{request.external_id}</div>
                <div className="col-12 content">{request.applicant.name}</div>
                <div className="col-12 content">
                  <ControlSelect
                    name="subtype"
                    options={options.subtype}
                    containerClass="input"
                    isClearable={false}
                  />
                </div>

                {!disableCategory && (
                  <div className="col-12 content">
                    <ControlSelect
                      name="category"
                      options={options.category}
                      containerClass="input"
                      isClearable={false}
                      isDisabled={disableCategory}
                    />
                  </div>
                )}

                <div className="col-12 content">
                  {request.file_link ? (
                    <a
                      href={request.file_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Zobrazit přílohu k žádance
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="col-12 pb-3 full">
                <div className="mb-3 mt-3">
                  <b>Text žádosti</b>
                </div>{" "}
                <p>
                  <div dangerouslySetInnerHTML={{ __html: request.text }}></div>
                </p>
              </div>
            </div>
            {!disableCommentSection && (
              <div className="request-button-section">
                <button
                  type="button"
                  className="button mr-2"
                  style={{ height: 40 }}
                  onClick={methods.handleSubmit(handleSubmit)}
                >
                  Uložit
                </button>
                {request.state === "refused" ? (
                  <button
                    type="button"
                    className="button btn-light"
                    style={{ height: 40 }}
                    onClick={() => onChangeState("pending")}
                  >
                    Přijmout žádanku
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button btn-light"
                    style={{ height: 40 }}
                    onClick={() => onChangeState("refused")}
                  >
                    Odmítnout žádanku
                  </button>
                )}
              </div>
            )}
            {disableCommentSection && (
              <>
                {message && (
                  <div className="mt-4">
                    <div className={`alert alert-${message.type}`}>
                      {message.text}
                    </div>
                  </div>
                )}
                <div className="row m-0">
                  <div className="col-12 mt-2">
                    <button
                      type="button"
                      className="button patient-info-table-button"
                      onClick={methods.handleSubmit(handleSubmit)}
                    >
                      Uložit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          {!disableCommentSection && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <PageNavigation className="sub-page-navigation present">
                  {showSideEffectForm && (
                    <div>
                      <a
                        onClick={() => setTab("side-effect")}
                        className={tab === "side-effect" ? "active" : " "}
                      >
                        Nahlášení nežádoucích účinků
                      </a>
                    </div>
                  )}
                  <div>
                    <a
                      onClick={() => setTab("reaction")}
                      className={tab === "reaction" ? "active" : " "}
                    >
                      Reakce na žádanku
                    </a>
                  </div>
                </PageNavigation>
              </div>
              {tab === "reaction" && (
                <>
                  <div className="form-row text-inp">
                    <TextAreaFormInp
                      name="answer"
                      rows={5}
                      columns={15}
                      maxlenght={5000}
                      containerClass="pharmacological-text-inp"
                    />
                  </div>
                  {message && (
                    <div className="form-row">
                      <div className={`alert alert-${message.type}`}>
                        {message.text}
                      </div>
                    </div>
                  )}
                  <div className="form-row-button">
                    <button
                      type="button"
                      className="button patient-info-table-button"
                      onClick={methods.handleSubmit(handleSubmit)}
                    >
                      Uložit
                    </button>
                  </div>
                </>
              )}
              {tab === "side-effect" && (
                <SideEffectForm requisition={request} />
              )}
            </>
          )}
        </Form>
      </FormProvider>
    </div>
  );
};
