import React, { useContext } from "react";
import { TableContext } from "./PatientExternalPresentTable";

export const TableHead = () => {
  const { headerGroups } = useContext(TableContext);

  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>
              {column.render("Header")}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
