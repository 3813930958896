import React from 'react' 
import { Patient } from '../../components'

export const PatientEdit = ({...props}) => {
    return (
        <div>
            <h1>Editace pacienta</h1>
            <Patient edit={true} />
        </div>
    )
}