import React from "react";
import { FormProvider } from "react-hook-form";
import {
  Table,
  PatientInfo,
  Form,
  TextAreaInp,
  ControlSelect,
  AsyncSelectArrComp,
  RiskMedicalAnamnesisLogic,
  Checkin,
  Diagnoses,
  PopUp,
  SwitchComp,
} from "../../components";
import { FaRegCaretSquareDown } from "react-icons/fa";

export const RiskMedicalAnamnesis = ({
  patient,
  riskDrugHistory,
  careIsLocked = false,
  careID,
  checkin,
  displayAddBtns = true,
  ...props
}) => {
  const {
    state,
    columns,
    table,
    error,
    formMethods,
    tags,
    hasRiskDiagnosis,
    commentsMethods,
    comments,
    saveData,
    saveComment,
    editForm,
    showCheckin,
    togglePopUp,
    clearForm,
  } = RiskMedicalAnamnesisLogic(riskDrugHistory, checkin, careID, careIsLocked);

  return (
    <>
      <div className="patient-profile">
        <PatientInfo patientData={patient} careID={careID} />
        {error.popUp && <PopUp message={error.message} toggle={togglePopUp} />}
        {/* Simple table */}

        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              {table.loading ? (
                <h1>Loading table...</h1>
              ) : table.data ? (
                <>
                  <Table data={table.data} columns={columns} />
                  <div className="iconMoreInfo">
                    <FaRegCaretSquareDown onClick={showCheckin} />
                  </div>
                </>
              ) : (
                <h2>Pacient nemá vstupní kontrolu</h2>
              )}
            </div>
          </div>
          {state.checkinInfo && (
            <>
              <div className="grid-row">
                <div className="grid-column patient-checkin-table">
                  <Checkin
                    displayAll={false}
                    toggle={showCheckin}
                    checkin={checkin}
                    disabled={true}
                    careIsLocked={careIsLocked}
                  />
                </div>
              </div>
            </>
          )}
          <div className="grid-row">
            <div className="grid-column">
              {state.loading ? (
                <h1>Loading form...</h1>
              ) : (
                <FormProvider {...formMethods}>
                  <Form className="myForm">
                    <Diagnoses
                      sectionName="has_risk_diagnosis"
                      diagnoseName="diagnoses"
                      drugName="drugs"
                      labelClass="strong"
                      fieldArrayLabel="strong"
                      disabled={!state.formActive}
                      title="Riziková léková anamnéza"
                      note={false}
                      clearForm={clearForm}
                      toggle={true}
                      deactivateFunc={true}
                    />
                    <AsyncSelectArrComp
                      sectionName="has_risk_diagnosis"
                      name="drugs"
                      endPoint="drugs"
                      active={!hasRiskDiagnosis}
                      label="Léčivo"
                      displayBtns={state.formActive}
                      labelClass="strong"
                    />
                    <ControlSelect
                      sectionName="has_risk_diagnosis"
                      labelClass={"strong"}
                      label="Štítky"
                      options={tags.data}
                      name="tags"
                      isMulti={true}
                      isDisabled={!hasRiskDiagnosis}
                    />
                    <TextAreaInp
                      sectionName="has_risk_diagnosis"
                      labelClass={"strong"}
                      id="note"
                      label="Poznámky"
                      row={5}
                      columns={15}
                      name="note"
                      maxlenght={5000}
                      disabled={!hasRiskDiagnosis}
                    />
                    <div className="form-row-button">
                      {!careIsLocked ? (
                        state.formActive ? (
                          <button
                            type="submit"
                            className="button"
                            onClick={formMethods.handleSubmit(saveData)}
                          >
                            Uložit
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="button"
                            onClick={editForm}
                          >
                            Editovat
                          </button>
                        )
                      ) : null}
                    </div>
                  </Form>
                </FormProvider>
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column">
              {state.submit && (
                <span className="strong">Data se ukládají...</span>
              )}
              {state.dataSaved && (
                <span className="strong">Data jsou uložena.</span>
              )}
            </div>
          </div>
          {state.edit ? (
            comments.loading ? (
              <h1>Loading comments...</h1>
            ) : (
              <>
                <div className="grid-row">
                  <div className="grid-column">
                    {!careIsLocked && (
                      <FormProvider {...commentsMethods}>
                        <Form className="myForm">
                          <div className="grid-row">
                            <div className="grid-column">
                              <TextAreaInp
                                labelClass={"strong"}
                                id="comment"
                                label="Komentář"
                                name="text"
                                row={5}
                                columns={15}
                                maxlenght={500}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-row-button">
                            <button
                              type="submit"
                              className="button"
                              onClick={commentsMethods.handleSubmit(
                                saveComment
                              )}
                            >
                              Uložit
                            </button>
                          </div>
                        </Form>
                      </FormProvider>
                    )}
                  </div>
                </div>
                <div className="grid-row comments">
                  <div className="grid-column comments">{comments.list}</div>
                </div>
              </>
            )
          ) : null}
        </div>
      </div>
    </>
  );
};
