import { useState, useEffect } from "react";

export const TextField = (props) => {
  const { filter, onChange, onSubmit, field, type = "text" } = props;

  const equal = field;
  const contains = `${field}__contains`;
  const startswith = `${field}__startswith`;
  const endswith = `${field}__endswith`;

  const opt = [
    { value: contains, label: "Obsahuje" },
    { value: startswith, label: "Začíná na" },
    { value: endswith, label: "Končí na" },
    { value: equal, label: "Rovná se" },
  ];

  const [option, setOption] = useState({
    value: contains,
    label: "Obsahuje",
  });
  const [value, setValue] = useState();
  const [options, setOptions] = useState(opt);

  useEffect(() => {
    if (filter[equal]) {
      setValue(filter[equal]);
      setOption(opt.find((item) => item.value === equal));
    } else if (filter[startswith]) {
      setValue(filter[startswith]);
      setOption(opt.find((item) => item.value === startswith));
    } else if (filter[endswith]) {
      setValue(filter[endswith]);
      setOption(opt.find((item) => item.value === endswith));
    } else if (filter[contains]) {
      setValue(filter[contains]);
      setOption(opt.find((item) => item.value === contains));
    } else {
      setValue("");
      setOption(option);
    }
  }, [filter]);

  useEffect(() => {
    if (filter[equal]) {
      setOption(opt.find((item) => item.value === equal));
    } else if (filter[startswith]) {
      setOption(opt.find((item) => item.value === startswith));
    } else if (filter[endswith]) {
      setOption(opt.find((item) => item.value === endswith));
    } else if (filter[contains]) {
      setOption(opt.find((item) => item.value === contains));
    } else {
      setOption(option);
    }
  }, []);

  const handleChange = (event) => {
    const option = options.find((item) => item.value === event.target.value);
    setOption(option);
    const oldEvent = {
      target: { value: value ? value : "", name: option.value },
    };
    onChange(oldEvent);
  };

  const onKeyPress = (e) => {
    // Enter
    if (e.which === 13) {
      onSubmit();
    }
  };

  return (
    <td role="cell" className="td-filter">
      <select
        className="table-filter-select"
        name="select"
        onChange={handleChange}
      >
        <option value={option.value}>{option.label}</option>
        {options.map((item, key) => (
          <option
            className={item.value === option.value ? "hidden" : ""}
            key={key}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
      <input
        type={type}
        name={option.value}
        className="table-filter-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </td>
  );
};
