import React, { useState, useEffect } from "react";

export const CheckinPharmaPlanItemLogic = (
  text = null,
  diagnoses = null,
  drugs = null,
  bool = null,
  riskLevel = null,
  hidePharmaPlanItem = true
) => {
  const [data, setData] = useState({
    diagnoses: false,
    drugs: false,
    text: false,
    bool: false,
    riskLevel: false,
  });
  const [hide, setHide] = useState(hidePharmaPlanItem);

  const toggleMoreInfo = () => {
    setHide(!hide);
  };

  useEffect(() => {
    pData(text, diagnoses, drugs, bool, riskLevel);
  }, []);

  //Parse data from checkin section to dispaly as a text or list
  const pData = (
    text = null,
    diagnoses = null,
    drugs = null,
    bool = null,
    riskLevel = null
  ) => {
    let note = text ? <p>{text}</p> : false;
    let drugsList = drugs ? pDrugs(drugs) : false;
    let diagnosesList = diagnoses ? pDiagnoses(diagnoses) : false;
    let risk = riskLevel ? <p>Výsledná rizikovost {riskLevel}</p> : false;
    let boolRes = bool != null ? (bool ? "Ano" : "Ne") : null;
    setData({
      diagnoses: diagnosesList,
      drugs: drugsList,
      text: note,
      bool: boolRes,
      riskLevel: risk,
    });
  };

  //Parse drugs to unordered list
  const pDrugs = (drugs) => {
    return (
      <ul>
        {drugs.map((drug, i) => {
          return (
            <li key={i}>
              {drug.name} | {drug.name_supplement}
            </li>
          );
        })}
      </ul>
    );
  };

  //Parse diagnoses to diagnoses title and drug list
  const pDiagnoses = (diagnoses) => {
    let list = diagnoses.map((diagnose, i) => {
      return (
        <div key={i}>
          <span className="strong">{diagnose.diagnosis.name}</span>
          <ul>
            {diagnose.drugs.map((drug, k) => {
              return (
                <li key={k}>
                  {drug.name} | {drug.name_supplement}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
    return list;
  };

  return { data, hide, toggleMoreInfo };
};
