import React, { createContext } from "react";
import { useExpanded, useFilters, useTable } from "react-table";
import { AllPatientsListTableBody } from "./AllPatientsListTableBody";
import { AllPatientsListTableHead } from "./AllPatientsListTableHead";

export const TableContext = createContext({});

export const AllPatientsListTable = ({
  columns,
  data,
  filters = {},
  checkFilter,
  id,
  ...props
}) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ["status"] },
    },
    useFilters,
    useExpanded
  );
  const { getTableProps } = tableInstance;

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="myTable" {...getTableProps()}>
        <AllPatientsListTableHead />
        <AllPatientsListTableBody id={id} {...props} />
      </table>
    </TableContext.Provider>
  );
};
