import React, { useContext, Fragment } from "react";
import { PatientInfoTable } from "..";
import { TableContext } from "./PatientExternalPresentTable";
import {
  DateFieldFilter,
  NumberFieldFilter,
  TextFieldFilter,
} from "./TableFilter";

export const TableBody = ({ id, ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);
  const getRowProps = (row) => {
    if (row.original.status === "noCheckin") {
      return { style: { background: "#454552", color: "#ffffff" } };
    } else if (row.original.require_attention) {
      return { style: { background: "#0164A7", color: "#ffffff" } };
    } else if (row.original.status === "none") {
      return { style: { background: "#0164A7", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelOne") {
      return { style: { background: "#279112", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelTwo") {
      return { style: { background: "#ECA100", color: "#ffffff" } };
    } else if (row.original.status === "riskLevelThree") {
      return { style: { background: "#A63A49", color: "#ffffff" } };
    }
  };

  // hight attention 0164a7
  // plasmatic concentration 7030a0
  // nezadouci ucinnek f00001

  return (
    <tbody {...getTableBodyProps()}>
      <tr role="row">
        <TextFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="external_id"
        />
        <TextFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="name"
        />
        <TextFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="birth_number"
        />
        <NumberFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="age_display"
        />
        <TextFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="current_care_department_description"
        />
        <TextFieldFilter
          onChange={props.handleFilterChange}
          onSubmit={props.onSubmit}
          filter={props.filter}
          field="current_care_doctor_name"
        />
        <DateFieldFilter
          onChange={props.handleDateChange}
          filter={props.filter}
          field="current_care_started_at"
        />
        <td>
          <button className="button" onClick={props.handleResetFilter}>
            Resetovat filtr
          </button>
        </td>
      </tr>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
            {row.isExpanded ? (
              <tr>
                <td colSpan={visibleColumns.length}>
                  <PatientInfoTable id={row.original.id} row={row} />
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};
