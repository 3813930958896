import React from "react";
import { NavLink } from "react-router-dom";
import { AmbulantPatientsLogic } from "./AmbulantPatientsLogic";

export const AmbulantPatients = ({ ...props }) => {
  const { state, error, noCheckInCount, myAmbulantList } =
    AmbulantPatientsLogic();

  return (
    <div>
      <h1>Ambulantní pacienti - rozcestník</h1>
      {error.error && <h2 className="message">{error.message}</h2>}
      {state.loading ? (
        <h2 className="message">Nahrávání ambulancí...</h2>
      ) : (
        <div className="signpost-wrap">
          <div className="signpost">{myAmbulantList}</div>
          <div className="signpost single">
            <div className="item">
              <NavLink to="all-clinics/ambulation">
                <span className="icon">
                  <i className="fas fa-clinic-medical"></i>
                  <u>{noCheckInCount}</u>
                </span>
                <span className="name">Všechny ambulance</span>
              </NavLink>
            </div>
          </div>
        </div>
      )}
      <div className="signpost-wrap menu">
        <div className="signpost ">
          <div className="item">
            <NavLink to="patients-external/present">
              <span className="icon">
                <i className="fa fa-clipboard"></i>
              </span>
              <span className="name">
                Soupis externích
                <br /> pacientů
              </span>
            </NavLink>
          </div>
          <div className="item">
            <NavLink to="patient-new">
              <span className="icon">
                <i className="fas fa-search"></i>
              </span>
              <span className="name">
                Přidání nového
                <br /> pacienta
              </span>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="signpost-wrap search">
        <div className="signpost">
          <div className="item">
            <NavLink to="patient-lookup">
              <span className="icon">
                <i className="fas fa-search"></i>
              </span>
              <span className="name">
                Hledání konkrétního
                <br /> pacienta
              </span>
            </NavLink>
          </div>
          <div className="item">
            <NavLink to="/requisitions/ambulation">
              <span className="icon">
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </span>
              <span className="name">
                Nezpracované
                <br /> žádanky
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
