import React, {useState, useEffect} from 'react' 
import { useFormContext, Controller } from 'react-hook-form'
import Switch  from 'react-switch'

export const SwitchComp = ({name, label, title, disabled = false, num = 0, classLabel, inputGroup, toggleSection=null, ...props}) => {
    const methods = useFormContext()
    const [ active, setActive ] = useState(false)

    const switchStyle = {
        offColor: '#cdd2d6',
        onColor: '#06c102',
        uncheckedIcon: false, 
        checkedIcon: false, 
        activeBoxShadow: '0 0 2px 3px #06c102',
        height: 24,
        width: 36, 
        handleDiameter: 18
        }
        
        useEffect (() => {
            const getToggleValue = () => {
                setActive (methods.getValues(name))
            }
            getToggleValue ()
        },[])
        

    const onChange = (field, checked) => {

        field.onChange(checked)
        setActive(checked)
        if (toggleSection != null) {
            toggleSection(active)
        }
        methods.setValue(name, checked)
    }


    return (
        <>
        <div className={`grid-column col-${num} ${inputGroup}`} > 
            <Controller control={methods.control} name={name} render={({ field }) => (
                <Switch {...field} disabled={disabled} checked={active} onChange={(checked) => {onChange(field, checked)}} {...switchStyle} {...props} />  
                )} />
            <input type="checkbox" {...methods.register(name)} hidden checked={active} />
            <span className={`${classLabel} formSubTitle`}>{label}</span>
        </div>
        </>

    )
}