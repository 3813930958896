import React from "react";
import { NavLink } from "react-router-dom";
import { PatientsHospitalLogic } from "./PatientsHospitalLogic";
import {
  PageNavigation,
  PatientHospitalPresent,
  PatientHospitalPast,
} from "../../components";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export const PatientsHospital = ({ match, ...props }) => {
  const { id, state, careType } = useParams();
  const { clinic, error } = PatientsHospitalLogic(parseInt(id), careType);

  return (
    <div>
      {clinic.loading ? (
        <h2>Načítání...</h2>
      ) : state == "present" ? (
        <h1>
          {clinic.data.description} /{" "}
          {careType === "hospitalization"
            ? "Soupis hospitalizovaných pacientů"
            : "Soupis ambulantních pacientů"}
        </h1>
      ) : state == "past" ? (
        <h1>{clinic.data.description} / Propuštění pacienti</h1>
      ) : null}
      <PageNavigation className="sub-page-navigation present">
        <div>
          <NavLink
            to={`/patients-hospital/${id}/present/${careType}`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Aktuálně{" "}
            {careType === "hospitalization" ? "hospitalizovaní" : "ambulantní"}{" "}
            pacienti
          </NavLink>
        </div>
        <div>
          <NavLink
            to={`/patients-hospital/${id}/past/${careType}`}
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Propuštění pacienti
          </NavLink>
        </div>
      </PageNavigation>
      {clinic.loading ? (
        <h2>Načítání...</h2>
      ) : state == "present" ? (
        <PatientHospitalPresent id={clinic.data.id} careType={careType} />
      ) : state == "past" ? (
        <PatientHospitalPast id={clinic.data.id} careType={careType} />
      ) : null}
      {error.error && <h2>{error.message}</h2>}
    </div>
  );
};
