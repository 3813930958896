import React from 'react'
import { PatientSearch } from '../../components'

export const PatientLookup = () => {
    return ( 
            <div>
                <h1>Hledání konkrétního pacienta</h1>
                    <PatientSearch />
            </div>
    )
}