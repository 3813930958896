import React from "react";
import { PatientHospitalPastTableLogic } from "./PatientHospitalPastTableLogic";

export const PatientHospitalPastTable = ({ row }) => {
  const { patient, state, care } = PatientHospitalPastTableLogic(row);
  return (
    <div className="patient-info-table">
      {state.loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {state.error && <h1>Chyba načítání pacienta</h1>}
          <div className="header patient-info-cell">
            <strong>Příjmení a jméno pacienta</strong>
          </div>
          <div className="header patient-info-cell">
            <strong>{patient.name}</strong>
          </div>
          <div className="patient-info-cell strong">Datum narození</div>
          <div className="patient-info-cell">{patient.birth_date}</div>
          <div className="patient-info-cell strong">Hmotnost</div>
          <div className="patient-info-cell">
            {patient.weight || "Žádné informace"} kg
          </div>
          <div className="patient-info-cell strong">Výška</div>
          <div className="patient-info-cell">
            {patient.height || "Žádné informace"} cm
          </div>
          <div className="patient-info-cell strong">Poznámka</div>
          <div className="patient-info-cell">
            {patient.note || "Žádné poznámky"}
          </div>
          <div className="patient-info-cell strong">BMI</div>
          <div className="patient-info-cell">
            {patient.bmi || "Žádné informace"}
          </div>
          <div className="patient-info-cell strong">Rodné číslo</div>
          <div className="patient-info-cell">{patient.birth_number}</div>
          <div className="patient-info-cell strong">ID pacienta</div>
          <div className="patient-info-cell">{patient.external_id}</div>
          <button
            className="button patient-info-table-button"
            type="button"
            {...row.getToggleRowExpandedProps()}
          >
            Zpět
          </button>
          <div>{!care.loading && care.list}</div>
        </>
      )}
    </div>
  );
};
