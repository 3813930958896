export const translateLabels = (label, value) => {
  if (value === "RiskDrugHistory") {
    switch (label) {
      case "note":
        return "Poznámky";
      case "tags":
        return "Štítky";
      case "has_risk_drug":
        return "Rizikové léčivo";
      case "has_risk_diagnosis":
        return "Riziková diagnóza";
      case "diagnoses":
        return "Diagnóza";
      case "drugs":
        return "Léčivo";
    }
  } else if (value === "CheckIn") {
    switch (label) {
      case "drug":
        return "Léčivo";
      case "drugs":
        return "Polypragmazie";
      case "narrow_therapeutic_window_drugs":
        return "Léčivo s úzkým terapeutickým oknem";
      case "high_interaction_potential_drugs":
        return "Vysoký interakční potenciál";
      case "polypharmacy_note":
        return "Polygramazie poznámka";
      case "diagnoses_note":
        return "Riziková diagnóza poznámka";
      case "renal_insufficiency_note":
        return "Renální insuficience poznámka";
      case "high_interaction_potential_note":
        return "Vysoký interakční potenciál poznámka";
      case "systemic_corticosteroids_note":
        return "Systémové kortikoidy nebo jiná imunosuprasiva poznámka";
      case "narrow_therapeutic_window_note":
        return "Léčivo s úzkým terepeutickým oknem poznámka";
      case "hepatic_insufficiency_note":
        return "Hepatální insuficience poznámka";
      case "significant_biochemical_changes_note":
        return "Změny biochemických / hematologických parametrů poznámka";
      case "intensive_care_note":
        return "Intenzivní péče poznámka";
      case "toggle_diagnoses":
        return "Diagnóza";
      case "diagnoses":
        return "Diagonózy a léčiva";
      case "polypharmacy":
        return "Polypragmazie";
      case "high_interaction_potential":
        return "Léčivo s vysokým interakčním potenciálem (VIP)";
      case "renal_insufficiency":
        return "Renální insuficience";
      case "significant_biochemical_changes":
        return "Další významné změny biochemických / hematologických parametrů (DVZBHP)";
      case "systemic_corticosteroids":
        return "Systémové kortikoidy nebo jiné imunosuprasiva (SKNJI)";
      case "narrow_therapeutic_window":
        return "Léčivo s úzkým terapeutickým oknem";
      case "hepatic_insufficiency":
        return "Laboratorní známky hepatální insuficience";
      case "intensive_care":
        return "Intenzivní péče";
      case "pharmacist_intervention_required":
        return "Nutný zásah KF";
      case "consultation_requested":
        return "Vyžádané konzulium";
      case "risk_level":
        return "Výsledný stupeň rizikovosti";
      case "care":
        return "Číslo péče";
    }
  } else if (value === "PharmacologicalPlan") {
    switch (label) {
      case "texts":
        return "Text farmakoterapeutického plánu";
      case "note":
        return "Poznámka";
      case "notification_date":
        return "Datum pro upozornění";
      case "verifications":
        return "Aktualizace plánu";
      case "recommendations":
        return "Farmakoterapeutické doporučení";
      case "discharge_recommendations":
        return "Farm. dop. do propouštěcí zprávy";
      case "tags":
        return "Štítky";
      case "patient_condition_change":
        return "Změna zdravotního stavu";
    }
  } else if (value === "PatientInformation") {
    switch (label) {
      case "name":
        return "Název záznamu";
      case "text":
        return "Popis";
      case "file":
        return "Soubor";
    }
  } else if (value === "PharmacologicalEvaluation") {
    switch (label) {
      case "drug":
        return "Léčivo";
      case "deployment":
        return "Nasazení léčiv";
      case "discontinuation":
        return "Vysazení léčiva";
      case "continuation":
        return "Pokračování v terapii";
      case "continuation_drug_reintroduction":
        return "Znovunasazení léčiva";
      case "dose_change":
        return "Změna dávky";
      case "recommended_investigation_by_specialist":
        return "Specialistou";
      case "recommended_investigation_by_laboratory":
        return "Laboratoří";
      case "recommended_investigation_by_physical":
        return "Fyzikální";
      case "tdm_interpretation":
        return "Interpretace";
      case "tdm_measure_level_recommendation":
        return "Doporučení změření hladiny";
      case "specific_adverse_effect_diagnosis":
        return "Diagnostika nežádoucího účinku";
      case "specific_adverse_effect_reporting":
        return "Hlášení NÚ";
      case "specific_consultation":
        return "Konzultace";
      case "dosage_determination":
        return "Stanovení dávky (při zahájení terapie)";
      case "administration_method_optimization":
        return "Optimalizace způsobu";
      case "tags":
        return "Štítky";
      case "deployment_initial_diagnosis":
        return "Diagnóza ve vstuní anamnéze";
      case "deployment_during_diagnosis":
        return "Diagnóza v průběhu hospitalizace";
      case "deployment_ft_approach":
        return "Vhodnější FT postup";
      case "continuation_other_reason":
        return "Pokračování v terapii jiný důvod";
      case "discontinuation_other_reason":
        return "Vysazení léčiva jiný důvod";
      case "dose_change_other_reason":
        return "Změna dávky jiný důvod";
      case "deployment_other_reason":
        return "Nasazení léčiva jiný důvod";
      case "continuation_medical_intervention":
        return "Po lékařské intervenci";
      case "discontinuation_contradiction":
        return "Kontraindikace";
      case "discontinuation_adverse_effect":
        return "Projev nežádoucího účinku";
      case "discontinuation_adverse_effect_risk":
        return "Riziko nežádoucího účinku";
      case "discontinuation_missing_indication":
        return "Chybějící indikace";
      case "discontinuation_allergies":
        return "Alergie";
      case "discontinuation_underdosage_risk":
        return "Rizika poddávkování";
      case "discontinuation_overdosage_risk":
        return "Rizika předávkování";
      case "discontinuation_drug_interaction":
        return "Léková interakce";
      case "discontinuation_duplicity":
        return "Duplicity";
      case "discontinuation_renal_insufficiency":
        return "Renální insuficience";
      case "discontinuation_hepatic_insufficiency":
        return "Hepatální insuficience";
      case "discontinuation_medical_intervention":
        return "Lékařská intervence";
      case "dose_change_adverse_effect":
        return "Projev nežád. účinku";
      case "dose_change_adverse_effect_risk":
        return "Riziko nežád. účinku";
      case "dose_change_hepatic_insufficiency":
        return "Hepatální insuficience";
      case "dose_change_renal_insufficiency":
        return "Renální insuficience";
      case "dose_change_drug_interaction":
        return "Léková interakce";
      case "dose_change_underdosage":
        return "Rizika poddávkování";
      case "dose_change_overdosage":
        return "Rizika předávkování";
      case "dose_change_laboratory_findings":
        return "Na základě lab. výsledků";
      case "dose_change_dosage_reduction":
        return "Snížení dávky";
      case "dose_change_dosage_increase":
        return "Zvýšení dávky";
    }
  } else if (value === "Patient") {
    switch (label) {
      case "name":
        return "Jméno a příjmení";
      case "first_name":
        return "Jméno";
      case "last_name":
        return "Přijmení";
      case "birth_date":
        return "Datum narození";
      case "birth_number":
        return "Rodné číslo";
      case "insurance_company":
        return "Pojišťovna";
      case "insurance_number":
        return "Číslo pojištěnce";
      case "height":
        return "Výška";
      case "weight":
        return "Hmotnost";
      case "current_care":
        return "Číslo aktuální péče";
      case "created_at":
        return "Vytvořeno dne";
      case "note":
        return "Poznámka";
    }
  }

  return label;
};
