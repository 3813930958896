import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AllClinicsLogic, PageUnderDevelopment } from "..";
import { NavLink } from "react-router-dom";

export const AllClinics = () => {
  const { careType } = useParams();
  const { loading, clinicsList, error, totalPatientsCount } =
    AllClinicsLogic(careType);

  return (
    <div>
      <h1>Všechny kliniky</h1>
      <div className="signpost-wrap max-w-1524">
        <div className="signpost">
          {loading ? (
            <h2 className="message">Loading...</h2>
          ) : error.error ? (
            <h2 className="message">{error.message}</h2>
          ) : (
            clinicsList
          )}
        </div>
      </div>
      {careType === "hospitalization" && (
        <div class="signpost-wrap search">
          <div class="signpost ">
            <div class="item">
              <NavLink to="/all-patients/hospitalization">
                <span className="icon">
                  <i className="far fa-hospital"></i>
                  <u>{totalPatientsCount}</u>
                </span>
                <span className="name">Celá nemocnice</span>
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
