import {useState, useEffect} from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

export const PatientDrugsInpLogic = (sectionName, drugName="", noteName="" ) => {
    const {getValues, setValue, control, reset} = useFormContext()
    const [state, setState] = useState({active: getValues(sectionName)})
    const toggle = useWatch({control: control, name: sectionName})
    
    useEffect (() => {
        if (!toggle) {
            clearFormValues()
        }
    }, [toggle])

    const clearFormValues = () => {
        if(drugName.length != 0) {
            setValue(drugName, [{label: "Vyhledejte...", value: ""}]) 
            reset(getValues(), {keepDefaultValues: true});
        }

        if (noteName.length != 0)  {
            setValue(noteName, "")
        }
    }
    
    const toggleSection = () => {
        setState({...state, active: !state.active})
    }
    
    

    return {state, toggleSection}
}