import { authHeader } from "../auth/authHeader";
import axios from "axios";

const getRequisitions = async (params) => {
  const options = authHeader();
  try {
    const response = await axios.get(
      process.env.REACT_APP_ROOT_API + `/requisitions/`,
      { ...options, params: { ...params } }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requisitionsService = { getRequisitions };
