import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { helpFunc } from "../../helpers/helpFunction";
import { others } from "../../services/other.services";
import { clinicService } from "../../services/clinic.services";
import { saveAs } from "file-saver";

export const ReportsLogic = () => {
  const [state, setState] = useState({ submit: false, generated: false });
  const [error, setError] = useState({ error: false, message: "" });
  const [reports, setReports] = useState({ loading: true, reports: [] });
  const [clinics, setClinics] = useState({ loading: true });
  const [filters, setFilters] = useState({
    years: {},
    months: {},
    formats: {},
    careType: {},
  }); //Loads filters and its options
  const [formState, setFormState] = useState({
    timeRange: false,
    customDate: false,
    careType: false,
    careState: false,
    year: false,
    month: false,
    clinic: false,
    department: false,
    format: false,
    actGroup: false,
    hidden: true,
    timeHidden: true,
    hasNote: false,
  }); //Check what parts of form is hidden
  const [departments, setDepartments] = useState({ loading: true });

  //Load options for filter when component loads
  useEffect(() => {
    getClinics();
    getReportsOptions();
  }, []);

  //Form section
  const defaultValues = {
    report: { label: "Vyberte požadovaný report", value: false },
    timeRange: { label: "Vyberte časové období", value: false },
    customDate: false,
    year: { label: "Vyberte rok", value: false },
    month: { label: "Vyberte měsíc", value: false },
    clinic: { label: "Vyberte kliniku", value: false },
    department: { label: "Vyberte oddělení", value: false },
    format: { label: "Vyberte formát", value: false },
    actGroupExact: "",
    actGroupStart: "",
    care_type: { label: "Vyberte pacienty", value: false },
    care_state: { label: "Vyberte péči", value: false },
    has_note: { label: "Vyberte pacienty", value: false },
  };

  const methods = useForm({ defaultValues: defaultValues });
  //Watch for changes in formfilters
  const reportType = useWatch({ control: methods.control, name: "report" });
  const clinic = useWatch({ control: methods.control, name: "clinic" });
  const timeRange = useWatch({ control: methods.control, name: "time_range" });

  //Loading all posible filters
  const getFilters = (report) => {
    //case based on picked reportType
    let time_range;
    switch (report) {
      case "uzis":
        time_range = [{ label: "Rok", value: "year" }];
        break;
      default:
        time_range = [
          { label: "Rok", value: "year" },
          { label: "Měsíc", value: "month" },
          { label: "Vlastní", value: "custom" },
        ];
    }
    //years -> form X (2000) to current year
    //month -> dictionary of 12 ints and labels
    const { years, months } = helpFunc.getTimeOptions();
    //dict with labels and values => pdf, xslx
    let formats;
    switch (report) {
      case "uzis":
      case "visit_notes":
        formats = [{ label: "PDF", value: "pdf" }];
        break;
      case "pharmacological_evaluation_drugs":
      case "pharmacological_evaluation_groups":
        formats = [{ label: "XLSX", value: "xlsx" }];
        break;
      default:
        formats = [
          { label: "PDF", value: "pdf" },
          { label: "XLSX", value: "xlsx" },
        ];
    }

    let careType = [
      { label: "Hospitalizovaní pacienti", value: "hospitalization" },
      { label: "Ambulantní pacienti", value: "ambulation" },
      { label: "Hospitalizovaní a ambulantní pacienti", value: "all" },
    ];

    let careState = [
      { label: "Aktivní péče", value: "active" },
      { label: "Ukončená péče", value: "finished" },
      { label: "Všechny péče", value: "all" },
    ];

    let hasNote = [
      { label: "Všichni pacienti", value: "all" },
      { label: "Pacienti jen s poznámkou", value: "true" },
      { label: "Pacienti jen bez poznámky", value: "false" },
    ];

    setFilters({
      ...filters,
      years: years,
      months: months,
      formats: formats,
      time_range: time_range,
      careType: careType,
      careState: careState,
      hasNote: hasNote,
    });
  };

  //Loading reports options
  const getReportsOptions = async () => {
    try {
      setReports({ ...reports, loading: true });
      const data = await others.getReportsOptions();
      const options = await helpFunc.pDataForSelect(data, "reports");
      setReports({
        ...reports,
        loading: false,
        options: options,
        reports: data,
      });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání reportů" });
    }
  };

  //Loading a clinics options
  const getClinics = async () => {
    try {
      setClinics({ ...clinics, loading: true });
      let data = await clinicService.getAllClinics("hospitals");
      data = await helpFunc.pDataForSelect(data, "clinics");
      setClinics({ ...clinics, loading: false, options: data });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání klinik" });
    }
  };

  //Loading departments options
  const getDepartments = async (clinic) => {
    try {
      setDepartments({ ...departments, loading: true });
      let data = await clinicService.getDepartments(clinic);
      data = await helpFunc.pDataForSelect(data, "clinics");
      setDepartments({ ...departments, loading: false, options: data });
      setFormState({ ...formState, department: true });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba při načítání oddělení" });
    }
  };

  //Dynamicaly changing the form
  useEffect(() => {
    if (reportType?.value) {
      hideOptionalFormField();
      getFilters(reportType.value);
    } else {
      hideOptionalFormField();
    }
  }, [reportType]);

  const setDefaultValues = (defaultValues, option) => {
    switch (option) {
      case "all":
        methods.setValue("time_range", defaultValues.timeRange);
        methods.setValue("date_from", defaultValues.customDate);
        methods.setValue("date_to", defaultValues.customDate);
        methods.setValue("year", defaultValues.year);
        methods.setValue("month", defaultValues.month);
        methods.setValue("clinic", defaultValues.clinic);
        methods.setValue("department", defaultValues.department);
        methods.setValue("format", defaultValues.format);
        methods.setValue("atc_group_exact", defaultValues.actGroupExact);
        methods.setValue("atc_group_startswith", defaultValues.actGroupStart);
        methods.setValue("care_type", defaultValues.care_type);
        methods.setValue("care_state", defaultValues.care_state);
        methods.setValue("has_note", defaultValues.has_note);
        break;
      case "time":
        methods.setValue("date_from", defaultValues.customDate);
        methods.setValue("date_to", defaultValues.customDate);
        methods.setValue("year", defaultValues.year);
        methods.setValue("month", defaultValues.month);
        break;
      case "clinic":
        methods.setValue("department", defaultValues.department);
        break;
    }
  };

  //When optional fields are hidden, show the once which are needed for new selected report
  useEffect(() => {
    if (formState.hidden && reportType) {
      showForm(reportType.value, defaultValues);
    }
  }, [formState.hidden, reportType]);

  //Show optional inputs based on selected report
  //Sets default form values
  const showForm = (reportType, defaultValues) => {
    setDefaultValues(defaultValues, "all");
    switch (reportType) {
      case "uzis":
        setFormState({
          ...formState,
          timeRange: true,
          format: true,
          hidden: false,
        });
        break;
      case "risk_levels":
        setFormState({
          ...formState,
          clinic: true,
          timeRange: true,
          careType: true,
          format: true,
          hidden: false,
        });
        break;
      case "pharmacological_evaluation_patients":
        setFormState({
          ...formState,
          clinic: true,
          timeRange: true,
          careType: true,
          format: true,
          hidden: false,
        });
        break;
      case "tags":
        setFormState({
          ...formState,
          clinic: true,
          timeRange: true,
          format: true,
          hidden: false,
        });
        break;
      case "pharmacological_evaluation_drugs":
        setFormState({
          ...formState,
          clinic: true,
          actGroupExact: true,
          timeRange: true,
          format: true,
          hidden: false,
        });
        break;
      case "pharmacological_evaluation_groups":
        setFormState({
          ...formState,
          clinic: true,
          actGroupStart: true,
          timeRange: true,
          format: true,
          hidden: false,
        });
        break;
      case "visit_notes":
        setFormState({
          ...formState,
          clinic: true,
          hasNote: true,
          format: true,
          hidden: false,
        });
        break;
      case "medical_procedures":
        setFormState({
          ...formState,
          clinic: true,
          timeRange: true,
          careState: true,
          format: true,
          hidden: false,
        });
        break;
    }
  };

  //call departments based on selected clinic
  useEffect(() => {
    if (clinic.value) {
      getDepartments(clinic.value);
    }
  }, [clinic]);

  //hide time range options based on selected report
  useEffect(() => {
    if (timeRange?.value) {
      hideTimeOptions();
    }
  }, [timeRange]);

  //Hide
  useEffect(() => {
    if (formState?.timeHidden) {
      showTimeRange(timeRange?.value, defaultValues);
    }
  }, [formState.timeHidden, timeRange]);

  //hide options in time range select
  const hideTimeOptions = () => {
    setFormState({
      ...formState,
      customDate: false,
      year: false,
      month: false,
      timeHidden: true,
    });
  };

  //hide all optional inputs
  const hideOptionalFormField = () => {
    setFormState({
      ...formState,
      timeRange: false,
      customDate: false,
      year: false,
      month: false,
      clinic: false,
      department: false,
      careState: false,
      careType: false,
      format: false,
      actGroupExact: false,
      actGroupStart: false,
      hidden: true,
    });
    setState({ submit: false, generated: false });
    clearErrors();
  };

  //show time range inputs based on selected time range
  const showTimeRange = (timeRange, defaultValues) => {
    setDefaultValues(defaultValues, "time");
    switch (timeRange) {
      case "year": {
        setFormState({ ...formState, year: true, timeHidden: false });
        break;
      }
      case "month": {
        setFormState({
          ...formState,
          month: true,
          year: true,
          timeHidden: false,
        });
        break;
      }
      case "custom": {
        setFormState({ ...formState, customDate: true, timeHidden: false });
        break;
      }
    }
  };

  //Generate report
  //CELERY SUCCESS, FAILURE
  const generateReport = async (data) => {
    try {
      clearErrors();
      setState({ ...state, submit: true });
      const params = getParams(data);
      const taskID = await others.generateReport(data.report?.value, params);
      await getStatus(taskID, data);
    } catch (e) {
      console.error(e);
      setState({ ...state, submit: false });
      setError({
        error: true,
        message: "Nastala chyba při generování reportu",
      });
    }
  };

  const saveData = async (file, name) => {
    saveAs(
      process.env.REACT_APP_ROOT_FILES + file,
      `${name.report?.label}.${name.format?.value}`
    );
    setState({ ...state, submit: false, generated: true });
  };

  const getStatus = async (id, data) => {
    const response = await others.getReportStatus(id);
    if (response.data.state === "SUCCESS") {
      saveData(response.data.result.data.file, data);
      return response;
    } else if (response.data.state === "FAILURE") {
      return Promise.reject("Chyba pri generovani reportu");
    }
    setTimeout(() => {
      getStatus(id, data);
    }, 1000);
  };

  const getParams = (data) => {
    //not optimal since object is copied multiple times
    let params = {};

    //replace null ""
    data = helpFunc.replaceNull(data);

    //formats
    if (data.format.value) {
      params = { ...params, report_format: data.format?.value };
    }
    if (data.atc_group_exact !== "") {
      params = { ...params, atc_group_exact: data.atc_group_exact };
    }
    if (data.atc_group_startswith !== "") {
      params = { ...params, atc_group_startswith: data.atc_group_startswith };
    }

    //care_type patients
    switch (data.care_type?.value) {
      case "hospitalization":
        params = { ...params, care_type: "hospitalization" };
        break;
      case "ambulation":
        params = { ...params, care_type: `ambulation` };
        break;
      case "all":
        params = { ...params, care_type: "external" };
        break;
    }

    //care_type patients
    switch (data.care_state?.value) {
      case "active":
        params = { ...params, care_state: "active" };
        break;
      case "finished":
        params = { ...params, care_state: `finished` };
        break;
      case "all":
        params = { ...params, care_state: "all" };
        break;
    }

    //time_range
    if (data.time_range?.value) {
      if (data.date_from || data.date_to) {
        if (data.date_from) {
          const date_from = helpFunc.parseDate(data.date_from);
          params = { ...params, date_from: date_from };
        }
        if (data.date_to) {
          const date_to = helpFunc.parseDate(data.date_to);
          params = { ...params, date_to: date_to };
        }
      } else if (data.month?.value) {
        params = {
          ...params,
          year: data.year?.value,
          month: data.month?.value,
        };
      } else if (data.year?.value) {
        params = { ...params, year: data.year?.value };
      }
      params = { ...params, time_range: data.time_range?.value };
    }
    //clinics
    if (data.clinic?.value) {
      //departs
      if (data.department?.value) {
        params = {
          ...params,
          clinic: data.clinic?.value,
          department: data.department?.value,
        };
      } else {
        params = { ...params, clinic: data.clinic?.value };
      }
    }

    //hasNote
    if (data.has_note?.value) {
      params = { ...params, has_note: data.has_note?.value };
    }
    return params;
  };

  //clear error
  const clearErrors = () => {
    setError({ error: false, message: "" });
  };

  return {
    state,
    error,
    methods,
    reports,
    clinics,
    departments,
    filters,
    formState,
    defaultValues,
    generateReport,
  };
};
