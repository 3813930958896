import React from "react";
import { NavLink } from "react-router-dom";

export const Help = () => {
  return (
    <div className="help">
      <h1>Nápověda</h1>

      <a href="https://conceptica.cz" target="_blank">
        <img src="./conceptica.png" alt="conceptica s.r.o." />
      </a>

      <a className="link" href="https://conceptica.cz" target="_blank">
        Conceptica s.r.o.
      </a>
      <p>
        <span class="title">Vývojové centrum: </span>
        Na Škrobech 246
        <br />
        252 25 Jinočany <br />
      </p>
      <p>
        <span class="title">Sídlo: </span>
        Rybná 24 <br />
        110 00 Praha 1 <br />
      </p>
      <p>
        <span class="title">Kontakty: </span>
        E-mail: <a href="mailto:servicedesk@conceptica.cz">servicedesk@conceptica.cz</a>{" "}
        <br />
        Telefon: <a href="tel:+420777865290">+420 777 865 290</a> <br />
        ID datové schránky: t5fwf2w <br />
        IČO: 275 78 348 <br />
      </p>

      <p>
        <a href="https://servicedesk.conceptica.cz/cs" target="_blank">
          Helpdesk
        </a>
      </p>

      <NavLink to="/">
        <button className="button">Zpět na úvodní stránku</button>
      </NavLink>
    </div>
  );
};

