import React from 'react' 
import { Reports } from '../../components'

export const ReportsAndStatistics = () => {
    return (
        <div>
            <h1>Statistiky a reporty</h1>
            <div className='max-w-1024'> 
                <Reports />
            </div>
        </div>
    )
}