import React from "react";
import { NavLink } from "react-router-dom";

export const ClinicBoxItem = ({
  id,
  abbreviation,
  description,
  noCheckInCount,
  careType,
  img,
  ...props
}) => {
  return (
    <>
      <NavLink to={`/patients-hospital/${id}/present/${careType}`}>
        <span className="icon">
          <i>
            <img src={img} />
            <u>0</u>
          </i>
          {/* show nuber of patients  */}
          <u>{noCheckInCount}</u>
        </span>
        <span className="name">
          {description} <br /> {abbreviation}
        </span>
      </NavLink>
    </>
  );
};
