import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { patientService } from "../../services/patient.services";
import { careServices } from "../../services/care.services";

export const PatientProfileLogic = () => {
  const [state, setState] = useState({ loading: true });
  const [error, setError] = useState("");
  const [patient, setPatient] = useState("");
  const [requisitions, setRequisitions] = useState({ data: [], answer: false });
  const [careType, setCareType] = useState(undefined);
  const { id, care_id } = useParams();

  const getRequisitions = async (careId = care_id) => {
    const requisitions = await patientService.getRequisitionsByCareId(careId);
    setRequisitions({
      data: requisitions,
      answer: requisitions.some(
        (item) => !item.answer || item.answer?.length === 0
      ),
    });
  };

  const getPatientData = async () => {
    try {
      setState({ ...state, loading: true });
      //get patient acc to url id
      let patient = await patientService.getPatient(id);
      let care = await careServices.getCare(care_id);
      getRequisitions(care.id);
      setPatient({ patient: patient, care: care, careID: care.id });
      setCareType(care.care_type);
      setState({ ...state, loading: false });
    } catch (error) {
      setError({
        error: true,
        message: "Chyba při načítání pacienta nebo péče",
      });
    }
  };

  useEffect(() => {
    getPatientData();
    return () => {
      setState({ ...state, loading: true });
    };
  }, [window.location.href]);

  return {
    state,
    patient,
    error,
    requisitions,
    careType,
    getPatientData,
  };
};
