import { CareProceduresPharmaPlan } from "../CareProcedures/CareProceduresPharmaPlan";
import { CheckinPharmaPlan } from "../CheckinPharmaPlan/CheckinPharmaPlan";
import { ColorStripe } from "../Graphics/ColorStripe";
import { PopUp } from "../PopUps/PopUp";
import { Table } from "../Table/Table";
import { TextContainer } from "../TextContainer/TextContainer";
import { RecordHistoryLogic } from "./RecordHistoryLogic";

export const RecordHistory = ({
  patient,
  care = {},
  checkin,
  careIsLocked = false,
  careID,
  plan = null,
  isPharmaPlan = true,
  ...props
}) => {
  const careType = care.care_type;

  const {
    error,
    table,
    popUp,
    procedures,
    patientRiskLevel,
    columns,
    records,
    togglePopUp,
    handleBackButton,
  } = RecordHistoryLogic(plan, checkin, patient, care, careIsLocked, careType);

  console.log(table);

  return (
    <>
      <div className="pharma-plan-goback-btn">
        {isPharmaPlan && (
          <button type="button" className="button" onClick={handleBackButton}>
            Zpět
          </button>
        )}
      </div>
      <div className="patient-profile pharmacological-plan">
        {/* Simple table */}
        {!popUp.hide && popUp.popUp}
        {error.error && <PopUp message={error.message} toggle={togglePopUp} />}
        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              {table.loading ? (
                <h1>Loading table...</h1>
              ) : table.data ? (
                <>
                  <Table
                    data={table.data}
                    columns={columns}
                    careType={careType}
                  />
                  <ColorStripe className={`color-stripe-${patientRiskLevel}`} />
                </>
              ) : (
                <h2>Nebyly nalezeny informace o pacientovi</h2>
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column flex-2">
              {records.map((item, index) => (
                <TextContainer
                  key={index}
                  rawData={item}
                  careIsLocked={true}
                  hide={false}
                  type={item.type}
                  careType={careType}
                />
              ))}
            </div>

            {/* RIGHT COLUMN */}
            <div className="grid-column flex-1">
              {/* checkin component showing patient checkin data */}
              {isPharmaPlan && (
                <div className="grid-row">
                  {!table.loading && (
                    <CheckinPharmaPlan
                      checkin={checkin}
                      careIsLocked={careIsLocked}
                      hidePharmaPlanItem={false}
                    />
                  )}
                </div>
              )}
              <div className="grid-row">
                {/* Medical procedures table */}
                <CareProceduresPharmaPlan
                  careId={careID}
                  reload={procedures.reload}
                  careType={careType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
