import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { clinicService } from "../../services/clinic.services";

export const PatientsHospitalLogic = (id = null, careType) => {
  const [error, setError] = useState("");
  const [clinic, setClinic] = useState({ loading: true });

  useEffect(() => {
    getClinic(id);
  }, [id, careType]);

  //load clinic form db
  const getClinic = async (id) => {
    try {
      setClinic({ ...clinic, loading: true });
      const data = await clinicService.getClinicById(id);
      setClinic({ ...clinic, loading: false, data: data });
    } catch (error) {
      console.error(error);
      setError({ error: true, message: "Chyba načítání kliniky." });
    }
  };

  return { clinic, error };
};

PatientsHospitalLogic.propTypes = {
  id: PropTypes.number,
};
