import React, {useEffect} from 'react' 
import { useFormContext, useWatch } from 'react-hook-form'

export const TextAreaInp = ({
    id,
    label, 
    labelClass, 
    disabled, 
    sectionName, 
    name, 
    defaultValue="", 
    required=false, 
    placeholder, 
    containerClass='input-group', 
    textAreaClass='inp-textarea', 
    showMore=false, 
    rows,
    ...props}) => {

    const methods = useFormContext()
    const toggle = useWatch({control: methods.control, name: sectionName})

    useEffect (() => {
        if (!toggle) {
            clearFormValues()
        }
    }, [toggle])

    const clearFormValues = () => {
        methods.setValue(name, "")
        methods.reset(methods.getValues(), {keepDefaultValues: true});
    }

    return ( 
        <>
            <div className={containerClass}>
                <label htmlFor={id} className={labelClass}>{label}</label>
                <textarea 
                    style={{height: props.height, lineHeight: props.lineHeight}} 
                    type='textarea' 
                    id={id} 
                    disabled={disabled} 
                    className={textAreaClass} 
                    placeholder={placeholder} 
                    {...methods.register(name, {required:required})} 
                    {...props} 
                    rows={rows}
                    />
            </div> 
        </>
    )
}