import React from "react";
import { NavLink } from "react-router-dom";
import { PatientsBedsLogic } from "./PatientsBedsLogic";

export const PatientsBeds = ({ ...props }) => {
  const { state, error, noCheckInCount, myHospitalsList, celaNemocnice } =
    PatientsBedsLogic();

  return (
    <div>
      <h1>Hospitalizovaní pacienti - rozcestník</h1>
      {error.error && <h2 className="message">{error.message}</h2>}
      {state.loading ? (
        <h2 className="message">Nahrávání klinik...</h2>
      ) : (
        <div className="signpost-wrap">
          <div className="signpost">{myHospitalsList}</div>
          {celaNemocnice && (
            <div className="signpost single">
              <div className="item">
                <NavLink to="/all-patients/hospitalization">
                  <span className="icon">
                    <i className="far fa-hospital"></i>
                  </span>
                  <span className="name">Celá nemocnice</span>
                </NavLink>
              </div>
            </div>
          )}
          <div className="signpost single">
            <div className="item">
              <NavLink to="all-clinics/hospitalization">
                <span className="icon">
                  <i className="fas fa-clinic-medical"></i>
                  <u>{noCheckInCount}</u>
                </span>
                <span className="name">Všechny kliniky</span>
              </NavLink>
            </div>
          </div>
        </div>
      )}
      <div className="signpost-wrap search">
        <div className="signpost ">
          <div className="item">
            <NavLink to="patient-lookup">
              <span className="icon">
                <i className="fas fa-search"></i>
              </span>
              <span className="name">
                Hledání konkrétního
                <br /> pacienta
              </span>
            </NavLink>
          </div>
          <div className="item">
            <NavLink to="/requisitions/hospitalization">
              <span className="icon">
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </span>
              <span className="name">
                Nezpracované
                <br /> žádanky
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
