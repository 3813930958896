import { NavLink, useParams } from "react-router-dom";
import { PageNavigation } from "../../components";
import { RequisitionsList } from "../../components/Requisitions/RequsitionsList";

export const Requisitions = () => {
  const { careType } = useParams();

  return (
    <div>
      <h1>Nezpracované žádanky</h1>
      <PageNavigation className="sub-page-navigation present">
        <div>
          <NavLink
            to="/requisitions/hospitalization"
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Hospitalizovaní pacienti
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/requisitions/ambulation"
            className={(isActive) => (!isActive ? "" : "active")}
          >
            Ambulantní pacienti
          </NavLink>
        </div>
      </PageNavigation>
      <RequisitionsList careType={careType} />
    </div>
  );
};
